import { callBackOnCurrentSkin } from "utils/HelperFunc"
const month = new Date().getMonth()
const day = new Date().getDate()
export const isWinter =
  (month === 11 && parseInt(day.toString()) >= 11) ||
  (month === 0 && day.toString().match(/^(1|2|3|4|5)$/g))
let skin = ""

skin = callBackOnCurrentSkin((currentSkin) => currentSkin.skin)
//console.log(skin)

let imgObj = {}
try {
  imgObj = {
    //pagamanti logo
    pagaPos: require(`../assets/images/pagaPos.svg`),
    pagaSvg: require(`../assets/images/pagaSvg.svg`),
    "visure-service": require(`../assets/images/visure-service.png`),
    yappay: require(`../assets/images/yappay.svg`),
    bolletinobarcode: require(`../assets/images/bolletinobarcode.png`),
    sepafinInst: require(`../assets/images/sepaInst.svg`),
    codicebolletino: require(`../assets/images/codicebolletino.jpeg`),
    SPORTBULL: require(`../assets/images52/logoSport.svg`),
    BOLO_AUTO: require(`../assets/images/boloAuto-form.svg`),
    PAGO_PA: require(`../assets/images/pagoPa-form.svg`),
    agenzia_entrata: require(`../assets/images/agenzia_entrata.png`),
    BOLLETINO: require(`../assets/images/bolletino-form.svg`),
    "BOLO_AUTO-logo": require(`../assets/images/boloauto.svg`),
    "PAGO_PA-logo": require(`../assets/images/pagopa.svg`),
    "BOLLETINO-logo": require(`../assets/images/BOLL-Black.svg`),
    "f24-logo": require(`../assets/images/f24.svg`),
    //mobile-pagamenti
    "SBOL006-Black-Mobile": require(`../assets/images/BOL006-Black.svg`),
    "SPPA001-Black-Mobile": require(`../assets/images/PPA-Black.svg`),
    "SPAGF24-Black-Mobile": require(`../assets/images/PAGF24-Black.svg`),
    "BOLL-Black-Mobile": require(`../assets/images/BOLL-Black.svg`),
    pagoPaBack: require(`../assets/images/pago-pa-background.jpg`),
    bolAutoBack: require(`../assets/images/bolo-auto-background.jpg`),
    baner_servizi_header: require(`../assets/images/baner_servizi_header.png`),
    caffeBanner: require(`../assets/images/caffeBanner.jpeg`),
    A920: require(`../assets/images/A920-18.png`),
    A80: require(`../assets/images/A80-12.png`),
    A35: require(`../assets/images/A35-18.png`),

    coming_soon: require(`../assets/images/coming_soon.png`),

    //Static home page images
    SER_POS: require("../assets/images/servizi_postali.png"),
    whatsApp: require("../assets/images/whatsApp.svg"),
    //
    RIC_CEL: require(`../assets/images/richariche_cellulari.png`),
    RIC_PVR: require(`../assets/images/richariche_pvr.png`),
    PRE_VOL: require(`../assets/images/prenotazione_biglietti_voli.png`),
    PRE_TRE: require(`../assets/images/Prenotazione_biglietti_treni.png`),
    PRE_EVE: require(`../assets/images/prenotazione_biglietti_eventi.png`),
    ACQ_ONL: require(`../assets/images/aqcquisti_online.png`),
    RIC_DIG: require(`../assets/images/ricariche_television_digitali.png`),
    GIF_CAR: require(`../assets/images/gift_card.png`),
    SPE_DIZ: require(`../assets/images/spedizione.png`),
    CRY_VAL: require(`../assets/images/crypto_valute.png`),
    ALT_SER: require(`../assets/images/altri_servizi.png`),
    //*//
    footerBottom: require(`../assets/images/footerb.svg`),
    footerBottomMrPay: require(`../assets/images/footerMrPay.svg`),

    servizi_banner: require(`../assets/images/caffeSiideBanner.jpeg`),
    loader: require(`../assets/images/loader.gif`),

    logo: require(`../assets/images${skin}/${isWinter ? "logoWinter" : "logo"}.svg`),
    logoFooter: require(`../assets/images${skin}/logoFooter.svg`),
    tickets: require(`../assets/images/tickets.svg`),
    uparrow: require(`../assets/images/uparrow.svg`),
    mario: require(`../assets/images/mario.jpg`),
    admAZZ: require(`../assets/images/admAZZ.svg`),
    click: require(`../assets/images/click.svg`),
    girl: require(`../assets/images/girl.png`),
    billDark: require(`../assets/images/bill-dark.svg`),
    billBlue: require(`../assets/images/bill-blue.svg`),
    billOrange: require(`../assets/images/bill-orange.svg`),
    Car: require(`../assets/images/Car.svg`),
    postpayimg: require(`../assets/images/postpayimg.svg`),
    TIM_logo_2016: require(`../assets/images/TIM_logo_2016.svg`),
    wind_logo_svg_vector: require(`../assets/images/wind-logo-svg-vector.svg`),
    three_logo: require(`../assets/images/three-logo.svg`),
    rightTriangle: require(`../assets/images/rightTriangle.svg`),
    posteitalia: require(`../assets/images/posteitalia.svg`),
    coop: require(`../assets/images/coop.svg`),
    paysafe: require(`../assets/images/paysafe.svg`),
    // Stanleybet_logo_international: require(`../assets/images/Stanleybet_logo_international.svg`),
    Stanleybet_logo_international: require(`../assets/images/Stanleybet_logo_international1.svg`),
    Sky_Italia_Logo_2018: require(`../assets/images/Sky_Italia_-_Logo_2018.svg`),
    bitcoinorange: require(`../assets/images/bitcoinorange.svg`),
    bitcoingreen: require(`../assets/images/bitcoingreen.svg`),
    ethereum: require(`../assets/images/ethereum.svg`),
    riple: require(`../assets/images/riple.svg`),
    litecoin: require(`../assets/images/litecoin.svg`),
    dashcoin: require(`../assets/images/dashcoin.svg`),
    cancelMob: require(`../assets/images/cancelMob.svg`),
    close: require(`../assets/images/close.svg`),
    euro: require(`../assets/images/euro.svg`),
    checkSymbol: require(`../assets/images/check-symbol.svg`),
    print: require(`../assets/images/print.svg`),
    logoGray: require(`../assets/images/logoGray.svg`),
    postepayBlue: require(`../assets/images/postepayBlue.svg`),
    BOLL: require(`../assets/images/BOLL-Black.svg`),
    SBOLL: require(`../assets/images/BOLL-Black.svg`),
    SBOLLA: require(`../assets/images/BOLL-Black.svg`),
    SBOLLF: require(`../assets/images/BOLL-Black.svg`),
    SMAVRAV: require(`../assets/images/BOLL-Black.svg`),
    SPAGF24CO: require(`../assets/images/BOLL-Black.svg`),
    SPAGPA: require(`../assets/images/pago-pa.svg`),
    mrPayIcon: require(`../assets/images/mrPayIcon.svg`),
    sepafinIcon: require(`../assets/images/sepafinIcon.svg`),
    ePayIcon: require(`../assets/images/ePayIcon.svg`),
    RCPP: require(`../assets/images/postpayimg.svg`),
    RPP001: require(`../assets/images/postpayimg.svg`),
    PARTYP: require(`../assets/images/partypoker.svg`),
    TIM: require(`../assets/images/TIM_logo_2016.svg`),
    TIMO: require(`../assets/images/TIM_logo_2016.svg`),
    VODA: require(`../assets/images/vodafone.svg`),
    VODO: require(`../assets/images/vodafone.svg`),
    BGAM: require(`../assets/images/bgame.svg`),
    BBET: require(`../assets/images/bbet.svg`),
    WIND: require(`../assets/images/wind-logo-svg-vector.svg`),
    WINO: require(`../assets/images/wind-logo-svg-vector.svg`),
    LMAX: require(`../assets/images/linkeln.svg`),
    LINKEM: require(`../assets/images/linkeln.svg`),
    BSTC: require(`../assets/images/Best_card.svg`),
    NNET: require(`../assets/images/on_net.svg`),
    IDTB: require(`../assets/images/IDTB.svg`),
    TRE: require(`../assets/images/three-logo.svg`),
    TREO: require(`../assets/images/three-logo.svg`),
    SKY: require(`../assets/images/Sky_Italia_-_Logo_2018.png`),
    PMOB: require(`../assets/images/posteitalia.png`),
    STBT: require(`../assets/images/stbt.svg`),
    CRYP: require(`../assets/images/bitcoinorange.svg`),
    CRP00B: require(`../assets/images/bitcoinorange.svg`),
    CRP0BC: require(`../assets/images/bitcoingreen.svg`),
    CRP00E: require(`../assets/images/ethereum.svg`),
    CRP00R: require(`../assets/images/riple.svg`),
    CRP00L: require(`../assets/images/litecoin.svg`),
    CRP00D: require(`../assets/images/dashcoin.svg`),
    PAYS: require(`../assets/images/paysafe.svg`),
    DDIGIM: require(`../assets/images/DDIGIM.svg`),
    BETL: require(`../assets/images/betaland.svg`),
    FLBT: require(`../assets/images/flashbet.svg`),
    FLBTF: require(`../assets/images/flashbet.svg`),
    DKENA: require(`../assets/images/DKENA.svg`),
    HOX: require(`../assets/images/HOX.svg`),
    FSTW: require(`../assets/images/FSTW.svg`),
    ILIO: require(`../assets/images/ILIO.svg`),
    RABON: require(`../assets/images/RABONA.png`),
    RABONA: require(`../assets/images/RABONA.png`),
    LYCAMD: require(`../assets/images/Lycamobile.svg`),
    TISCA: require(`../assets/images/Tiscali.svg`),
    TISCAL: require(`../assets/images/Tiscali.svg`),
    WIND3: require(`../assets/images/WIND3.svg`),
    VERYMB: require(`../assets/images/VERYMB.svg`),
    VERYMOB: require(`../assets/images/VERYMB.svg`),
    COOPV: require(`../assets/images/COOPV.png`),
    LYCA: require(`../assets/images/LYCA.svg`),
    LYCAD: require(`../assets/images/LYCA.svg`),
    DTEL: require(`../assets/images/DTEL.jpg`),
    SATO: require(`../assets/images/STPL.png`),
    CHILLI: require(`../assets/images/CHILLI.png`),
    SIMF: require(`../assets/images/SIMF.png`),
    DIGIM: require(`../assets/images/DDIGIM.svg`),
    KENA: require(`../assets/images/DKENA.svg`),
    // LMAX: require(`../assets/images/CHILLI.png`),
    VECTO: require(`../assets/images/VECTO.svg`),
    TALKH: require(`../assets/images/TALKH.png`),
    ORCHE: require(`../assets/images/ORCHE.png`),
    WIND3O: require(`../assets/images/WIND3.svg`),
    FSTWO: require(`../assets/images/FSTW.svg`),
    PALL: require(`../assets/images/PALL.png`),
    LBRA: require(`../assets/images/LBRA.svg`),
    BTIT: require(`../assets/images/BTIT.svg`),
    CELK: require(`../assets/images/CELK.png`),
    BITC: require(`../assets/images/BITC.png`),
    NSHP: require(`../assets/images/NSHP.png`),
    BWIN: require(`../assets/images/BWIN.svg`),
    PKST: require(`../assets/images/PKST.svg`),
    WHIL: require(`../assets/images/WHIL.svg`),
    STCS: require(`../assets/images/STCS.svg`),
    STPL: require(`../assets/images/STPL.png`),
    DAZN: require(`../assets/images/DAZN.svg`),
    AMZNP: require(`../assets/images/AMZNP.svg`),
    AMAZON: require(`../assets/images/AMZNP.svg`),
    CONAD: require(`../assets/images/CONAD.svg`),
    ESSEL: require(`../assets/images/ESSEL.svg`),
    GAMES: require(`../assets/images/GAMES.svg`),
    ITALO: require(`../assets/images/ITALO.svg`),
    MANGO: require(`../assets/images/MANGO.svg`),
    OVS: require(`../assets/images/OVS.svg`),
    PENNY: require(`../assets/images/PENNY.svg`),
    TAMOIL: require(`../assets/images/TAMOIL.svg`),
    UNIEUR: require(`../assets/images/UNIEUR.svg`),
    MD: require(`../assets/images/MD.svg`),
    NATURS: require(`../assets/images/NATURS.svg`),
    SPESAC: require(`../assets/images/SPESAC.svg`),
    AMZNC: require(`../assets/images/AMZNP.svg`),
    ZALAND: require(`../assets/images/ZALAND.svg`),
    ZLNDO: require(`../assets/images/ZALAND.svg`),
    APPLE: require(`../assets/images/APPLE.svg`),
    BTFG: require(`../assets/images/BTFG.png`),
    TELC: require(`../assets/images/TIM_logo_2016.svg`),
    UNOM: require(`../assets/images/UNOM.png`),
    PSSTO: require(`../assets/images/PSSTO.svg`),
    SONPSS: require(`../assets/images/PSSTO.svg`),
    SONY: require(`../assets/images/PSSTO.svg`),
    NINT: require(`../assets/images/NINT.svg`),
    NINTND: require(`../assets/images/NINT.svg`),
    XBOX: require(`../assets/images/XBOX.svg`),
    HELBIZ: require(`../assets/images/helbiz.svg`),
    HLBZ: require(`../assets/images/helbiz.svg`),
    MCHBTTR: require(`../assets/images/MUCHB.svg`),
    TOTOWB: require(`../assets/images/TOTOWB.png`),
    MUCHB: require(`../assets/images/MUCHB.svg`),
    NETFL: require(`../assets/images/NETFL.png`),
    NTLFX: require(`../assets/images/NETFL.png`),
    EUROSP: require(`../assets/images/EUROSP.svg`),
    ERSPRT: require(`../assets/images/EUROSP.svg`),
    WISHC: require(`../assets/images/WISHC.png`),
    WSHCRD: require(`../assets/images/WISHC.png`),
    BITN: require(`../assets/images/BITN.svg`),
    SPOTI: require(`../assets/images/SPOTIFY.svg`),
    SPTFY: require(`../assets/images/SPOTIFY.svg`),
    VOLAGR: require(`../assets/images/VOLAGR.png`),
    VOLGRTS: require(`../assets/images/VOLAGR.png`),
    SND000: require(`../assets/images/BOLL-Black.svg`),
    ALIPER: require(`../assets/images/ALIPER.svg`),
    ASOS: require(`../assets/images/ASOS.svg`),
    BENNET: require(`../assets/images/BENNET.svg`),
    BORBON: require(`../assets/images/borbone.svg`),
    BRICOI: require(`../assets/images/BRICOI.svg`),
    DESPAR: require(`../assets/images/DESPAR.svg`),
    FOOTL: require(`../assets/images/FOOTL.svg`),
    MEDIAW: require(`../assets/images/MEDIAW.svg`),
    MUSEM: require(`../assets/images/MUSEM.svg`),
    NESPRE: require(`../assets/images/NESPRE.svg`),
    NIKE: require(`../assets/images/nike2.svg`),
    PANORA: require(`../assets/images/PANORA.svg`),
    PRENAT: require(`../assets/images/PRENAT.svg`),
    Q8: require(`../assets/images/Q8.svg`),
    TIGOTA: require(`../assets/images/TIGOTA.svg`),
    UNES: require(`../assets/images/UNES.svg`),
    VIAGGI: require(`../assets/images/VIAGGI.svg`),
    FLIGHT: require(`../assets/images/flightgift.svg`),
    FLYKUB: require(`../assets/images/flykube.svg`),
    GROUPA: require(`../assets/images/groupalia.svg`),
    HOTELG: require(`../assets/images/hotelgift.svg`),
    NATURP: require(`../assets/images/naturplus.svg`),
    AMICAF: require(`../assets/images/amicafarmacia.svg`),
    DAYDRE: require(`../assets/images/daydream.svg`),
    FARMAO: require(`../assets/images/daydream.svg`),
    NIEMAN: require(`../assets/images/nieman.svg`),
    REGA24: require(`../assets/images/regali24.svg`),
    SIGMAD: require(`../assets/images/sigmadental.svg`),
    WALBER: require(`../assets/images/walber.svg`),
    ZOOPLU: require(`../assets/images/zooplus.svg`),
    HM: require("../assets/images/hm.svg"),
    HANDM: require("../assets/images/hm.svg"),
    PQUAD: require("../assets/images/PQUAD.jpeg"),
    COIN: require("../assets/images/COIN.svg"),
    DELIV: require("../assets/images/DELIV.svg"),
    FLIXB: require("../assets/images/FLIXB.svg"),
    IPER: require("../assets/images/IPER.svg"),
    PITTAR: require("../assets/images/PITTAR.svg"),
    ROADH: require("../assets/images/ROADH.svg"),
    SPART: require("../assets/images/SPART.svg"),
    TANNIC: require("../assets/images/TANNIC.svg"),
    TFORK: require("../assets/images/TFORK.svg"),
    TRENIT: require("../assets/images/TRENIT.svg"),
    GUESS: require("../assets/images/GUESS.svg"),
    IP: require("../assets/images/IP.svg"),
    "Bitnovo Voucher": require("../assets/images/bitnovo.svg"),
    "bitnovo-black": require("../assets/images/bitnovo-black.svg"),
    ServiceCRY: require("../assets/images/CRY.png"),
    CHILIT: require("../assets/images/CHILI.svg"),
    RKTNTV: require("../assets/images/RAKUTEN.svg"),
    XBOXU: require("../assets/images/XBOXGAMEPAS.svg"),
    TOYSC: require("../assets/images/TOYS.png"),
    STEAM: require("../assets/images/STEAM.svg"),
    TRONY: require("../assets/images/TRONY.svg"),
    KIABI: require("../assets/images/KIABI.svg"),
    BIMBOS: require("../assets/images/BIMBOS.png"),
    IDEAS: require("../assets/images/IDEAS.png"),
    IDEASB: require("../assets/images/IDEAS.png"),
    IDEAST: require("../assets/images/IDEAS.png"),
    IKEA: require("../assets/images/IKEA.svg"),
    TICK1: require("../assets/images/TICK1.svg"),
    DECAT: require("../assets/images/DECAT.svg"),
    TIGROS: require("../assets/images/TIGROS.svg"),
    UNICOM: require("../assets/images/UNICOM.svg"),
    CLEVER: require("../assets/images/CLEVER.svg"),
    NOWTEL: require("../assets/images/NOWTEL.svg"),
    FDRMBL: require("../assets/images/FEDERMOBILE.svg"),
    AIRBNB: require("../assets/images/AIRBNB.svg"),
    CDITNM: require("../assets/images/NUMBER1.svg"),
    LIBON: require("../assets/images/LIBON.svg"),
    YOUNG: require("../assets/images/YOUNG.svg"),
    GCDGT: require("../assets/images/GIOCODIG.svg"),
    GPLAY: require("../assets/images/GPLAY.svg"),
    BITSA: require("../assets/images/BITSA.svg"),
    NEOSRF: require("../assets/images/Neosurf.svg"),
    CARRE4: require("../assets/images/Carrefour.svg"),
    PPA: require("../assets/images/pago-pa.svg"),
    ITAAIR: require("../assets/images/ITAAIR.svg"),
    FBET: require("../assets/images/FBET.svg"),
    ENI: require("../assets/images/ENI.svg"),
    EUROSN: require("../assets/images/EUROSN.svg"),
    DOUGL: require("../assets/images/DOUGL.svg"),
    DECO: require("../assets/images/DECO.svg"),
    IPERAL: require("../assets/images/IPERAL.svg"),
    MEGAM: require("../assets/images/MEGAM.svg"),
    LFELTR: require("../assets/images/LFELTR.svg"),
    OKTOCH: require("../assets/images/OKTOCH.svg"),
    L8MTC: require("../assets/images/lottomatica-logo.svg"),
    AIRCSH: require("../assets/images/aircash-logo.svg"),
    GOLDB: require("../assets/images/goldbet-logo.svg"),
    PAYSD: require("../assets/images/paysafe-logo.svg"),
    MONST: require("../assets/images/mondadori-logo.svg"),
    SWIVE: require("../assets/images/swish-logo.svg"),
    EXPRT: require("../assets/images/expert-logo.svg"),
    MAXIDI: require("../assets/images/maxidi-logo.svg"),
    RINASC: require("../assets/images/rinascente-icon.svg"),
    TERRAN: require("../assets/images/terranova-logo.svg"),
    EBELLA: require("../assets/images/eb-icon.svg"),
    BWEST: require("../assets/images/best western-logo.svg"),
    RINASO: require("../assets/images/rinascimento-logo.svg"),
    WINEL: require("../assets/images/winelivery-logo.svg"),
    // SPEDIZ : require(`../assets/images/daydream.svg`),
    // SPEDI1 : require(`../assets/images/daydream.svg`),
    // COOPV: require(`../assets/images${skin}/bill-dark.svg`),
    // DKENA: require(`../assets/images${skin}/bill-dark.svg`),
    // HOX: require(`../assets/images${skin}/bill-dark.svg`),
    // FSTW: require(`../assets/images${skin}/bill-dark.svg`),
    // ILIO: require(`../assets/images${skin}/bill-dark.svg`)
    "google-authenticator": require(`../assets/images/google-authenticator-2.svg`),
    //prenotazioni images
    "bgame-logo": require(`../assets/images/prenotazioni/bgame-logo.svg`),
    "bgame-mobile": require(`../assets/images/bgame-mobile.svg`),

    "bgame-bg": require(`../assets/images/prenotazioni/bgame-bg.jpg`),
    "bgame-card": require(`../assets/images/prenotazioni/bgame-card.png`),

    "auto-logo": require(`../assets/images/prenotazioni/auto-logo.svg`),
    "auto-card": require(`../assets/images/prenotazioni/auto-card.png`),
    "auto-bg": require(`../assets/images/prenotazioni/auto-bg.jpg`),
    "auto-mobile": require(`../assets/images/prenotazioni/auto-mobile.svg`),

    "assicurazioni-logo": require(`../assets/images/prenotazioni/assicurazioni-logo.svg`),
    "assicurazioni-card": require(`../assets/images/prenotazioni/assicurazioni-card.jpg`),
    "assicurazioni-bg": require(`../assets/images/prenotazioni/assicurazioniNew-bg.jpg`),
    "assicurazioni-mobile": require(`../assets/images/prenotazioni/assicurazioni-mobile.svg`),

    "energia-logo": require(`../assets/images/prenotazioni/energia-logo.svg`),
    //"energia-mobile": require(`../assets/images/prenotazioni/energiaNew-mobile.svg`),
    "luce-gas-mobile": require(`../assets/images/prenotazioni/energia-mobile.svg`),
    "energia-card": require(`../assets/images/prenotazioni/energia-card.png`),

    "enagic-logo": require(`../assets/images/prenotazioni/enagic-logo.svg`),
    "enagic-bg": require(`../assets/images/prenotazioni/enagic-bg.jfif`),
    "enagic-card": require(`../assets/images/prenotazioni/enagic-card.jfif`),
    "enagic-mobile": require(`../assets/images/prenotazioni/enagic-logo.svg`),
    enagicimg1: require(`../assets/images/prenotazioni/enagic-img1.png`),
    enagicimg2: require(`../assets/images/prenotazioni/enagic-img2.png`),
    enagicplay: require(`../assets/images/prenotazioni/enagic-play-icon.png`),

    "expedia-logo": require(`../assets/images/prenotazioni/expedia.svg`),
    "expedia-bg": require(`../assets/images/prenotazioni/expedia-bg.jpg`),
    "expedia-card": require(`../assets/images/prenotazioni/expedia-card.png`),
    "expedia-mobile": require(`../assets/images/prenotazioni/expedia-mobile.svg`),

    "flixbus-logo": require(`../assets/images/prenotazioni/flixbus.svg`),
    "flixbus-bg": require(`../assets/images/prenotazioni/flixbus-bg.jpg`),
    "flixbus-card": require(`../assets/images/prenotazioni/flixbus-card.png`),
    "flixbus-mobile": require(`../assets/images/prenotazioni/flixbusNew-mobile.svg`),

    "trenitalia-logo": require(`../assets/images/prenotazioni/trenitalia.svg`),
    "trenitalia-bg": require(`../assets/images/prenotazioni/trenitalia-bg.jpg`),
    "trenitalia-card": require(`../assets/images/prenotazioni/trenitalia-card.png`),
    "trenitalia-mobile": require(`../assets/images/prenotazioni/trenitalia-mobile.svg`),

    "vivaticket-logo": require(`../assets/images/prenotazioni/vivaticket.svg`),
    "vivaticket-bg": require(`../assets/images/prenotazioni/vivaticket-bg.jpg`),
    "vivaticket-card": require(`../assets/images/prenotazioni/vivaticket-card.png`),
    "vivaticket-mobile": require(`../assets/images/prenotazioni/vivaticket-mobile.svg`),

    "bigliettieria eventi-logo": require(`../assets/images/prenotazioni/biglietti-logo.svg`),
    "biglietti-logo": require(`../assets/images/prenotazioni/biglietti-logo.svg`),
    "bigliettieria eventi-bg": require(`../assets/images/prenotazioni/biglietti-bg.jpg`),
    "biglietti-card": require(`../assets/images/prenotazioni/biglietti-card.png`),
    "biglietti-mobile": require(`../assets/images/prenotazioni/biglietti-mobile.svg`),
    "biglietti-background": require(`../assets/images/prenotazioni/biglietti-background.jpg`),

    "ticketing-logo": require(`../assets/images/prenotazioni/ticketing.svg`),
    "ticketone-logo": require(`../assets/images/prenotazioni/ticketing.svg`),
    "ticketing-bg": require(`../assets/images/prenotazioni/ticketing-bg.jpg`),
    "ticketing-card": require(`../assets/images/prenotazioni/ticketing-card.png`),
    "ticketone-mobile": require(`../assets/images/prenotazioni/ticketone-mobile.svg`),

    "stubhub-logo": require(`../assets/images/prenotazioni/stubhub.svg`),
    "stubhub-bg": require(`../assets/images/prenotazioni/stubhub-bg.jpg`),
    "stubhub-card": require(`../assets/images/prenotazioni/stubhub-card.png`),
    "stubhub-mobile": require(`../assets/images/prenotazioni/stubhub-mobile.svg`),

    "visure-logo": require(`../assets/images/prenotazioni/visure-logo.svg`),
    "visure-card": require(`../assets/images/prenotazioni/visure-card.png`),

    "shop-online-mobile": require(`../assets/images/prenotazioni/online-shop-mobile.svg`),
    "shop-online-card": require(`../assets/images/prenotazioni/shop-online-card.png`),
    "shop-online-logo": require(`../assets/images/prenotazioni/shop-online-logo.svg`),
    "shop-online-bg": require(`../assets/images/prenotazioni/shop-online-bg.png`),

    "finanziamenti-card": require(`../assets/images/prenotazioni/finanziamenti-card.png`),
    "finanziamenti-logo": require(`../assets/images/prenotazioni/finanziamenti-logo.svg`),
    "finanziamenti-bg": require(`../assets/images/prenotazioni/finanziamenti-bg.jpg`),
    "finanziamenti-mobile": require(`../assets/images/prenotazioni/finanziamenti-mobile.svg`),

    "facileristrutturare-card": require(`../assets/images/prenotazioni/facileristrutturare-card.jpg`),
    "facileristrutturare-bg": require(`../assets/images/prenotazioni/facileristrutturare-bg.jpg`),
    "facileristrutturare-logo": require(`../assets/images/prenotazioni/facileristrutturare-logo.svg`),
    "facileristrutturare-mobile": require(`../assets/images/prenotazioni/facileristrutturare-mobile.svg`),

    "internetveloce-card": require(`../assets/images/prenotazioni/internetveloce-card.png`),
    "internetveloce-logo": require(`../assets/images/prenotazioni/internetveloce-logo.svg`),

    // "stubhub-lg": require(`../assets/images/prenotazioni/stubhub-logo.svg`),
    // "vivaticket-lg": require(`../assets/images/prenotazioni/vivaticket-logo.svg`),
    // "ticketone-lg": require(`../assets/images/prenotazioni/ticketone-logo.svg`),

    visure: require(`../assets/images/prenotazioni/visure-logo.svg`),
    "visure-mobile": require(`../assets/images/prenotazioni/visure-logo.svg`),
    "energia-bg": require(`../assets/images/prenotazioni/energia.png`),
    spedizione: require(`../assets/images/spedizione-img.png`),
    commingSoon: require(`../assets/images/commingSoon.gif`),
    ServiceDefault: require(`../assets/images/ServiceDefault.jpg`),
    ServiceBET: require(`../assets/images/ServiceBET.jpg`),
    ServiceFLB: require(`../assets/images/ServiceFLB.jpeg`),
    ServiceBTL: require(`../assets/images/ServiceBTL.jpg`),
    ServiceBGM: require(`../assets/images/BANNERBGAME.png`),
    ServiceBUL: require(`../assets/images/BANNERSPORTBULL.jpg`),
    ServiceBBL: require(`../assets/images/BANNERSPORTBULL.jpg`),
    ServiceBWN: require(`../assets/images/ServiceBWN.jpg`),
    ServicePKS: require(`../assets/images/ServicePKS.jpg`),
    ServicePPB: require(`../assets/images/ServicePPB.jpg`),
    ServiceSTC: require(`../assets/images/ServiceSTC.jpg`),
    ServiceWHL: require(`../assets/images/ServiceWHL.jpg`),
    ServiceBBT: require(`../assets/images/ServiceBGM.jpg`),
    ServiceSTB: require(`../assets/images/BANNERSTANLEYBET.png`),
    ServiceTPP: require(`../assets/images/BANNERTOTOPAY.png`),
    ServiceAMB: require(`../assets/images/amazingBet.jpeg`),
    ServicePSC: require(`../assets/images/PSC.jpg`),
    ServiceFBT: require(`../assets/images/ServiceFBET.jpg`),
    ServiceAMZ: require(`../assets/images/ServiceAMZ.jpg`),
    SMTD: require(`../assets/images/scommettendo.png`),
    AMBT: require(`../assets/images/amazingbet.png`),
    EPLY: require(`../assets/images/eplay.png`),
    // "visure-logo": require(`../assets/images/prenotazioni/stubhub.svg`),
    MBBT: require(`../assets/images/mideporte-blog.png`),
    faturaBackground: require(`../assets/images/FaturaBackground.png`),
    mobileLoginGirl: require(`../assets/images/OnlineShop.jpg`),
    transactionModalImg: require(`../assets/images/transactionModal.svg`),
    // shop
    //
    bigbuy: require(`../assets/shop/bigbuy.svg`),
    mainBanner: require(`../assets/shop/banner.jpg`),
    mainBanner2: require(`../assets/shop/banner2.jpg`),
    mainBanner3: require(`../assets/shop/banner3.jpg`),
    adidas: require(`../assets/shop/adidas.svg`),
    zara: require(`../assets/shop/zara.svg`),
    lacoste: require(`../assets/shop/lacoste.svg`),
    nike: require(`../assets/shop/nike.svg`),
    philipPlein: require(`../assets/shop/phillip-plein.svg`),
    calvinClein: require(`../assets/shop/calvin-klein.svg`),

    product: require(`../assets/shop/product.svg`),
    product2: require(`../assets/shop/product-2.svg`),

    promotionBottom: require(`../assets/shop/promotionBottom.svg`),

    bg1: require(`../assets/shop/Bg1.svg`),
    bg2: require(`../assets/shop/Bg2.svg`),
    bg3: require(`../assets/shop/Bg3.svg`),
    bg4: require(`../assets/shop/Bg4.svg`),

    informaticaelettronica: require(`../assets/shop/informaticaelettronica.png`),
    sportfitness: require(`../assets/shop/sportfitness.svg`),
    sportrelax: require(`../assets/shop/sportfitness.png`),
    casagiardino: require(`../assets/shop/casagiardino.png`),
    cucinagourmet: require(`../assets/shop/cucinagourmet.png`),
    giochibambini: require(`../assets/shop/giochibambini.jpg`),
    giocattolicostumi: require(`../assets/shop/giocattolicostumi.png`),
    modaaccessori: require(`../assets/shop/modaaccessori.png`),
    outletofferte: require(`../assets/shop/Bg2.svg`),
    outletofferteMob: require(`../assets/shop/shopBannerMob.png`),
    offerterefurbished: require(`../assets/shop/Bg2.svg`),
    profumeriacosmesi: require(`../assets/shop/profumeriacosmesi.png`),
    salutebellezza: require(`../assets/shop/salutebellezza.png`),
    relaxtempolibero: require(`../assets/shop/relaxtempolibero.png`),
    regalioriginali: require(`../assets/shop/relaxtempolibero.png`),
    sexyshoperotico: require(`../assets/shop/sexyshoperotico.png`),
    offertericondizionati: require(`../assets/shop/offertericondizionati.png`),
    televenditavistointv: require(`../assets/shop/televenditavistointv.png`),

    bannerArredamento1: require(`../assets/shop/bannerArredamento1.png`),
    bannerArredamento2: require(`../assets/shop/bannerArredamento2.png`),

    casagiardinoBanner: require(`../assets/shop/casagiardinoBanner.png`),
    salutebellezzaBanner: require(`../assets/shop/salutebellezzaBanner.png`),
    regalioriginaliBanner: require(`../assets/shop/regalioriginaliBanner.png`),

    giocattolicostumiImg: require(`../assets/shop/giocattolicostumiImg.png`),
    informaticaelettronicaImg: require(`../assets/shop/informaticaelettronicaImg.png`),

    bolletinoFake: require("../assets/images/bolletinoFake.png"),

    tnt: require(`../assets/shop/tnt.png`),
    ups: require(`../assets/shop/ups.png`),
    ups_aereo: require(`../assets/shop/ups.png`),
    posteitaliane: require(`../assets/shop/posteitaliane.png`),
    gls: require(`../assets/shop/gls.png`),
    seur: require(`../assets/shop/seur.png`),
    dhl: require(`../assets/shop/dhl.png`),
    EA: require(`../assets/images/EA.svg`),
    ROBLOX: require(`../assets/images/ROBLOX.svg`),
    RBLX: require(`../assets/images/ROBLOX.svg`),

    mammaroHeader: require("../assets/images/Mamanero_Logo.svg"),
    SepafinLogo: require("../assets/images/SepafinLogo.svg"),
    placeholder: require(`../assets/images/placeholder.jpg`),
    "": require(`../assets/images/placeholder.jpg`),

    pdf_img: require(`../assets/images/pdf-img.png`),
    csv_img: require(`../assets/images/csv-img.png`),

    euro_icon: require(`../assets/images/euro_icon.svg`),
    euro_sign: require(`../assets/images/euro_sign.svg`),
    cent_sign: require(`../assets/images/cent_sign.svg`),

    ups: require(`../assets/images/UPS.svg`),
    sda: require(`../assets/images/SDA.svg`),
    brt: require(`../assets/images/BRT.svg`),

    internet_popup: require(`../assets/images/internet_popup.png`),
    internet_popup_img: require(`../assets/images/internet_popup_img.webp`),

    RTELC: require(`../assets/images/ricaricaPIN.svg`),
    RTELD: require(`../assets/images/ricaricaSIM.svg`),

    logoMain55: require(`../assets/images55/logoMain.svg`),

    Sepafin: require("../assets/images/SepafinLogo.svg"),
    BGame: require(`../assets/images/bgame.svg`),
    BBet: require(`../assets/images/bbet.svg`),
    MrPay: require(`../assets/images/mr-pay_logo.svg`),
    BigBuy: require(`../assets/shop/bigbuy.svg`),
    SportBull: require(`../assets/images52/logoSport.svg`),
    Mideporte: require(`../assets/images/mideporte-blog.png`),
    Flashbet: require(`../assets/images/Flashbet_logo.svg`),
    FlashbetFlashpoints: require(`../assets/images/Flashbet_logo.svg`),
    Amazingbet: require(`../assets/images/amazingbet.png`),
    VisureOpenApi: require(`../assets/images/openapi_logo.svg`),
    Bitnovo: require("../assets/images/bitnovo.svg"),
    Betaland: require(`../assets/images/betaland.svg`),
    MrPayStanley: require(`../assets/images/stanley.svg`),
    SpediamoPro: require(`../assets/images/Spediamopro_logo.svg`),
    "E-Pay": require(`../assets/images/epay.svg`),
    mrPayBanner: require(`../assets/images/mrPayBanner.svg`),
    mrPayFooter: require(`../assets/images/mrPayFooter.svg`),


    "arrow-back": require("../assets/images/arrow-back.svg"),
    "pdf-icon": require("../assets/images/pdf-icon.svg"),

    getLogoBySkinId: (e) => {
      try {
        return require(`../assets/images${e === 8 ? 6 : e === 51 ? 7 : e}/logo.svg`);
      } catch (error) {
      return  require(`../assets/images/placeholder.jpg`);
      }
    },
  }
} catch (err) {
  console.error("error", err)
}

export default imgObj
