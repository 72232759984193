import { Tabs } from "antd"
import React, { useEffect, useState } from "react"
import StatisticheAnnuali from "./StatisticheAnnuali"
import moment from "moment"
import StatisticheMensili from "./StatisticheMensili"
import { DatePicker } from "antd"
import AgenziePerformanti from "./AgenziePerformanti"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

const { RangePicker } = DatePicker
const { TabPane } = Tabs

const StatisticheTabs = (props) => {
  const [activeKey, setActiveKey] = useState("1")
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [selectedFilter, setSelectedFilter] = useState(5)
  const { getStatistiche, activeSkinId } = props
  const filters = [
    {
      name: "Oggi",
      key: 3,
    },
    {
      name: "Settimanale",
      key: 4,
    },
    {
      name: "Mensile",
      key: 5,
    },
    {
      name: "Annuale",
      key: 6,
    },
  ]

  const changeSelected = (filter) => {
    setSelectedFilter(filter)
    if (filter == 3) {
      const fromDate = moment().format("YYYY-MM-DD")
      setFrom(fromDate)
      setTo(fromDate)
    }
    if (filter == 4) {
      const fromDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .format("YYYY-MM-DD")
      const toDate = moment().format("YYYY-MM-DD")
      setFrom(fromDate)
      setTo(toDate)
    }
    if (filter == 5) {
      setFrom("")
      setTo("")
    }
  }

  const onChange = (key) => {
    setActiveKey(key)
  }

  const handleDateRangeChange = (dates) => {
    if (dates.length > 0) {
      setFrom(moment(dates[0]._d).format("DD-MM-YYYY"))
      setTo(moment(dates[1]._d).format("DD-MM-YYYY"))
    } else {
      setFrom("")
      setTo("")
    }
  }

  useEffect(() => {
    getStatistiche(activeSkinId, from, to, "", "", "")
  }, [activeSkinId, from, to])

  return (
    <div className="statistiche-tabs">
      <div className="filter-options">
        {filters.map((filter) => (
          <span
            key={filter.key}
            className={`filter-option ${
              filter.key == selectedFilter ? "active" : ""
            }`}
            onClick={() => changeSelected(filter.key)}
          >
            {filter.name}
          </span>
        ))}
        <RangePicker onChange={handleDateRangeChange} />
      </div>

      <Tabs defaultActiveKey="1" onChange={onChange} activeKey={activeKey}>
        <TabPane tab="Movimenti" key="1" style={{ width: "70%" }}>
          {selectedFilter == "6" ? <StatisticheAnnuali annualiStatitiche={props.annualiStatitiche}/> : <StatisticheMensili />}
        </TabPane>
        <TabPane tab="Provvigioni" key="2" style={{ width: "70%" }} disabled>
          {/* Provvigioni */}
        </TabPane>
        <AgenziePerformanti />
      </Tabs>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  StatisticheTabs
)
