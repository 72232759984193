import React, { useEffect, useState } from "react"
import { Input, Button, List, Transfer, Select, Form, Divider, Modal } from "antd"
import axios from "axios"
import DisplayCategories from "./DisplayCategories"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { endpoint } from "../../config/api"

const { Option } = Select

const CategoryManagement = (props) => {
  const [categoryInput, setCategoryInput] = useState("")
  const [updatedCategoryInput, setUpdatedCategoryInput] = useState("")
  const [categories, setCategories] = useState([])
  const [subcategoryInput, setSubcategoryInput] = useState("")
  const [updatedSubcategoryInput, setUpdatedSubcategoryInput] = useState("")
  const [editingCategoryIndex, setEditingCategoryIndex] = useState(-1)
  const [editingSubcategoryIndex, setEditingSubcategoryIndex] = useState(-1)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCategoryBackend, setSelectedCategoryBackend] = useState(null)
  const [selectedSubcategoryBackend, setSelectedSubcategoryBackend] = useState(null)
  const [selectedSupplier, setSelectedSupplier] = useState("E-Pay")
  const [loading, setLoading] = useState(false)
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false)
  const [showDeleteSubcategoryModal, setShowDeleteSubcategoryModal] = useState(false)
  const [newSubcategoryInput, setNewSubcategoryInput] = useState("")
  const token = props?.accountInfo?.token

  const options = [
    { value: "E-Pay", label: "E-Pay" },
    { value: "OpenAPI.it", label: "OpenAPI.it" },
    { value: "SpediamoPro", label: "SpediamoPro" },
  ]


  const handleServicesTree = async () => {
    try {
      await props.getServicesTree()
    } catch (error) {
      console.error(error)
    }
  }

  const handleServices = async () => {
    try {
      await props.getServicesData()
    } catch (error) {
      console.error(error)
    }
  }

  const transformedData = []
  const categoryMap = {}

  for (const key in props.servicesTree) {
    const entry = props.servicesTree[key]
    if (entry.group && entry.name) {
      const selectedServices = Object.keys(entry)
        .filter(
          (subKey) => subKey !== "name" && subKey !== "group" && subKey !== "id"
        )
        .map((subKey) => entry[subKey].name)

      if (!categoryMap[entry.group]) {
        categoryMap[entry.group] = {
          // id: entry.id,
          name: entry.group,
          subcategories: [],
        }
      }

      categoryMap[entry.group].subcategories.push({
        id: entry.id,
        name: entry.name,
        selectedServices,
      })
    }
  }

  // Convert the categoryMap object to an array
  for (const categoryName in categoryMap) {
    transformedData.push(categoryMap[categoryName])
  }


  useEffect(() => {
    handleServices()
    handleServicesTree()
  }, [])

  const addCategory = () => {
    if (categoryInput.trim() !== "") {
      const newCategory = {
        name: categoryInput,
        subcategories: [],
        supplier: selectedSupplier,
      }
      setCategories([...categories, newCategory])
      setCategoryInput("")
      setSelectedCategory(newCategory)
      setSelectedCategoryBackend(null)
    }
  }

  const addSubcategory = (categoryIndex) => {
    if (subcategoryInput.trim() !== "") {
      const updatedCategories = [...categories]
      updatedCategories[categoryIndex].subcategories.push({
        name: subcategoryInput,
        selectedServices: [],
      })
      setCategories(updatedCategories)
      setSubcategoryInput("")
    }
  }

  const editCategory = (categoryIndex) => {
    setEditingCategoryIndex(categoryIndex)
  }

  const editSubcategory = (categoryIndex, subcategoryIndex) => {
    setEditingSubcategoryIndex({
      categoryIndex,
      subcategoryIndex,
    })
  }

  const saveCategory = () => {
    setEditingCategoryIndex(-1)
  }

  const saveSubcategory = () => {
    setEditingSubcategoryIndex(-1)
  }

  const sendData = (data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    axios
      .post(`${endpoint}/category`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res)
        setCategories([])
        handleServicesTree()
      })
      .catch((err) => console.log(err))
  }

  const updateCategory = () => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(
        `${endpoint}/category-update`,
        {
          id: selectedSubcategoryBackend.id,
          group:
            updatedCategoryInput !== ""
              ? updatedCategoryInput
              : selectedCategoryBackend.name,
          full_name:
            updatedSubcategoryInput !== ""
              ? updatedSubcategoryInput
              : selectedSubcategoryBackend.name,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        handleServicesTree()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const updateSelectedServicesList = (newTargetKeys, direction) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(
        `${endpoint}/update-transfer-companies`,
        {
          category_id: selectedSubcategoryBackend.id,
          full_names: newTargetKeys,
          directions: direction === "right" ? true : false,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        handleServicesTree()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleDeleteCategory = (categoryName) => {
    setLoading(true)

    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .delete(
        `${endpoint}/category/delete/${categoryName}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        handleServicesTree()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleDeleteSubcategory = (subcategoryId) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .delete(
        `${endpoint}/subcategory/delete/${subcategoryId}`,
        {
          subcategoryId: subcategoryId,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        handleServicesTree()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleAddSubcategory = (groupName, subcategoryName) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(
        `https://new-staging.bpoint.store/api/tttttttttt`,
        {
          subcategoryName: subcategoryName,
          group: groupName,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        handleServicesTree()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleChangeSupplier = (value) => {
    setSelectedSupplier(value)
  }

  const showDeleteCategoryConfirmation = () => {
    setShowDeleteCategoryModal(true)
  }

  const closeDeleteCategoryModal = () => {
    setShowDeleteCategoryModal(false)
  }

  const showDeleteSubcategoryConfirmation = () => {
    setShowDeleteSubcategoryModal(true)
  }

  const closeDeleteSubcategoryModal = () => {
    setShowDeleteSubcategoryModal(false)
  }

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <div style={{ padding: 40, width: "40%" }}>
          <div>
            <Form
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              layout="horizontal"
            >
              <Form.Item label="Category ">
                <Input
                  placeholder="Enter Category"
                  value={categoryInput}
                  onChange={(e) => setCategoryInput(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Supplier">
                <Select onChange={handleChangeSupplier} value={selectedSupplier}>
                  {options.map((option) => (
                    <Option key={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Button onClick={addCategory} block style={{ marginBottom: 20 }}>
                ADD
              </Button>
            </Form>
          </div>
          {selectedCategory && (
            <div>
              {editingCategoryIndex === categories.indexOf(selectedCategory) ? (
                <div style={{ display: "flex" }}>
                  <Input
                    placeholder="Enter Subcategory"
                    value={selectedCategory.name}
                    onChange={(e) => {
                      const updatedCategories = [...categories]
                      updatedCategories[categories.indexOf(selectedCategory)].name =
                        e.target.value
                      setCategories(updatedCategories)
                    }}
                  />
                  <div></div>
                  <Button
                    type="primary"
                    onClick={() =>
                      saveCategory(categories.indexOf(selectedCategory))
                    }
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h5>{selectedCategory.name}</h5>
                  <Button
                    onClick={() =>
                      editCategory(categories.indexOf(selectedCategory))
                    }
                  >
                    Edit
                  </Button>
                </div>
              )}
              {selectedCategory.subcategories.length > 0 && (
                <List
                  dataSource={selectedCategory.subcategories}
                  renderItem={(subcategory, subcategoryIndex) => (
                    <>
                      <List.Item>
                        {editingSubcategoryIndex.categoryIndex ===
                          categories.indexOf(selectedCategory) &&
                        editingSubcategoryIndex.subcategoryIndex ===
                          subcategoryIndex ? (
                          <>
                            <Input
                              placeholder="Enter Subcategory"
                              value={subcategory.name}
                              onChange={(e) => {
                                const updatedCategories = [...categories]
                                updatedCategories[
                                  categories.indexOf(selectedCategory)
                                ].subcategories[subcategoryIndex].name =
                                  e.target.value
                                setCategories(updatedCategories)
                              }}
                            />
                            <div></div>
                            <Button
                              type="primary"
                              onClick={() =>
                                saveSubcategory(
                                  categories.indexOf(selectedCategory),
                                  subcategoryIndex
                                )
                              }
                            >
                              Save
                            </Button>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {subcategory.name}
                            <Button
                              onClick={() =>
                                editSubcategory(
                                  categories.indexOf(selectedCategory),
                                  subcategoryIndex
                                )
                              }
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                      </List.Item>
                      {selectedSubcategory &&
                        selectedSubcategory?.name === subcategory?.name && (
                          <Transfer
                            showSearch
                            listStyle={{
                              width: "46%",
                              height: 250,
                            }}
                            filterOption={(inputValue, option) =>
                              option.title
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            dataSource={Object.keys(props.servicesData).map((serviceKey) => ({
                              key: serviceKey,
                              title: serviceKey,
                            }))}
                            titles={["Services", "Selected"]}
                            targetKeys={selectedSubcategory.selectedServices}
                            onChange={(newTargetKeys) => {
                              const updatedCategories = [...categories]
                              updatedCategories[
                                categories.indexOf(selectedCategory)
                              ].subcategories[subcategoryIndex].selectedServices =
                                newTargetKeys
                              setCategories(updatedCategories)
                              setSelectedSubcategory(null)
                            }}
                            render={(item) => item.title}
                          />
                        )}
                    </>
                  )}
                />
              )}
              <Form
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                layout="horizontal"
              >
                <Form.Item label="Subcategory">
                  <Input
                    placeholder="Enter Subcategory"
                    onChange={(e) => setSubcategoryInput(e.target.value)}
                    value={subcategoryInput}
                  />
                </Form.Item>
                <Button
                  block
                  onClick={() =>
                    addSubcategory(categories.indexOf(selectedCategory))
                  }
                >
                  ADD
                </Button>
              </Form>
            </div>
          )}

          <Button
            type="primary"
            block
            onClick={() => sendData(categories)}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            SEND DATA
          </Button>
          <Divider />
          {selectedCategoryBackend && (
            <div>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <Input
                  placeholder="Enter Category"
                  value={updatedCategoryInput || selectedCategoryBackend.name}
                  onChange={(e) => {
                    setUpdatedCategoryInput(e.target.value)
                  }}
                />
                <Button
                  icon="delete"
                  onClick={showDeleteCategoryConfirmation}
                ></Button>
              </div>

              {selectedCategoryBackend &&
                selectedSubcategoryBackend &&
                selectedCategoryBackend.subcategories.some(
                  (subcategory) => subcategory.id === selectedSubcategoryBackend.id
                ) && (
                  <>
                    <div
                      style={{ display: "flex", marginTop: 10, marginBottom: 10 }}
                    >
                      <Input
                        placeholder="Enter Subcategory"
                        value={
                          updatedSubcategoryInput || selectedSubcategoryBackend.name
                        }
                        onChange={(e) => setUpdatedSubcategoryInput(e.target.value)}
                      />
                      <Button
                        icon="delete"
                        onClick={showDeleteSubcategoryConfirmation}
                      ></Button>
                    </div>
                    <Button
                      type="primary"
                      onClick={updateCategory}
                      block
                      style={{ marginBottom: 20, marginTop: 12 }}
                    >
                      UPDATE
                    </Button>
                  </>
                )}

              {/* </List.Item> */}
              {selectedCategoryBackend &&
                selectedSubcategoryBackend &&
                selectedCategoryBackend.subcategories.some(
                  (subcategory) => subcategory.id === selectedSubcategoryBackend.id
                ) && (
                  <Transfer
                    listStyle={{
                      width: "46%",
                      height: 250,
                      marginBottom: 10,
                    }}
                    showSearch
                    filterOption={(inputValue, option) =>
                      option.title.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    dataSource={Object.keys(props.servicesData).map((serviceKey) => ({
                      key: serviceKey,
                      title: serviceKey,
                    }))}
                    titles={["Services", "Selected"]}
                    targetKeys={selectedSubcategoryBackend.selectedServices}
                    onChange={(newTargetKeys, direction) => {
                      updateSelectedServicesList(newTargetKeys, direction)
                      setSelectedSubcategoryBackend(null)
                    }}
                    render={(item) => item.title}
                  />
                )}

              <Form
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                layout="horizontal"
              >
                <Form.Item label="Subcategory">
                  <Input
                    placeholder="Enter Subcategory"
                    onChange={(e) => setNewSubcategoryInput(e.target.value)}
                    value={newSubcategoryInput}
                  />
                </Form.Item>
                <Button
                  block
                  onClick={() =>
                    handleAddSubcategory(
                      selectedCategoryBackend.name,
                      newSubcategoryInput
                    )
                  }
                >
                  ADD
                </Button>
              </Form>
            </div>
          )}
        </div>
        <div style={{ width: "50%" }}>
          {/* Backend Data */}
          <DisplayCategories
            categories={transformedData}
            onSelectSubcategory={(subcategory) => {
              setSelectedSubcategoryBackend(subcategory)
              setSelectedSubcategory(null)
            }}
            onSelectCategory={(category) => {
              setSelectedCategoryBackend(category)
              setSelectedCategory(null)
            }}
            loading={loading}
          />
          {/* Frontend Data */}
          <DisplayCategories
            categories={categories}
            onSelectSubcategory={(subcategory) => {
              setSelectedSubcategory(subcategory)
              setSelectedSubcategoryBackend(null)
            }}
            onSelectCategory={(category) => {
              setSelectedCategory(category)
              setSelectedCategoryBackend(null)
            }}
          />
        </div>
      </div>
      <Modal
        title="Delete Category"
        visible={showDeleteCategoryModal}
        onOk={() => {
          handleDeleteCategory(selectedCategoryBackend.name)
          closeDeleteCategoryModal()
        }}
        onCancel={closeDeleteCategoryModal}
      >
        {`Are you sure you want to delete this category: ${selectedCategoryBackend?.name} ?`}
      </Modal>
      <Modal
        title="Delete Subcategory"
        visible={showDeleteSubcategoryModal}
        onOk={() => {
          handleDeleteSubcategory(selectedSubcategoryBackend.id)
          closeDeleteSubcategoryModal()
        }}
        onCancel={closeDeleteSubcategoryModal}
      >
        {`Are you sure you want to delete this subcategory: ${selectedCategoryBackend?.subcategories[0]?.name} ?`}
      </Modal>
    </>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesData: state.main.servicesData,
  servicesTree: state.main.servicesTree
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CategoryManagement
)
