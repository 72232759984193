import { all, fork, take, call } from "redux-saga/effects"
/* ------------- SAGA GENERATORS ------------- */
import * as AuthGenerators from "./AuthSagas"
import * as MainGenerators from "./MainSagas"
import * as ShopGenerators from "./ShopSagas"
function* takeOneAndBlock(pattern, worker, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern)
      yield call(worker, ...args, action)
    }
  })
  return task
}

export default function* root() {
  yield all([
    //AUTH Generators
    ...[
      takeOneAndBlock("GET_AGENCY_POS", AuthGenerators.getAgencyPos),
      takeOneAndBlock("CANCEL_PAGAMENTO", AuthGenerators.cancelPagamento),
      takeOneAndBlock(
        "GET_COMPANY_NAME_BY_CONTO_CORRENTO",
        AuthGenerators.getCompanyNameByContoCorrento
      ),
      takeOneAndBlock("GET_REFILLS", AuthGenerators.getRefills),
      takeOneAndBlock("CHANGE_AGENT_SKIN", AuthGenerators.changeAgentSkin),
      takeOneAndBlock("PAGO_TICKET", AuthGenerators.pagoTicket),
      takeOneAndBlock("CREATE_USER_BGAME", AuthGenerators.createUserBgame),
      takeOneAndBlock("GET_REGISTRAZIONE_DATA", AuthGenerators.getRegistrazioneData),
      takeOneAndBlock("SET_PAY_F_SAGA", AuthGenerators.setPayFSaga),
      takeOneAndBlock(
        "ACTIVATE_DEACTIVATE_SEPAFIN",
        AuthGenerators.activateDeactivateSepafin
      ),
      takeOneAndBlock("NUOVO_BILLER_SUBMIT", AuthGenerators.nuovoBillerSubmit),
      takeOneAndBlock("BUY_VISURE", AuthGenerators.buyVisure),
      takeOneAndBlock("SET_BOKING_SEP", AuthGenerators.setBokingSep),
      takeOneAndBlock("INTERNET_REGISTER", AuthGenerators.internetRegister),
      takeOneAndBlock("PAY_PAGO_PA", AuthGenerators.payPagoPa),
      takeOneAndBlock("SET_MAV_RAV", AuthGenerators.setMavRav),
      takeOneAndBlock("SET_PAGO_PA", AuthGenerators.setPagoPa),
      takeOneAndBlock("MR_PAY_PAGO_PA", AuthGenerators.mrPayPagoPa),
      takeOneAndBlock("GET_BOLLETINI_AUTO", AuthGenerators.getBolletiniAuto),
      takeOneAndBlock("BOLLETINO_CAPTURE", AuthGenerators.bolletinoCapture),
      takeOneAndBlock("SET_FRECCIA", AuthGenerators.setFreccia),
      takeOneAndBlock("BUY_TICKET_ONLINE", AuthGenerators.buyTicketOnline),
      takeOneAndBlock("BUY_ASSICURAZIONE", AuthGenerators.buyAssicurazione),
      takeOneAndBlock("FETCH_BOLLETINI", AuthGenerators.fetchBolletini),
      takeOneAndBlock("GET_STATISTICHE_MAIN", AuthGenerators.getStatisticheMain),
      takeOneAndBlock(
        "GET_VISURE_BY_SERVICE_ID",
        AuthGenerators.getVisureByServiceId
      ),
      takeOneAndBlock("GET_VISURE_DOCUMENT", AuthGenerators.getVisureDocument),
      takeOneAndBlock("GET_BUSSINESS_INFOS", AuthGenerators.getBussinessInfos),
      takeOneAndBlock("GET_CUSTOM_VOUCHER_REQ", AuthGenerators.getCustomVoucherReq),
      takeOneAndBlock("BUY_BIT_NOVO_VOUCHER", AuthGenerators.buyBitNovoVoucher),
      takeOneAndBlock(
        "UPDATE_SERVICES_CHANGE_STATUS",
        AuthGenerators.UpdateServiceChangeStatus
      ),
      takeOneAndBlock("GET_STATISTICHE", AuthGenerators.getStatistiche),
      takeOneAndBlock("ADD_EXTRA_DATA", AuthGenerators.AddExtraData),
      takeOneAndBlock("ADD_SUPER_ADMIN", AuthGenerators.AddSuperAdmin),
      takeOneAndBlock(
        "ADD_INFORMAZIONI_FINANZIARIO",
        AuthGenerators.AddInformazioniFinanziario
      ),
      takeOneAndBlock("GET_PAYMENTS_FOR_EXCEL", AuthGenerators.getPaymentsForExcel),
      takeOneAndBlock(
        "GET_ALL_PAYMENTS_FOR_EXCEL",
        AuthGenerators.getAllPaymentsForExcel
      ),
      takeOneAndBlock("ADD_SKIN_NEW", AuthGenerators.AddSkinNew),
      takeOneAndBlock("SEND_MAIL_FATTURA", AuthGenerators.sendMailFattura),
      takeOneAndBlock(
        "GET_ALL_FATURA_BY_SEARCH",
        AuthGenerators.getAllFaturaBySearch
      ),
      takeOneAndBlock("GET_ALL_SERVICES", AuthGenerators.getAllServices),
      takeOneAndBlock("GET_FATURA_DETAILS", AuthGenerators.getFaturaDetails),
      takeOneAndBlock("SEPAFIN_CREDENTIALS", AuthGenerators.sepafinCredentials),
      takeOneAndBlock("SEPAFIN_LOGIN", AuthGenerators.sepafinLogin),
      takeOneAndBlock("GET_BOOKINGS_BOL_MR", AuthGenerators.getBookingsBolMr),
      takeOneAndBlock(
        "SEPAFIN_FORGET_PASSWORD",
        AuthGenerators.sepafinForgetPassword
      ),
      takeOneAndBlock("GET_WIDGET_PAYMENTS", AuthGenerators.getWidgetPayments),

      takeOneAndBlock("GET_SKINS", AuthGenerators.getSkins),
      takeOneAndBlock("UPDATE_VISURA", AuthGenerators.updateVisura),
      takeOneAndBlock("ADD_VISURE", AuthGenerators.addVisure),
      // AUTH h
      takeOneAndBlock("GET_AGENT_BY_USER_ID", AuthGenerators.getAgentByUserId),
      takeOneAndBlock("GET_USER_BY_USER_ID", AuthGenerators.getUserByUserId),
      takeOneAndBlock("GET_VISURE_BY_VISURE_ID", AuthGenerators.getVisureByVisureId),
      takeOneAndBlock("GET_VISURE", AuthGenerators.getVisure),
      takeOneAndBlock(
        "GET_DATA_FORM_DETAILS_ACTIVES",
        AuthGenerators.getDataFormDetailsActives
      ),
      takeOneAndBlock("ADD_TICKET", AuthGenerators.addTicket),
      takeOneAndBlock("ADD_PRIVATE_MSG", AuthGenerators.addPrivateMsg),
      takeOneAndBlock("SEND_VISURE_DETAILS", AuthGenerators.sendVisureDetails),
      takeOneAndBlock("UPDATE_DATA_FORM", AuthGenerators.updateDataForm),
      takeOneAndBlock("GET_TICKET_BY_TICKET_ID", AuthGenerators.getTicketByTicketId),
      takeOneAndBlock("GET_DATA_FORM_DETAILS", AuthGenerators.getDataFormDetails),
      takeOneAndBlock("SEND_DATA_FORM", AuthGenerators.sendDataForm),
      takeOneAndBlock("SIGN_IN_BY_EMAIL", AuthGenerators.signInByEmail),
      takeOneAndBlock("SIGN_IN_BY_OTP", AuthGenerators.signInByOtp),
      takeOneAndBlock("GET_ACCOUNT_INFO", AuthGenerators.getAccountInfo),
      takeOneAndBlock("LOG_OUT", AuthGenerators.logOut),
      takeOneAndBlock("GET_BOLLETINI_BIANCHI", AuthGenerators.getBolletiniBianchi),
      takeOneAndBlock(
        "GET_BOLLETINI_PREMERCATI",
        AuthGenerators.getBolletiniPremercati
      ),
      takeOneAndBlock("GET_BARCODE_DATA", AuthGenerators.getBarcodeData),
      takeOneAndBlock("GET_PAYMENTS", AuthGenerators.getPayments),
      takeOneAndBlock("GET_ALL_PAYMENTS", AuthGenerators.getAllPayments),
      takeOneAndBlock("GET_REQUESTED_REFILLS", AuthGenerators.getRequestedRefills),
      takeOneAndBlock("REQUIRE_TRANSFER", AuthGenerators.requireTransfer),
      takeOneAndBlock("REQUIRE_REFILL_STATUS", AuthGenerators.requireRefillStatus),
      takeOneAndBlock("GET_RECHARGE_MOBILE", AuthGenerators.getRechargeMobile),
      takeOneAndBlock(
        "GET_RECHARGE_MOBILE_SIM",
        AuthGenerators.getRechargeMobileSim
      ),
      takeOneAndBlock(
        "CAPTURE_RECHARGE_MOBILE_SIM",
        AuthGenerators.captureRechargeMobileSim
      ),
      takeOneAndBlock("GET_POSTE_PAY", AuthGenerators.getPostePay),
      takeOneAndBlock("deposit", AuthGenerators.userTransferMoney),
      takeOneAndBlock("GET_ADS", AuthGenerators.getAds),
      takeOneAndBlock("CREATE_ADS", AuthGenerators.createAds),
      takeOneAndBlock("READ_ADS", AuthGenerators.readAds),
      takeOneAndBlock("GET_REGISTER", AuthGenerators.getRegister),
      takeOneAndBlock("GET_CHANGED_PASSWORD", AuthGenerators.getChangedPassword),
      takeOneAndBlock("GET_CONFIGURA", AuthGenerators.getConfigura),
      takeOneAndBlock("GET_CODICE_TICKET", AuthGenerators.getCodiceTicket),
      takeOneAndBlock("GET_USER_DETAIL", AuthGenerators.getUserDetail),
      takeOneAndBlock("UPDATE_USER_DETAIL", AuthGenerators.updateUserDetail),
      takeOneAndBlock("CHANGE_AGENT", AuthGenerators.changeAgent),
      takeOneAndBlock("GET_AGENTS", AuthGenerators.getAgents),
      takeOneAndBlock("GET_TRANSFER_DOCUMENT", AuthGenerators.getTransferDocument),
      takeOneAndBlock("GET_SKIN_EXTRAS", AuthGenerators.getSkinExtras),
      takeOneAndBlock("GET_ERRORS", AuthGenerators.getErrors),
      takeOneAndBlock("DELETE_ERROR", AuthGenerators.deleteError),
      takeOneAndBlock("GET_CONTI_ACCOUNT", AuthGenerators.getContiAccount),
      takeOneAndBlock("SET_NEW_CONTI_ACCOUNT", AuthGenerators.setNewContiAccount),
      takeOneAndBlock("GET_COURIERS", AuthGenerators.getCouriers),
      takeOneAndBlock("SET_SHIPPING_INFO", AuthGenerators.setShippingInfo),
      takeOneAndBlock("BUY_SHIPPING_ORDER", AuthGenerators.buyShippingOrder),
      takeOneAndBlock("GET_ANNUALI_STATITICHE", AuthGenerators.getAnnualiStatitiche),
      takeOneAndBlock(
        "GET_LATEST_SERVICE_STATISTIC",
        AuthGenerators.getLatestServiceStatistic
      ),
    ],
    //MAIN Generators
    ...[
      takeOneAndBlock("GET_REGISTER_TOKEN", MainGenerators.getRegisterToken),
      takeOneAndBlock("FORGOT_PASSWORD", MainGenerators.forgotPassword),
      takeOneAndBlock("GET_SEARCHED_USERS", MainGenerators.getSearchedUsers),
      takeOneAndBlock("GET_USER_PHOTOS", MainGenerators.getUserPhotos),
      takeOneAndBlock("GET_SYSTEM_MODES", MainGenerators.getSystemModes),
      takeOneAndBlock("GET_SERVICES", MainGenerators.getServices),
      takeOneAndBlock("GET_USERS", MainGenerators.getUsers),
      takeOneAndBlock("GET_SKIN_USERS", MainGenerators.getSkinUsers),
      takeOneAndBlock("GET_USERS_SIMPLE", MainGenerators.getUsersSimple),

      takeOneAndBlock("GET_USERS_BY_SEARCH", MainGenerators.getUsersBySearch),
      takeOneAndBlock("GET_OVERVIEW_DASHBOARD", MainGenerators.getOverviewDashboard),
      takeOneAndBlock("TOGGLE_FAVORITE", MainGenerators.toggleFavorite),
      takeOneAndBlock("UPDATE_SYSTEM_MODE", MainGenerators.updateSystemMode),
      takeOneAndBlock("GET_FAVORITES", MainGenerators.getFavorites),
      takeOneAndBlock("SEND_PRENOTAZIONE", MainGenerators.sendPrenotazione),
      takeOneAndBlock("GET_SELECTED_COMPANY", MainGenerators.getSelectedCompany),
      takeOneAndBlock("GET_ALL_IMAGES", MainGenerators.getAllImages),
      takeOneAndBlock("UPLOAD_NEW_IMAGE", MainGenerators.uploadNewImage),
      takeOneAndBlock("UPDATE_IMAGE", MainGenerators.updateImage),
      takeOneAndBlock("DELETE_IMAGE", MainGenerators.deleteImage),
      takeOneAndBlock("UPDATE_COMPANY", MainGenerators.updateCompany),
      takeOneAndBlock("GET_SERVICES_DATA", MainGenerators.getServicesData),
      takeOneAndBlock("GET_SERVICES_TREE", MainGenerators.getServicesTree),
      takeOneAndBlock("CHANGE_SERVICE_CATEGORY", MainGenerators.changeServiceCategory),
    ],
    //SHOP
    ...[
      takeOneAndBlock("BUY_CAFFE", ShopGenerators.buyCoffee),
      takeOneAndBlock("GET_COFFEE_SERVICES", ShopGenerators.getCoffeeServices),
      takeOneAndBlock("GET_COFFEE_ADDRESS", ShopGenerators.getCoffeeAddress),
      takeOneAndBlock("GET_PRODUCTS_BY_TAG", ShopGenerators.getProductsByTag),
      takeOneAndBlock("GET_PRODUCTS_LIST", ShopGenerators.getProductsList),
      takeOneAndBlock("GET_PRODUCT_DETAILS", ShopGenerators.getProductDetails),
      takeOneAndBlock("GET_BRANDS", ShopGenerators.getBrands),
      takeOneAndBlock("GET_DEFAULT_PRODUCTS", ShopGenerators.getDefaultProducts),
      takeOneAndBlock("GET_CATEGORIES", ShopGenerators.getCategories),
      takeOneAndBlock("CHECK_OUT", ShopGenerators.checkOut),
      takeOneAndBlock("GET_TO_CART", ShopGenerators.getToCart),
      takeOneAndBlock("GET_ITEMS_CART", ShopGenerators.getItemsCart),
      takeOneAndBlock("GET_ORDER", ShopGenerators.getOrder),
      takeOneAndBlock("GET_REMOVE_TO_CART", ShopGenerators.getRemoveToCart),
      takeOneAndBlock("GET_CARRIES", ShopGenerators.getCarries),
      takeOneAndBlock("GET_PROD_CAT", ShopGenerators.getProdCat),
      takeOneAndBlock("GET_ORDERS", ShopGenerators.getOrders),
      takeOneAndBlock("GET_ORDER_DATA", ShopGenerators.getOrderData),
    ],
  ])
}
