import React from "react"
import { notification } from "antd"
import images from "themes/images"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { useState } from "react"
import ContiGiocoModal from "../../../../shared-components/adminSharedComp/ContiGiocoModal/ContiGiocoModal"
import ServiziModal from "../../../../shared-components/adminSharedComp/ServiziModal/ServiziModal"
import BonusModal from "../../../../shared-components/adminSharedComp/ServiziModal/BonusModal"
import { baseUrlImg } from "../../../../config/api"

const AdminServiziItem = (props) => {
  const {
    category_full_name,
    full_name,
    category,
    UpdateServicesChangeStatus,
    number_id,
    activeSkinId,
    getService,
    name,
    active,
    category_id,
    getContiAccount,
    services,
    setContiAccount,
    setNewContiStatus,
    getSelectedCompany,
    setSelectedCompany,
    logo,
    selectedCompany
  } = props

  const onServiceClick = () =>
    category_id === 33 &&
    (window.location.href = `${window.origin + window.location.hash}/${number_id}`)

  const [openModal, setOpenModal] = useState(false)
  const [openServiceModal, setOpenServiceModal] = useState(false)
  const [openBonusModal, setOpenBonusModal] = useState(false)
  const [selectedId, setSelectedId] = useState()

  return (
    <>
      <ServiziModal
        open={openServiceModal}
        onClose={() => {
          setOpenServiceModal(false)
          setSelectedCompany({})
        }}
        getService={getService}
        openBonusModal={() => setOpenBonusModal(true)}
      />
      <BonusModal
        open={openBonusModal}
        onClose={() => {
          setOpenBonusModal(false)
        }}
        full_name={full_name}
        selectedCompany={selectedCompany}
      />
      <ContiGiocoModal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setContiAccount(null)
          setNewContiStatus(null)
        }}
        name={name}
        service_id={selectedId}
      />
      <div className="AdminServiziItem animated fadeIn" onClick={onServiceClick}>
        <div className="AdminServiziItem--Header">
          <div className="AdminServiziItem--Header--Prenotazione">
            {category_full_name.toUpperCase()}
          </div>
          <div className="AdminServiziItem--Header--Title">{full_name}</div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}

        <img
          src={`${baseUrlImg}/${logo}` || images["placeholder"]}
          className="AdminServiziItem--Image"
          alt={category}
          loading="lazy"
        />
        <div className="AdminServiziItem--ButtonWrapper">
          <div>
            <button
              onClick={() => {
                if (active === 0) {
                  UpdateServicesChangeStatus(
                    name,
                    full_name,
                    number_id,
                    true,
                    activeSkinId,
                    async () => {
                      await notification["success"]({
                        message:
                          "Lo stato viene modificato con successo - L'impostazione del servizio è stata modificata.",
                        description: "Il servizio è stato abilitato con successo.",
                      })
                      await getService()
                    }
                  )
                } else {
                  notification["success"]({
                    message: "Lo stato e gia attivato",
                  })
                }
              }}
              className={`${active === 1 ? "active" : ""}`}
            >
              ACTIVE
            </button>
            <button
              onClick={() => {
                if (active === 1) {
                  UpdateServicesChangeStatus(
                    name,
                    full_name,
                    number_id,
                    false,
                    activeSkinId,
                    async () => {
                      await notification["error"]({
                        message:
                          "Lo stato viene modificato con successo - L'impostazione del servizio è stata modificata. ",
                        description:
                          " Il servizio è stato disabilitato con successo.",
                      })
                      await getService()
                    }
                  )
                } else {
                  notification["error"]({
                    message: "Lo stato e gia disattivato",
                  })
                }
              }}
              className={`${active === 0 ? "active" : ""}`}
            >
              INACTIVE
            </button>
          </div>
          {services.map(
            (service) =>
              service.company_id === number_id &&
              [
                "BGM001",
                "BBT001",
                "BULL001",
                "MBBT001",
                "FLBT001",
                "FLBT002",
                "BETL001",
                "SportBet001"
              ].includes(service.service_id) && (
                <div key={service.number_id}>
                  <i
                    className="fas fa-user"
                    onClick={async () => {
                      const service_id = service.service_id
                      await getContiAccount(service_id)
                      setSelectedId(service.service_id)
                      setOpenModal(true)
                    }}
                  ></i>
                </div>
              )
          )}

          <div>
            <i
              className="fas fa-cog"
              onClick={async () => {
                await getSelectedCompany(number_id)
                setOpenServiceModal(true)
              }}
            ></i>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  adminContiGiocoModal: state.auth.adminContiGiocoModal,
  services: state.auth.allServices.services,
  contiAccount: state.auth.contiAccount,
  selectedCompany: state.main.selectedCompany,

})
export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  AdminServiziItem
)
