import { debounce } from "lodash"
import { disallowedCompanies } from "config/index"
import { skin } from "config/api"
import moment from "moment"
import CryptoJS from "crypto-js"
import { mapForSkinInfo, isLocalHost } from "config"
import groupedServices from "../routes/domains/FormVisure/structure.json"

import { createSelector } from "reselect"
import _ from "lodash"

import $ from "jquery"

function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const dontShowRPCH = (forgotPasswordState) => {
  const localStorageAccSRPCH = localStorage.getItem("acccountSRPCH")
  const decoded = window.atob(localStorageAccSRPCH)
  const stringObject = IsJsonString(decoded) ? decoded : "{}"
  const parsedSRPCH = localStorageAccSRPCH ? JSON.parse(stringObject) : {}
  const { email, name, username } = parsedSRPCH
  return (email && name && username) || forgotPasswordState
}

export function numberWithCommas(text) {
  if (text !== undefined && text !== "0") {
    return text.toLocaleString("it-IT", { minimumFractionDigits: 2 })
  }
  return text
}
export function randomString(number) {
  let string = [...Array(number)]
    .map((i) => (~~(Math.random() * 36)).toString(36))
    .join("")
  return string
}
// export function Encrypt() {
//   // ("000y00000y0y00m000000m0yM0000M000000h00000000d00h0000dss00000");
//   let year = moment().format("YYYY");
//   let month = moment().format("MM");
//   let day = moment().format("dd");
//   let minutes = moment().format("mm");
//   let hour = moment().format("HH");
//   let seconds = moment().format("ss");
//   return btoa(
//     btoa(
//       randomString(3) +
//         year.charAt(0) +
//         randomString(5) +
//         year.charAt(1) +
//         randomString(1) +
//         year.charAt(2) +
//         randomString(2) +
//         minutes.charAt(0) +
//         randomString(6) +
//         minutes.charAt(1) +
//         randomString(1) +
//         year.charAt(3) +
//         month.charAt(0) +
//         randomString(4) +
//         month.charAt(1) +
//         randomString(6) +
//         hour.charAt(0) +
//         randomString(8) +
//         day.charAt(0) +
//         randomString(2) +
//         hour.charAt(1) +
//         randomString(4) +
//         day.charAt(1) +
//         seconds.charAt(0) +
//         seconds.charAt(1) +
//         randomString(5)
//     )
//   );
// }

var pageWidth, pageHeight
var basePage = {
  width: 470,
  height: 490,
  scale: 1,
  scaleX: 1,
  scaleY: 1,
}
export function getScale(page, container) {
  // ".casinoIframe--game"
  var $page = $(`${page}`)

  getPageSize()
  scalePages($page, pageWidth, pageHeight)

  $(window).resize(
    debounce(function () {
      getPageSize()
      scalePages($page, pageWidth, pageHeight)
    }, 150)
  )

  function getPageSize() {
    pageHeight = $(`${container}`).height()
    pageWidth = $(`${container}`).width()
  }

  function scalePages(page, maxWidth, maxHeight) {
    var scaleX = 1,
      scaleY = 1
    scaleX = maxWidth / basePage.width
    scaleY = maxHeight / basePage.height
    basePage.scaleX = scaleX
    basePage.scaleY = scaleY
    basePage.scale = scaleX > scaleY ? scaleY : scaleX

    var newLeftPos = Math.abs(
      Math.floor((basePage.width * basePage.scale - maxWidth) / 2)
    )
    var newTopPos = Math.abs(
      Math.floor((basePage.height * basePage.scale - maxHeight) / 2)
    )

    page.attr(
      "style",
      "-webkit-transform:scale(" +
        (basePage.scale >= 1 ? basePage.scale : basePage.scale) +
        ");left:" +
        newLeftPos +
        "px;top:" +
        newTopPos +
        "px;"
    )
  }
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  var costs = []
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j
      else {
        if (j > 0) {
          var newValue = costs[j - 1]
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue
  }
  return costs[s2.length]
}

export function similarity(s1, s2) {
  var longer = s1
  var shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  var longerLength = longer.length
  if (longerLength == 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

export const iconsFromType = {
  expedia: "fal fa-plane",
  flixbus: "fal fa-bus",
  trenitalia: "fal fa-subway",
  vivaticket: "fal fa-ticket-alt",
  ticketing: "fal fa-ticket-alt",
  stubhub: "fal fa-ticket-alt",
  visure: "fal fa-file-alt",
  "shop-online": "fal fa-shopping-cart",
  bgame: "fal fa-futbol",
  auto: "fal fa-car",
  assicurazioni: "fal fa-file-alt",
  energia: "fal fa-lightbulb-on",
  facileristrutturate: "fal fa-futbol",
}

export const removeUnwantedCompanies = (Companies, getCompanyDetails) => {
  const blackListIdsForEachSkin = [
    "HM",
    "Bitcoin Direct to Wallet",
    "Crypto Direct to Wallet",
  ]
  return Companies.filter((comp) => {
    const { companyKeyID, companyName } = getCompanyDetails(comp)
    if (
      blackListIdsForEachSkin.includes(companyKeyID) ||
      blackListIdsForEachSkin.includes(companyName)
    ) {
      return false
    }
    const { skin_id } = skin
    const blackList = disallowedCompanies[skin_id]
    return disallowedCompanies.hasOwnProperty(skin_id)
      ? !(blackList.includes(companyKeyID) || blackList.includes(companyName))
      : true
  })
}

export function callBackOnCurrentSkin(callBackReturn, callBackVoid) {
  const localHostSkinId = 2
  for (let i = 0; i < mapForSkinInfo.length; i++) {
    const currentSkin = mapForSkinInfo[i]
    if (
      window.location.href[currentSkin.compareFunc](currentSkin.urlToMatch) ||
      (isLocalHost && currentSkin.skin_id === localHostSkinId)
    ) {
      callBackVoid && callBackVoid(currentSkin)
      return callBackReturn && callBackReturn(currentSkin)
    }
  }
}
export function usePrevious(value, useRef, useEffect) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const SEPAFIN_SECRET_KEY_ENC = "asd*&^%_a./_"

export const setAes = (string) => {
  return CryptoJS.AES.encrypt(string, SEPAFIN_SECRET_KEY_ENC).toString()
}

export const getAes = (string) => {
  var decrypted = CryptoJS.AES.decrypt(string, SEPAFIN_SECRET_KEY_ENC)
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const sepaObject = (username = "") => {
  const t = getAes(
    localStorage.getItem(
      username
        ? username + "_U2FsdGVkX18YoYWEWNSlyfOyr3KYBr3rqs+cvROFiiM="
        : "U2FsdGVkX18YoYWEWNSlyfOyr3KYBr3rqs+cvROFiiM="
    ) || ""
  )
  if (username) {
    return { sepafin_access_token: t.replace(username + "_|_", "") }
  }
  return { sepafin_access_token: "" }
}

export const sepafinReLoginRequire = (username) => {
  localStorage.removeItem(username + "_U2FsdGVkX18YoYWEWNSlyfOyr3KYBr3rqs+cvROFiiM=")
  localStorage.removeItem(
    username + "_U2FsdGVkX19iXK9gTcY1Vwf5Y387nqwvEhttrIxxNevvAmtcZKjnBArHVxBDysfC"
  )
}

export const sepafinRegisterLogin = (sepafin_access_token, expires, username) => {
  localStorage.setItem(
    username + "_U2FsdGVkX18YoYWEWNSlyfOyr3KYBr3rqs+cvROFiiM=",
    setAes(username + "_|_" + sepafin_access_token)
  )
  localStorage.setItem(
    username + "_U2FsdGVkX19iXK9gTcY1Vwf5Y387nqwvEhttrIxxNevvAmtcZKjnBArHVxBDysfC",
    setAes(moment(expires).toString())
  )
}

export const checkSepafinToken = (sepafin_access_token, username) => {
  if (!sepafin_access_token.includes(username)) {
    sepafinReLoginRequire(username)
    return true
  }
  return false
}

export const shouldShowSepafinLogin = (username) => {
  const token = localStorage.getItem(
    username + "_U2FsdGVkX18YoYWEWNSlyfOyr3KYBr3rqs+cvROFiiM="
  )
  const expireTimeAes = localStorage.getItem(
    username + "_U2FsdGVkX19iXK9gTcY1Vwf5Y387nqwvEhttrIxxNevvAmtcZKjnBArHVxBDysfC"
  )
  if (!expireTimeAes) return true
  if (checkSepafinToken(getAes(token), username)) return true
  const expireTime = getAes(expireTimeAes)
  const dif = moment(expireTime).diff(moment(), "hours")
  return !(token && dif >= 0 && dif <= 24)
}

export function getBase64(file, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(file)
}

export const getAuth = (state) => state.auth
export const getMain = (state) => state.main

export const isSepafinSelector = createSelector([getAuth], (auth) => {
  return !!auth?.accountInfo?.profile?.hasSepafinCredentials
})

export const servicesSelector = createSelector([getAuth, getMain], (auth, main) => {
  const isSepafin = !!auth?.accountInfo?.profile?.hasSepafinCredentials
  let services = main?.services
  if (isSepafin && "PRDPST" in services) {
    delete services["PRDPST"]["BOLL"]
  } else if ("PRDPST" in services) {
    ;["SBOLL", "SBOLLA", "SBOLLF", "SMAVRAV", "SPAGF24CO", "SPAGPA"].forEach(
      (prop) => {
        delete services["PRDPST"][prop]
      }
    )
  }
  return services
})

export const visureSlector = createSelector([getMain], (main) => {
  let servicesVisure = _.cloneDeep(main?.services?.VISURE)
  if (servicesVisure) {
    delete servicesVisure["name"]
    delete servicesVisure["group"]
  }
  if (servicesVisure) {
    Object.keys(groupedServices).forEach((key) => {
      groupedServices[key].forEach(({ groupName, categories }) => {
        let services = servicesVisure[key].services
        let serviceNames = services.map(({ name }) => name)
        const defaultCategories = () =>
          services.filter(({ name }) => name.includes(groupName))
        let nearIndex = serviceNames.findIndex((name) => name.includes(groupName))
        if (nearIndex !== -1) {
          let newServiceCategories = _.cloneDeep(categories || defaultCategories())
          const cateogrieFullNames = newServiceCategories.map(
            (cat) => groupName.toLowerCase() + " " + cat.toLowerCase()
          )
          const cateogrieNames = newServiceCategories.map((cat) => cat)
          servicesVisure[key].services = services.filter((service) => {
            const cleanName = service.name
              .toLowerCase()
              .replaceAll("à", "a")
              .replaceAll("-", "")
              .replaceAll("  ", " ")
              .trim()
            const shouldRemove = cateogrieFullNames.includes(cleanName)
            if (shouldRemove) {
              cateogrieNames.forEach((categoryName, index) => {
                if (cleanName.includes(categoryName.toLowerCase())) {
                  newServiceCategories[index] = {
                    name: categoryName,
                    details: service,
                  }
                }
              })
            }
            return !shouldRemove
          })
          servicesVisure[key].services.splice(nearIndex, 0, {
            name: groupName,
            type: "group",
            cateogries: newServiceCategories,
          })
        }
      })
    })
  }
  return servicesVisure
})

export const toCurrency = (prezzo) =>
  new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR" }).format(
    parseFloat((prezzo || "").toString())
  )

export const toCurrencyPagoPa = (prezzo) =>
  new Intl.NumberFormat("it-IT").format(parseFloat((prezzo || "").toString()))

export const onCodeChange = (code, setFieldsValue) => {
  if (code) {
    const codeParts = code.split(/[|§]/).slice(1).slice(-3);
    if (codeParts.length === 3) {
      const price = parseInt(codeParts[2] / 100);
      const importo_cent = codeParts[2] % 100;
      setFieldsValue({
        importo: price && toCurrencyPagoPa(price),
        importo_cent: importo_cent !== undefined ? String(importo_cent).padStart(2, "0") : "00",
        ente_creditore: codeParts[1],
        codice_aviso: codeParts[0],
      });
    } else {
      setFieldsValue({
        importo: null,
        importo_cent: null,
        ente_creditore: null,
        codice_aviso: null,
      });
    }
  }
};



export  const mrPayScanBarcode = (barcode = "", setFieldsValue) => {
  if (barcode) {
    // console.log(barcode)
    const counter1 = barcode.substring(0, 2) //2shifror
    const codiceIdf = barcode.substring(2, 2 + parseInt(counter1))
    const counter2 = barcode.substring(20, 22) //2shifror
    const sulCC = barcode.substring(22, 22 + parseInt(counter2))

    const counter3 = barcode.substring(34, 36) //2shifror
    const shuma = parseFloat(barcode.substring(36, 36 + parseInt(counter3))) || 0
    const counter4 = barcode.substring(46, 47) //1shifror
    const tipologia = barcode.substring(47, 47 + parseInt(counter4))


  const shumaString = shuma.toString();
  const importo = shumaString.slice(0, -2) || '0'; 
  const importo_cent = shumaString.slice(-2); 

    setFieldsValue({
      codice_identificativo: codiceIdf,
     importo: new Intl.NumberFormat("it-IT").format(parseFloat(importo)),
    importo_cent: importo_cent,
      numero_conto_corrente: sulCC,
      tipologia: tipologia,
    })
  }
}

export const getServiceIdIdentifier = (
  object = {},
  id = "",
  extra = "",
  defaultVal = "default"
) => {
  const identifier = extra + (id.includes("BETL") ? "BTL" : id.substring(0, 3))
  return object?.[identifier] || object?.[defaultVal]
}
