import moment from "moment"
const controlErrors = (
  err,
  service_id,
  setShowSepafinLogin,
  form,
  pdfUnread,
  isSepafin,
  callback
) => {
  callback()
}

export const bolletinoSubmit = (
  e,
  service_id,
  form,
  submitFunction,
  clearFields,
  codiceFiscaleOrPartitaIvaIsRequired,
  setShowSepafinLogin,
  isPos,
  pdfUnread,
  callback,
  newFields,
  isMrPay,
  setBolletiniLoading,
  username,
  user_id
) => {
  e.preventDefault()

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const pagamentiServices = accountData?.dashboard?.pagamenti_module.services

  form.validateFieldsAndScroll((err, values) => {
    controlErrors(
      err,
      service_id,
      setShowSepafinLogin,
      form,
      !!pdfUnread,
      !isMrPay,
      callback ||
        (() => {
          if (!err) {
            let dynamicFields = {}
            ;(newFields || []).forEach(({ TagLabel, TagName }) => {
              dynamicFields[`${TagLabel}`] = values[`${TagName}`]
            })

            switch (service_id) {
              case "SBOL003":
              case "SBOL004":
                submitFunction(
                  service_id,
                  values.person_type,
                  values.via_piazza,
                  values.citta,
                  values.phone_number,
                  values.importo,
                  values.codice,
                  values.nome,
                  values.cognome,
                  values.denominazione,
                  values.partita_iva,
                  values.causale,
                  clearFields,
                  isPos,
                  username
                )
                break
              case "BOL007":
              case pagamentiServices["Paytipper PagoPA"]:
                if (setBolletiniLoading) setBolletiniLoading(true)
                submitFunction(
                  service_id,
                  "pa",
                  values.codice_fiscale_bol,
                  values.codice_aviso,
                  clearFields,
                  values.importo,
                  (values.importo_cent || "00").toString(),
                  values.ente_creditore,
                  values.eseguito_da,
                  user_id,
                  setBolletiniLoading
                )
                break
              case "SPPA001":
                submitFunction(
                  service_id,
                  "pa",
                  values.codice_fiscale_bol,
                  values.codice_aviso,
                  clearFields,
                  null,
                  null,
                  username
                )
                break
              case "SBOL006":
                submitFunction(
                  service_id,
                  "auto",
                  null,
                  null,
                  clearFields,
                  values.tipo_veicolo,
                  values.targa,
                  username
                )
                break
              case "SBOL007":
                submitFunction(
                  service_id,
                  values.importo,
                  values.causale,
                  values.person_type,
                  values.via_piazza,
                  values.citta,
                  values.identificativo_pagamento,
                  values.iban,
                  values.cin_importo,
                  values.cin_intermedio,
                  values.cin_complessivo,
                  values.codice_esenzione,
                  values.nome,
                  values.cognome,
                  values.denominazione,
                  clearFields,
                  isPos,
                  username
                )
                break

              default:
                if (
                  [
                    "BOL001",
                    "SBOL001",
                    pagamentiServices["Paytipper Bill payments no edit Miniprogram"],
                  ].includes(service_id)
                ) {
                  if (isMrPay) {
                    if (setBolletiniLoading) setBolletiniLoading(true)
                    submitFunction(
                      service_id,
                      values.importo,
                      values.id_prenotazione,
                      values.nome,
                      values.cognome,
                      values.conto_corrente,
                      values.causale,
                      clearFields
                    )
                  } else {
                    submitFunction(
                      !!codiceFiscaleOrPartitaIvaIsRequired,
                      ["896", "674"].includes(values.tipologia)
                        ? "SBOL002"
                        : "SBOL001",
                      (values.person_type || "").toString(),
                      values.via_piazza,
                      values.citta,
                      (values.importo || "").toString(),
                      values.tipologia,
                      values.numero_conto_corrente,
                      values.causale,
                      values.nome,
                      values.cognome,
                      values.codice_fiscale,
                      values.denominazione,
                      values.partita_iva,
                      values.codice_identificativo,
                      dynamicFields,
                      clearFields,
                      (values.importo_cent || "00").toString(),
                      username
                    )
                  }
                }
                if (
                  [
                    "BOL004",
                    "BOL003",
                    pagamentiServices["Paytipper RAV"],
                    pagamentiServices["Paytipper MAV"],
                  ].includes(service_id)
                ) {
                  setBolletiniLoading(true)
                  submitFunction(
                    service_id,
                    values.numero_conto_corrente,
                    values.importo,
                    (values.importo_cent || "00").toString(),
                    (values.rav || "").toString(),
                    values.numero_documento,
                    values.eseguito_da?.replace(/\//g, "-"),
                    (values.scadenza || new moment()).format("DD/MM/YYYY"),
                    values.codice_fiscale,
                    values.citta,
                    values.provincia,
                    values.indirizzo,
                    user_id,
                    clearFields,
                    setBolletiniLoading
                  )
                }
                if (
                  [
                    "BOL002",
                    "SBOL002",
                    pagamentiServices["Paytipper Bill payments"],
                  ].includes(service_id)
                ) {
                  if (isMrPay) {
                    if (setBolletiniLoading) setBolletiniLoading(true)
                    submitFunction(
                      service_id,
                      (values.numero_conto_corrente || "").toString(),
                      values.importo,
                      (values.importo_cent || "00").toString(),
                      (values.codice_identificativo || "").toString(),
                      parseInt(values.tipologia),
                      values.eseguito_da?.replace(/\//g, "-"),
                      values.via_piazza,
                      values.cap,
                      values.citta,
                      values.provincia,
                      values.indirizzo,
                      user_id,
                      clearFields,
                      setBolletiniLoading
                    )
                  } else {
                    submitFunction(
                      !!codiceFiscaleOrPartitaIvaIsRequired,
                      ["896", "674"].includes(values.tipologia)
                        ? "SBOL002"
                        : "SBOL001",
                      (values.person_type || "").toString(),
                      values.via_piazza,
                      values.citta,
                      (values.importo || "").toString(),
                      values.tipologia,
                      values.numero_conto_corrente,
                      values.causale,
                      values.nome,
                      values.cognome,
                      values.codice_fiscale,
                      values.denominazione,
                      values.partita_iva,
                      values.codice_identificativo,
                      user_id,
                      clearFields,
                      setBolletiniLoading
                    )
                  }
                }
                if (
                  [pagamentiServices["Paytipper Bollo auto"]].includes(service_id)
                ) {
                  if (setBolletiniLoading) setBolletiniLoading(true)
                  submitFunction(
                    service_id,
                    values.tipo_veicolo,
                    values.targa,
                    user_id,
                    clearFields,
                    setBolletiniLoading
                  )
                }
            }
          }
        })
    )
  })
}
