import React from "react"
import { baseUrlImg } from "../../config/api"

const PrintTicketGiftCard = ({ arr, barcode }) => {

  const data = JSON.parse(arr.receipt);
  const array = Object.values(data).map((value) => (value === "" ? "\n" : value));

  const uniqueArray = array
    ? array.filter((value, index, array) => {
        return value === "\n" || array.indexOf(value) === index
      })
    : []

  return (
    <div className="pin-receipt">
      <span className="service-name">{JSON.parse(arr.receipt)["serviceName"]}</span>
      <img
        src={`${baseUrlImg}/${arr.logo}`}
        alt="service logo"
        className="service-logo"
      />
      <div
        className="receipt-info"
        style={{ alignItems: "flex-start", width: "100%" }}
      >
        {uniqueArray?.map((value, index) =>
          value === "\n" ? <br /> : <div key={index}>{value}</div>
        )}
      </div>
      {barcode !== 1 && (
        <div className="receipt-barcode">
          <img
            className="barcodeModal"
            src={`https://barcode.tec-it.com/barcode.ashx?data=${
              barcode || 0
            }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
            alt=""
          />
        </div>
      )}

      {/* <div className="receipt-description">
        {JSON.parse(arr.receipt)["Description"]}
      </div> */}
    </div>
  )
}

export default PrintTicketGiftCard
