import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import moment from "moment"
import { Icon, Table, Select, Pagination, Input, Button, notification } from "antd"
import "antd/dist/antd.css"
import ModalResponsiveForTables from "shared-components/ModalResponsiveForTables/ModalResponsiveForTables"
import SpanFormater from "shared-components/SpanFormater/SpanFormater"
import ModalRow from "shared-components/ModalResponsiveForTables/ModalRow"
import Excel from "./Excel"
import Pdf from "./Pdf"
import { Loader } from "shared-components"
import { numberWithCommas } from "utils/HelperFunc"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import { isArray } from "lodash"
import AdminNewStatistiche from "../../shared-components/adminSharedComp/adminNewStatistiche/adminNewStatistiche"
import images from "themes/images"
import { withRouter } from "react-router"
import { baseUrlImg } from "../../config/api"

const { Option } = Select

export const UtenteCell = ({ icon, Word, title, color }) => {
  return (
    <div className="utente-data" title={title}>
      <i style={{ WebkitTextStroke: `2px ${color}` }} className={icon} />
      <div className="utente-word">{Word}</div>
    </div>
  )
}
class TransazioniAdmin extends React.Component {
  constructor() {
    super()
    this.state = {
      tabFilter: "",
      modal: false,
      searchText: "",
      searchedColumn: "",
      visible: false,
      username: "",
      usernames: null,
      service: "",
      barcode: "",
      name: "",
      address: "",
      phone: "",
      from: "",
      to: "",
      modalDetails: "",
      fromLabel: "",
      toLabel: "",
      perPage: 50,
      showModalResponsive: false,
      picker: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
          color: "var(--accent-bg)",
        },
      ],
      //1 for movimenti , 2 for refills
      tableType: 1,
    }
  }

  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }

  hideModal = () => {
    document.body.style.overflow = ""
    this.setState({ modal: false })
  }
  showModal = () => {
    document.body.style.overflow = "hidden"
    this.setState({ modal: true })
  }

  selectTab = (payload) => {
    this.setState({ tabFilter: payload })
  }

  componentDidMount() {
    const { username } = this.props.match.params;
    const { skinList, accountInfo, activeSkinId, forAdmin } = this.props;
    const role = accountInfo?.profile?.role?.name;
    const activityId = accountInfo?.profile?.activity_id;
  
    this.setState({ username });
  
    const getSkinId = () => {
      if (role === "super_admin" || role === "agent") {
        return activityId;
      }
      return activeSkinId !== -1 ? activeSkinId : (skinList.length > 0 ? skinList[0]?.id : undefined);
    };
  
    if (skinList.length > 0) {
      const skinId = getSkinId();
      console.log(skinId);
  
      forAdmin
        ? this.props.getPayments(
            username !== "" ? username : "",
            "",
            "",
            1,
            50,
            skinId
          )
        : this.props.getPayments(username !== "" ? username : "", "", "", 1, 50);
  
      role === "super_admin"
        ? this.props.getAllServices(activityId)
        : this.props.getAllServices(skinId);
    }
  }
  
  componentDidUpdate(prevProps) {
    const { username, from, to, perPage, service } = this.state;
    const { activeSkinId, skinList, forAdmin, fromDate } = this.props;
  
    if (skinList.length > 0 && skinList !== prevProps.skinList && activeSkinId === -1) {
      this.props.setActiveSkinId(skinList[0].id);
    }
  
    if (activeSkinId !== prevProps.activeSkinId && activeSkinId !== -1 && forAdmin) {
      this.props.getPayments(
        username !== "" ? username : "",
        from || "",
        to || "",
        1,
        perPage ? perPage : 10,
        activeSkinId,
        service || ""
      );
      this.props.getAllServices(activeSkinId);
    }
  
    if (fromDate && fromDate !== prevProps.fromDate) {
      const label = moment(fromDate, "DD/MM/YYYY");
      this.setState({
        from: fromDate,
        to: fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 50,
      });
    }
  }
  
  

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (dataIndex === "service") {
        return (
          <div
            style={{ padding: 8, width: "180px" }}
            className="table-filter-dropdown"
          >
            <Select
              mode="single"
              allowClear
              showSearch={true}
              style={{ width: "100%" }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value)
                this.setState({ service: value ? value : "" })
              }}
            >
              {this.props.listedServices.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ marginTop: 8, width: "100%" }}
            >
              Search
            </Button>
          </div>
        )
      }
      if (dataIndex === "utente") {
        return (
          <div
            style={{ padding: 8, width: "180px" }}
            className="table-filter-dropdown"
          >
            <Select
              mode="single"
              allowClear
              showSearch={true}
              style={{ width: "100%" }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value)
                this.setState({ username: value ? value : "" })
              }}
            >
              {Array.isArray(this.props.usernames)
                ? this.props.usernames.map((username, index) => (
                    <Option key={index} value={username}>
                      {username}
                    </Option>
                  ))
                : Object.entries(this.props.usernames).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
            </Select>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ marginTop: 8, width: "100%" }}
            >
              Search
            </Button>
          </div>
        )
      } else {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }}
              onPressEnter={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        )
      }
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].props?.Word?.props
        ? record[dataIndex].props.Word.props.Word.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex].props
        ? record[dataIndex].props.Word.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    const { to, from, perPage, service, username } = this.state
    confirm()
    if (dataIndex == "utente") {
      this.props.getPayments(
        username,
        from || "",
        to || "",
        1,
        perPage,
        this.props.activeSkinId,
        "",
        service == "TUTTI" ? "" : service
      )
      this.props.getStatistiche(
        this.props.activeSkinId,
        from || "",
        to || "",
        username == "TUTTI" ? "" : username,
        service == "TUTTI" ? "" : service
      )
    } else if (dataIndex == "service") {
      this.props.getPayments(
        username ? username : "",
        from || "",
        to || "",
        1,
        perPage,
        this.props.activeSkinId,
        "",
        service == "TUTTI" ? "" : service
      )

      this.props.getStatistiche(
        this.props.activeSkinId,
        from || "",
        to || "",
        username == "TUTTI" ? "" : username,
        service == "TUTTI" ? "" : service
      )
    } else if (dataIndex == "barcode") {
      this.props.getCodiceTicket(selectedKeys[0], "omeLale")
    } else {
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      })
    }
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: "", username: "", service: "" })
  }

  handleSubmit = async (e, refills) => {
    const { username, to, from, perPage, service } = this.state
    e.preventDefault()
    if (refills) {
      await this.setState({ tableType: 2 })
      this.props.getRefills(1, perPage)
    } else {
      await this.setState({ tableType: 1 })

      if (this.props.forAdmin) {
        this.props.getPayments(
          username,
          from || "",
          to || "",
          1,
          perPage,
          this.props.activeSkinId,
          "",
          service == "TUTTI" ? "" : service
        )
        this.props.getStatistiche(
          this.props.activeSkinId,
          from || "",
          to || "",
          username == "TUTTI" ? "" : service,
          service == "TUTTI" ? "" : service
        )
      } else {
        this.props.getPayments(
          username,
          from || "",
          to || "",
          1,
          perPage,
          "",
          "",
          service == "TUTTI" ? "" : service
        )
      }
    }
  }

  componentWillUnmount() {
    if (this.props.forAdmin === true) {
      this.props.openModalForAdmin(false)
      this.props.editModalDetails({})
    }
    this.props.setFromDateToDate(null)
  }
  activateModalForAdmin = (item, index) => {
    this.props.openModalForAdmin(true)
    this.props.editModalDetails({
      index,
      barcode: item.barcode,
      agency_name: item.agency_name,
      address: item.agency_address,
    })
  }

  handleDateChange = (date) => {
    this.setState({ from: date, to: date })
  }

  render() {
    const { forAdmin, paymentsForExcel, screenWidth, stat } = this.props
    const {
      picker,
      isCalendarOpen,
      fromLabel,
      toLabel,
      to,
      from,
      username,
      service,
      perPage,
      modalDetails,
      tableType,
    } = this.state

    const {
      payments,
      accountInfo,
      loadingPayments,
      paymentsPages,
      getCodiceTicket,
      getPayments,
      activeSkinId,
    } = this.props

    const paymentsO =
      payments &&
      isArray(payments) &&
      payments.sort(function (a, b) {
        return new Date(b.executed_date) - new Date(a.executed_date)
      })

    var columns =
      activeSkinId === -1
        ? []
        : tableType === 2 && screenWidth > 815
        ? [
            {
              title: "Date / Ora",
              dataIndex: "date",
              key: "date",
              width: "15%",
              ellipsis: true,
              // sorter: (a, b) =>
              //   moment(a.date, "DD/MM/YYYY HH:mm").unix() -
              //   moment(b.date, "DD/MM/YYYY HH:mm").unix(),
            },
            {
              title: "Barcode",
              dataIndex: "barcode",
              key: "barcode",
              ...this.getColumnSearchProps("barcode"),
              width: "12%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Utente",
              dataIndex: "ragione",
              key: "ragione",
              ...this.getColumnSearchProps("ragione"),
              width: "15%",
              ellipsis: true,
            },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "15%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Importo",
              dataIndex: "importo",
              key: "importo",
              // sorter: (a, b) =>
              //   parseFloat(a.importo.replace(".", "")) -
              //   parseFloat(b.importo.replace(".", "")),
              width: "10%",
            },
            {
              title: "Saldo",
              dataIndex: "saldo",
              key: "saldo",
              // sorter: (a, b) =>
              //   parseFloat(a.saldo.replace(".", "")) -
              //   parseFloat(b.saldo.replace(".", "")),
              width: "10%",
            },
          ]
        : screenWidth > 815 && accountInfo.profile.role.name === "main_admin"
        ? [
            {
              title: "Date / Ora",
              dataIndex: "date",
              key: "date",
              width: "15%",
              ellipsis: true,
              sorter: (a, b) =>
                moment(a.date, "DD/MM/YYYY HH:mm").unix() -
                moment(b.date, "DD/MM/YYYY HH:mm").unix(),
            },
            {
              title: "Barcode",
              dataIndex: "barcode",
              key: "barcode",
              ...this.getColumnSearchProps("barcode"),
              width: "14%",
              ellipsis: true,
              onCell: (record, rowIndex) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Utente",
              dataIndex: "utente",
              ...this.getColumnSearchProps("utente"),
              ellipsis: true,
              width: "15%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({ username: record.username })
                    this.props.getPayments(
                      record.username,
                      from || "",
                      to || "",
                      1,
                      perPage,
                      this.props.activeSkinId,
                      "",
                      service == "TUTTI" ? "" : service
                    )
                    record.utente?.props?.Word?.props &&
                      navigator.clipboard.writeText(record.username)

                    notification["success"]({
                      message: "Il utente è stato copiato con successo",
                      placement: "topRight",
                    })
                  },
                }
              },
            },
            // {
            //   title: "Ragione Sociale",
            //   dataIndex: "ragione",
            //   key: "ragione",
            //   ...this.getColumnSearchProps("ragione"),
            //   width: "18%",
            //   ellipsis: true,
            // },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "15%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Importo",
              dataIndex: "importo",
              key: "importo",
              // sorter: (a, b) => parseFloat(a.importo) - parseFloat(b.importo),
              width: "9%",
              ellipsis: true,
            },
            {
              title: <span title="Commissione">Commissione</span>,
              dataIndex: "commissione",
              key: "commissione",
              // sorter: (a, b) =>
              //   parseFloat(a.commissione.replace(".", "")) -
              //   parseFloat(b.commissione.replace(".", "")),
              width: "9%",
              ellipsis: true,
            },
            {
              title: <span title="Provvigione">Provvigione</span>,
              dataIndex: "proviggione",
              key: "proviggione",
              // sorter: (a, b) =>
              //   parseFloat(a.proviggione.replace(",", ".")) -
              //   parseFloat(b.proviggione.replace(",", ".")),
              width: "9%",
              ellipsis: true,
            },
            {
              title: "Aggio Skin",
              dataIndex: "aggio",
              key: "aggio",
              // sorter: (a, b) =>
              //   parseFloat(a.aggio.replace(",", ".")) -
              //   parseFloat(b.aggio.replace(",", ".")),
              width: "9%",
              ellipsis: true,
            },
            // {
            //   title: "Aggio Provider",
            //   dataIndex: "aggio",
            //   key: "aggio_provider",
            //   // sorter: (a, b) =>
            //   //   parseFloat(a.aggio.replace(",", ".")) -
            //   //   parseFloat(b.aggio.replace(",", ".")),
            //   width: "9%",
            //   ellipsis: true,
            // },
            {
              title: "Saldo",
              dataIndex: "saldo",
              key: "saldo",
              // sorter: (a, b) =>
              //   parseFloat(a.saldo.replace(".", "")) -
              //   parseFloat(b.saldo.replace(".", "")),
              width: "10%",
              ellipsis: true,
            },
          ]
        : accountInfo.profile.role.name !== "main_admin" && screenWidth > 815
        ? [
            {
              title: "Date / Ora",
              dataIndex: "date",
              key: "date",
              width: "12%",
              ellipsis: true,
              sorter: (a, b) =>
                moment(a.date, "DD/MM/YYYY HH:mm").unix() -
                moment(b.date, "DD/MM/YYYY HH:mm").unix(),
            },
            {
              title: "Barcode",
              dataIndex: "barcode",
              key: "barcode",
              ...this.getColumnSearchProps("barcode"),
              width: "12%",
              ellipsis: true,
              onCell: (record, rowIndex) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Utente",
              dataIndex: "utente",
              ...this.getColumnSearchProps("utente"),
              ellipsis: true,
              width: "15%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({ username: record.username })
                    this.props.getPayments(
                      record.username,
                      from || "",
                      to || "",
                      1,
                      perPage,
                      this.props.activeSkinId,
                      "",
                      service == "TUTTI" ? "" : service
                    )
                    navigator.clipboard.writeText(record.username)
                    notification["success"]({
                      message: "Il utente è stato copiato con successo",
                      placement: "topRight",
                    })
                  },
                }
              },
            },
            // {
            //   title: "Ragione Sociale",
            //   dataIndex: "ragione",
            //   key: "ragione",
            //   ...this.getColumnSearchProps("ragione"),
            //   width: "16%",
            //   ellipsis: true,
            // },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "12%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Importo",
              dataIndex: "importo",
              key: "importo",
              // sorter: (a, b) => parseFloat(a.importo) - parseFloat(b.importo),
              width: "9%",
              ellipsis: true,
            },
            {
              title: <span title="Commissione">Commissione</span>,
              dataIndex: "commissione",
              key: "commissione",
              // sorter: (a, b) =>
              //   parseFloat(a.commissione.replace(".", "")) -
              //   parseFloat(b.commissione.replace(".", "")),
              width: "9%",
              ellipsis: true,
            },
            {
              title: <span title="Provvigione">Provvigione</span>,
              dataIndex: "proviggione",
              key: "proviggione",
              // sorter: (a, b) =>
              //   parseFloat(a.proviggione.replace(",", ".")) -
              //   parseFloat(b.proviggione.replace(",", ".")),
              width: "9%",
              ellipsis: true,
            },
            {
              title: "Aggio Skin",
              dataIndex: "aggio",
              key: "aggio_skin",
              // sorter: (a, b) =>
              //   parseFloat(a.aggio.replace(",", ".")) -
              //   parseFloat(b.aggio.replace(",", ".")),
              width: "9%",
              ellipsis: true,
            },
            {
              title: "Saldo",
              dataIndex: "saldo",
              key: "saldo",
              // sorter: (a, b) =>
              //   parseFloat(a.saldo.replace(".", "")) -
              //   parseFloat(b.saldo.replace(".", "")),
              width: "10%",
              ellipsis: true,
            },
          ]
        : screenWidth > 300 && screenWidth < 815 && tableType !== 2
        ? [
            {
              title: "Date / Barcode",
              dataIndex: "date",
              ellipsis: true,
              render: (text, record) => (
                <div className="data-barcode-row">
                  <span>{text}</span>
                  <span>{record.barcode}</span>
                </div>
              ),
              width: "28%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Utente",
              dataIndex: "utente",
              key: "utente",
              ...this.getColumnSearchProps("utente"),
              ellipsis: true,
              width: "25%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({ username: record.username })
                    this.props.getPayments(
                      record.username,
                      from || "",
                      to || "",
                      1,
                      perPage,
                      this.props.activeSkinId,
                      "",
                      service == "TUTTI" ? "" : service
                    )
                    navigator.clipboard.writeText(record.username)
                    notification["success"]({
                      message: "Il utente è stato copiato con successo",
                      placement: "topRight",
                    })
                  },
                }
              },
            },
            // {
            //   title: "Ragione Sociale",
            //   dataIndex: "ragione",
            //   key: "ragione",
            //   ...this.getColumnSearchProps("ragione"),
            //   width: "25%",
            //   ellipsis: true,
            // },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "18%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            // {
            //   title: "Importo",
            //   dataIndex: "importo",
            //   key: "importo",
            //   // sorter: (a, b) => parseFloat(a.importo) - parseFloat(b.importo),
            //   width: "13%",
            //   ellipsis: true,
            // },
            {
              title: "Dett.",
              dataIndex: "details",
              key: "details",
              width: "11%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({
                      showModalResponsive: true,
                      modalDetails: record,
                    })
                  },
                }
              },
            },
          ]
        : screenWidth < 815 && tableType === 2
        ? [
            {
              title: "Ragione Sociale",
              dataIndex: "ragione",
              ...this.getColumnSearchProps("ragione"),
              ellipsis: true,
              width: "35%",
            },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "35%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Importo",
              dataIndex: "importo",
              key: "importo",
              // sorter: (a, b) => parseFloat(a.importo) - parseFloat(b.importo),
              width: "25%",
              ellipsis: true,
            },
            {
              title: "Dett.",
              dataIndex: "details",
              key: "details",
              width: "15%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({
                      showModalResponsive: true,
                      modalDetails: record,
                    })
                  },
                }
              },
            },
          ]
        : [
            {
              title: "Date / Barcode",
              dataIndex: "date",
              ellipsis: true,
              render: (text, record) => (
                <div className="data-barcode-row">
                  <span>{text}</span>
                  <span>{record.barcode}</span>
                </div>
              ),
              width: "35%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
            },
            {
              title: "Utente",
              dataIndex: "utente",
              ...this.getColumnSearchProps("utente"),
              ellipsis: true,
              width: "30%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({ username: record.utente.props.Word.props.Word })
                    this.props.getPayments(
                      record.utente.props.Word.props.Word,
                      from || "",
                      to || "",
                      1,
                      perPage,
                      this.props.activeSkinId,
                      "",
                      service == "TUTTI" ? "" : service
                    )
                  },
                }
              },
            },
            {
              title: "Service",
              dataIndex: "service",
              key: "service",
              ...this.getColumnSearchProps("service"),
              width: "25%",
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    getCodiceTicket(record.barcode, record.service)
                    this.showModal(
                      this.state.index,
                      modalDetails.barcode,
                      modalDetails.agency_name,
                      modalDetails.agency_address,
                      modalDetails.agency_phone
                    )
                  },
                }
              },
              ellipsis: true,
            },
            // {
            //   title: "Importo",
            //   dataIndex: "importo",
            //   key: "importo",
            //   // sorter: (a, b) => parseFloat(a.importo) - parseFloat(b.importo),
            //   width: "20%",
            //   ellipsis: true,
            // },
            {
              title: "Dett.",
              dataIndex: "details",
              key: "details",
              width: "15%",
              ellipsis: true,
              onCell: (record) => {
                return {
                  onClick: (ev) => {
                    this.setState({
                      showModalResponsive: true,
                      modalDetails: record,
                    })
                  },
                }
              },
            },
          ]

    // if (accountInfo.profile.role === "agent") {
    //   columns = columns.filter(function (value, index, arr) {
    //     if (value.dataIndex !== "utente") {
    //       return value
    //     }
    //   })
    // }

    if (
      accountInfo.profile.role.name === "agent" ||
      accountInfo.profile.role.name === "super_admin"
    ) {
      const index = columns.findIndex((column) => column.key === "aggio_skin")
      if (index !== -1) {
        columns.splice(index, 1)
      }
    }

    var data =
      paymentsO &&
      isArray(paymentsO) &&
      paymentsO?.map((p) => ({
        date: moment(p.executed_date).format("DD/MM/YYYY HH:mm:ss"),
        barcode: p.barcode,
        utente: (
          <UtenteCell
            icon={p.user_role === "user" ? "fal fa-user-circle" : "fal fa-store"}
            Word={
              p.agency_username ? (
                <>
                  <span>{p.agency_username}</span>
                  <span>{p.agency_name}</span>
                </>
              ) : (
                username
              )
            }
            title={p.agency_name}
          />
        ),
        ragione:
          tableType === 2 ? (
            <UtenteCell
              icon={p.user_role === "user" ? "fal fa-user-circle" : "fal fa-store"}
              Word={p.agency_name}
              title={p.agency_name}
            />
          ) : (
            <SpanFormater
              myClassName="nomeTd"
              Word={p?.agency_name}
              size={35}
              nrOfRows={1}
              formatWord={true}
            />
          ),
        service: p.service_name,
        importo: p.sign + numberWithCommas(p.price1000 / 100) + "€",
        commissione: p.commissione + "€",
        proviggione: p.percentage + "€",
        aggio: p.system_aggio + "€",
        saldo: p.saldo + "€",
        user_role: p.user_role,
        details: <i className="fal fa-plus-square" />,
        image: p.logo,
        username: p.agency_username,
      }))

    return (
      <>
        <div className="Container Annunci Movimenti" style={{ width: "100%" }}>
          {this.state.showModalResponsive === true &&
            this.props.screenWidth <= 1024 &&
            forAdmin && (
              <ModalResponsiveForTables
                Close={(e) => {
                  this.setState({
                    modalDetails: "",
                    showModalResponsive: false,
                  })
                }}
                Header={
                  <React.Fragment>
                    <i className="fal fa-user-alt" aria-hidden="true"></i>
                    <span>{modalDetails?.ragione?.props?.Word}</span>
                  </React.Fragment>
                }
                beforeFooter={null}
                Footer={null}
                Rows={
                  <React.Fragment>
                    <div className="ServiceRow">
                      <ModalRow
                        title="Service"
                        data={modalDetails.service}
                        image={<img src={`${baseUrlImg}/${modalDetails.image}`} />}
                      />
                    </div>
                    <div className="DateOraRow">
                      <ModalRow title="Date Ora" data={modalDetails.date} />
                    </div>
                    <div className="OtherRow">
                      <ModalRow title="Barcode" data={modalDetails?.barcode} />

                      <ModalRow
                        title="User"
                        data={modalDetails?.ragione?.props?.Word}
                      />
                      <ModalRow title="Importo" data={modalDetails.importo} />
                      <ModalRow
                        title="Commissione"
                        data={modalDetails.commissione}
                      />
                      <ModalRow
                        title="Proviggione"
                        data={modalDetails.proviggione}
                      />
                      <ModalRow title="Aggio" data={modalDetails?.aggio} />
                      <ModalRow title="Saldo" data={modalDetails.saldo} />
                    </div>
                  </React.Fragment>
                }
              />
            )}
          <div className="container-fluid overview">
            <div className="panels-container">
              {isCalendarOpen && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                  }}
                  picker={picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}
              {screenWidth > 865 && this.props.activeSkinId !== -1 && (
                <>
                  {/* <AdminNewStatistiche
                    importo={stat?.importo}
                    commissione={stat?.commissione}
                    provRete={stat?.proviggioni}
                    aggio={stat?.system_aggio}
                    onDateChange={this.handleDateChange}
                  /> */}
                  <div className="movimenti-header-container">
                    <h1
                      onClick={() => {
                        this.props.getPayments(
                          "",
                          "",
                          "",
                          1,
                          50,
                          this.props.activeSkinId
                        )
                        this.props.getStatistiche(
                          this.props.activeSkinId,
                          "",
                          "",
                          "",
                          ""
                        )
                        this.setState({
                          tableType: 1,
                          username: "",
                          service: "",
                          from: "",
                          to: "",
                        })
                      }}
                    >
                      TUTTI MOVIMENTI
                    </h1>

                    <div
                      className="dal calendar"
                      type="text"
                      onClick={() => {
                        this.setCalendar(true)
                      }}
                    >
                      <i className="fal fa-calendar-alt"></i>
                      <span>
                        {fromLabel
                          ? `${fromLabel} - ${toLabel}`
                          : "Seleziona la data"}
                      </span>
                    </div>
                    {this.props.accountInfo.profile.role.name === "main_admin" && (
                      <button
                        className={"filterBtn" + (tableType === 2 ? " active" : "")}
                        htmltype="submit"
                        onClick={(e) => {
                          this.handleSubmit(e, true)
                        }}
                      >
                        <i className="fad fa-coins"></i>
                        ENTRATE
                      </button>
                    )}
                    <Pdf
                      paymentExcelLoading={this.props.paymentExcelLoading}
                      username={username}
                      from={from}
                      to={to}
                      service={service}
                      perPage={perPage}
                      payments={paymentsForExcel}
                      getPaymentsForExcel={this.props.getPaymentsForExcel}
                    />
                    <Excel
                      username={username}
                      from={from}
                      to={to}
                      service={service}
                      perPage={perPage}
                      payments={paymentsForExcel}
                    />
                  </div>
                </>
              )}

              {this.props.activeSkinId === -1 && (
                <div className="no-data">
                  {" "}
                  <i className="fal fa-info-circle"></i>
                  <span>No Data</span>
                </div>
              )}
              {loadingPayments &&
                (forAdmin ? <div className="loaderAdmin"></div> : <Loader />)}
              {!loadingPayments && this.props.activeSkinId !== -1 && (
                <Table
                  className={tableType === 2 ? "entrate" : ""}
                  rowClassName={(record) =>
                    record.user_role === "user" ? "data-row user" : "data-row"
                  }
                  rowKey={(record) => record.barcode}
                  style={{ width: "100%" }}
                  columns={columns}
                  pagination={false}
                  showHeader={true}
                  dataSource={data}
                />
              )}
            </div>
          </div>
        </div>
        {this.props.activeSkinId !== -1 && (
          <div className="paginationWrapper">
            <Pagination
              onChange={(e) => {
                if (tableType === 1) {
                  forAdmin
                    ? this.props.getPayments(
                        username !== "" ? username : "",
                        from || "",
                        to || "",
                        e,
                        perPage,
                        this.props.activeSkinId,
                        "",
                        service !== "" && service != "TUTTI" ? service : ""
                      )
                    : getPayments(
                        username !== "" ? username : "",
                        from || "",
                        to || "",
                        e,
                        perPage,
                        "",
                        "",
                        service == "TUTTI" ? "" : service
                      )
                } else {
                  this.props.getRefills(e, perPage)
                }
              }}
              total={
                Object.keys(paymentsPages).length === 0
                  ? 1
                  : paymentsPages.total_pages * 10
              }
            />
            <Select
              defaultValue={50}
              onChange={(e) => {
                this.setState({ perPage: parseInt(e) })
                if (tableType === 1) {
                  forAdmin
                    ? getPayments(
                        username !== "" ? username : "",
                        from || "",
                        to || "",
                        1,
                        e,
                        this.props.activeSkinId,
                        "",
                        service == "TUTTI" ? "" : service
                      )
                    : getPayments(
                        username !== "" ? username : "",
                        from || "",
                        to || "",
                        1,
                        e,
                        "",
                        "",
                        service == "TUTTI" ? "" : service
                      )
                } else {
                  this.props.getRefills(1, e)
                }
              }}
            >
              <Option value={10}>10 / Pagina</Option>
              <Option value={25}>25 / Pagina</Option>
              <Option value={50}>50 / Pagina</Option>
              <Option value={100}>100 / Pagina</Option>
            </Select>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  payments: state.auth.payments,
  loadingPayments: state.auth.loadingPayments,
  paymentsFromCode: state.auth.paymentsFromCode,
  paymentsPages: state.auth.paymentsPages,
  screenWidth: state.main.screenWidth,
  paymentsForExcel: state.auth.paymentsForExcel,
  paymentExcelLoading: state.auth.paymentExcelLoading,
  fromDate: state.auth.fromDate,
  usernames: state.auth.usernames,
  services: state.auth.listed_services,
  stat: state.auth.Statistiche?.total,
  services: state.auth.allServices.services,
  listedServices: state.auth.listed_services,
  skinList: state.auth.skinList,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(TransazioniAdmin)
)
