import React, { useState, useEffect,useCallback } from "react";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import MainActions from "redux-store/models/main";
import { NewBarcodeScanner as Scanner } from "shared-components";
import CommonBolletino from "./CommonBolletino"
import _ from "lodash";
import { notification } from "antd";
import { Form } from 'antd';
import { bolletinoSubmit } from "shared-components/ModulePopUp/bolletinoSubmit";
import {
  calculateSaldoVal,
} from "shared-components/ModulePopUp/F24Forms";
import TopWrapper from "./TopWrapper";
import $ from "jquery";
import { withRouter } from "react-router-dom"

const CommonBolletinoWrapper = (props) => {
    const {
  services,
  bolletiniBianchi,
  bolletiniLoading,
  toggleFavorite,
  setShowSepafinLogin,
  form,
  detailByContoCorrente,
  activeService,
  setService,
  allFavServices,
  submit,
  commonBolletinoProps,
  pagamentoRows,
  children,
  handleSubmit,
  getCompanyNameByContoCorrento,
  isSepafin,
  setBolletiniLoading,
  scannedBarcode,
  setScannedBarcode,
  isPrenotaBolletino,
  history,
  accountInfo
}=props;
    const { codiceFiscaleOrPartitaIvaIsRequired, newFields, causaleRequired,companyName } = detailByContoCorrente || {};
 const hasCodiceFiscale = !!detailByContoCorrente && !!codiceFiscaleOrPartitaIvaIsRequired;
  const [barcode, setBarcode] = useState("");
    const [lastRequestTipology, setLastRequestTipology] = useState();
  const [camera, setCamera] = useState(false);
  const getCompanyNameBy =useCallback( (contoCorrentoValue, tipologia) => {
    setLastRequestTipology(tipologia);
    getCompanyNameByContoCorrento(contoCorrentoValue, tipologia);
  },[setLastRequestTipology,getCompanyNameByContoCorrento]);

  const {getFieldValue}=form;

  const contoCorentoChange = (contoCorrentoValue) => {
    const tipologia = getFieldValue("tipologia");
    getCompanyNameBy(contoCorrentoValue, tipologia);
  };
  const delayedCallback = _.debounce(contoCorentoChange, 400);

  useEffect(() => {
    const tipologia = getFieldValue("tipologia");
    if (tipologia && lastRequestTipology && tipologia !== lastRequestTipology && (isSepafin || isPrenotaBolletino )) {
      getCompanyNameBy($("#bolletino_numero_conto_corrente").val(), tipologia);
    }
  }, [getFieldValue, lastRequestTipology,getCompanyNameBy,isSepafin]);

    useEffect(() => {
    const onChangeCallback = (contoCorrentoValue) => delayedCallback(contoCorrentoValue);
    if(isSepafin || isPrenotaBolletino){
      $("#bolletino_numero_conto_corrente").focusout(function () {
      onChangeCallback($(this).val());
    })
  }
  }, [delayedCallback, isSepafin]);

    useEffect(() => {
        if(activeService==="SPAGF24"){
    const element = document.querySelector("#saldo_finale")
    let saldo = calculateSaldoVal(pagamentoRows, form.getFieldValue)
    if (saldo) {
      element.value = saldo
    }
}
  })

  useEffect(() => {
    if (bolletiniBianchi && Object.values(bolletiniBianchi).length > 0)
      notification[bolletiniBianchi.errors ? "error" : "success"]({
        message: bolletiniBianchi.message,
        description: Object.values(bolletiniBianchi.errors || {}),
      });
  }, [bolletiniBianchi]);
  useEffect(() => {
    if (bolletiniLoading) {
      notification["info"]({
        message: "Transazione di caricamento...",
      });
    }
  }, [bolletiniLoading]);

  //const close=() => isPrenotaBolletino ? null : setService(null)
  const close = () => isPrenotaBolletino ? history.push("/dashboard") : setService(null)

  const onBolletinoSubmit=(e) => {
    handleSubmit ?handleSubmit(e,form): bolletinoSubmit(e, activeService, form, (Array.isArray(submit) ? (isSepafin ? submit[0] : submit[1]) : submit), form.resetFields,codiceFiscaleOrPartitaIvaIsRequired,setShowSepafinLogin, false,
      false,
      null,
      newFields,(!isSepafin && !isPrenotaBolletino),setBolletiniLoading,accountInfo?.profile?.username, accountInfo.profile.id);
  }


const mrPayScanBarcode = (barcode="") => {
    if(barcode){
    const counter1 = barcode.substring(0, 2); //2shifror
    const codiceIdf = barcode.substring(2, 2 + parseInt(counter1));
    const counter2 = barcode.substring(20, 22); //2shifror
    const sulCC = barcode.substring(22, 22 + parseInt(counter2));

    const counter3 = barcode.substring(34, 36); //2shifror
    const shuma = parseFloat(barcode.substring(36, 36 + parseInt(counter3))) || 0;
    const counter4 = barcode.substring(46, 47); //1shifror
    const tipologia = barcode.substring(47, 47 + parseInt(counter4));
    form.setFieldsValue({
      codice_identificativo: codiceIdf,
      importo: new Intl.NumberFormat("it-IT").format(parseFloat(shuma.toString()) / 100),
      numero_conto_corrente: sulCC,
      tipologia: tipologia,
    });
  }
  };

  const getBarcodePart = (barcode, beforePartIndex, charLength = 2) => {
    const startIndex = beforePartIndex + charLength;
    const stringCharacters = parseInt(barcode.substring(beforePartIndex, startIndex));
    const endIndex = stringCharacters + startIndex;
    const stringPart = barcode.length >= endIndex && barcode.substring(startIndex, endIndex);
    return { endIndex, stringPart };
  };
  const sepafinScanBarcode = async (barcode) => {
    const barcodePart = (beforePartIndex, charLength = 2) => getBarcodePart(barcode, beforePartIndex, charLength);
    const { endIndex: codiceIndex, stringPart: codice_identificativo } = await barcodePart(0);
    const { endIndex: correnteIndex, stringPart: numero_conto_corrente } = await barcodePart(codiceIndex);
    const { endIndex: importoIndex, stringPart: importo } = await barcodePart(correnteIndex);
    const { stringPart: tipologia } = await barcodePart(importoIndex, 1);
    await form.setFieldsValue({ ...(tipologia ? { tipologia } : {}) });
    await form.setFieldsValue({
      ...(codice_identificativo ? { codice_identificativo } : {}),
      ...(numero_conto_corrente ? { numero_conto_corrente } : {}),
      ...(importo ? { importo: parseFloat(importo) } : {}),
    });
  };
  const scanBarcode = (isSepafin || isPrenotaBolletino ) ? sepafinScanBarcode : mrPayScanBarcode;
  // console.log(activeService)
  if(scannedBarcode && $(`.bolletini--inputs`).length){
    if(activeService === "BOL007" || activeService === "103185"){
     form.setFieldsValue({ code: scannedBarcode });

    }else{
      scanBarcode(scannedBarcode)
    }
     setScannedBarcode(null);
  }
  return (
    <div className={`bolletini bianchi ${props.className || ""}`}>
      {
        !isPrenotaBolletino && <div className="bolletini--services">
        <TopWrapper activeService={activeService} setService={setService} services={services} />{" "}
      </div>
      }
      {camera && (
        // <BarcodeScannerComponent
        //   onUpdate={(err, result) => {
        //     if (result) {
        //       setBarcode(result.text);
        //       setCamera(false);
        //     } else setBarcode("Scanning...");
        //   }}
        // />
        <Scanner
          onDetected={(e) => {
            if (e?.codeResult?.code?.length > 1) {
              setBarcode(e.codeResult.code);
              setCamera(false);
            }
          }}
        />
      )}
      <CommonBolletino
        allFavServices={allFavServices}
        toggleFavorite={toggleFavorite}
        service_id={activeService}
        close={close}
        handleSubmit={onBolletinoSubmit}
        isPrenotaBolletino={isPrenotaBolletino}
        {...commonBolletinoProps}
      >
        <Form>
            <div className="bolletini--inputs">
                {children && children(form,barcode,services,isSepafin,newFields, causaleRequired,hasCodiceFiscale,detailByContoCorrente,companyName,isPrenotaBolletino)}
            </div>
        </Form>
      </CommonBolletino>
    </div>
  );
};
const CommonBolletinoWrapperForm = Form.create({ name: "bolletino" })(CommonBolletinoWrapper);

export default withRouter (connect(({ main: { services }, auth,auth: { bolletiniLoading,detailByContoCorrente, bolletiniBianchi , accountInfo } }) => {
  const isSepafin=!!auth?.accountInfo?.profile?.hasSepafinCredentials
  return {
    services,
    scannedBarcode: auth.scannedBarcode,
    bolletiniLoading,
    bolletiniBianchi,
    detailByContoCorrente,
    isSepafin,
    accountInfo
  };
}, {...AuthActions,...MainActions})(CommonBolletinoWrapperForm));