import "./style.css";
import React, { useState, useEffect } from "react";
import { UserInfoBar } from "shared-componentsMobile";
import Servizi from "./Servizi";
import { isEqual } from "lodash";
import MainActions from "redux-store/models/main";
import { connect } from "react-redux";
import ServicesForms from "./ServicesForms";
import { similarity,isSepafinSelector } from "utils/HelperFunc";

const Card = ({ setTab, tab, id, name, icon, setSearch ,isSepafin}) => {
    const pagaamentiCategory=isSepafin?"SPRDPST":"PRDPST"

return (
  <div
    onClick={() => {
      setSearch("");
      setTab(id);
      // if (id !== "0" && id.includes(pagaamentiCategory)) {
      //   const el = document.querySelector(`#${pagaamentiCategory}`);
      //   if (el) el.click();
      // }
    }}
    id={`tab${id}`}
    className={"serviziFilter--cards__item" + (isEqual(tab, id) ? " active" : "")}
  >
    <i className={`fal ${icon}`}></i>
    <span>{name}</span>
  </div>
)
  }

const Dashboard = (props) => {
  const { getFavorites, favorites, toggleFavorite, accountInfo, getServices, setPrenotaBolletino } = props;
  useEffect(() => {
    getFavorites();
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSepaUser = accountInfo.profile.sepafin_active;

  const [tab, setTab] = useState("0");
  useEffect(() => {
    const el = document.querySelector(".mobileServices--panel .mobileServices--header");
    if (el) el.click();
  }, [tab]);



  const [serviceSearched, setSearch] = useState("");
  const [activeCategory, setCategory] = useState(null);
  const [activeService, setService] = useState(null);

  useEffect(() => {
    const searchWord = serviceSearched.toString().toLowerCase().trim();
    if (similarity(searchWord, "cafe") >= 0.8 || similarity(searchWord, "coffee") >= 0.8) {
      setTab("8");
      setService(null);
    }
  }, [serviceSearched]);

  useEffect(() => {
    setCategory(props.activeCategory);
  }, [props.activeCategory]);

  useEffect(() => {
    setService(props.activeService);
  }, [props.activeService]);

  const setServ=(ser)=>{
    setService(ser);
    props.setActiveService && props.setActiveService(ser)
  }
  return (
    <section className="mobileWrapper">
      <UserInfoBar />
      {!activeService ? (
        <React.Fragment>
          <div className="serviziFilter">
            <div className="serviziFilter--search">
              <input
                value={serviceSearched}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Cerca tra i servizi attivi"
              />
              <i className="fal fa-search" aria-hidden="true"></i>
            </div>
            <div className="serviziFilter--cards">
              <Card
                setSearch={setSearch}
                id={"fav"}
                icon="fa-star"
                tab={tab}
                setTab={setTab}
                name="Servizi Preferiti"
              />
              <div
                onClick={() => {
                  window.location.hash = "products";
                }}
                className={"serviziFilter--cards__item"}
              >
                <i className={`fal fa-shopping-cart`}></i>
                <span>Shop</span>
              </div>
              {/* <Card id={"8"} setSearch={setSearch} icon="fa-coffee" tab={tab} setTab={setTab} name={`Mamanero Caffé`} />  */} 
              <Card
                id={"0"}
                setSearch={setSearch}
                icon="fa-file-alt"
                tab={tab}
                setTab={setTab}
                name={`Tutti${"\n"}Servizi`}
              />
              <Card
                id={[ "SPRDPST" , "PRDPST"]}
                setSearch={setSearch}
                icon="fa-file-alt"
                tab={tab}
                setTab={setTab}
                name="Prodotti Postali"
              />
              <Card
                id={["RTELC", "RTELD", "RTELI"]}
                setSearch={setSearch}
                icon="fa-mobile"
                tab={tab}
                setTab={setTab}
                name={`Ricariche${"\n"}Telefoniche`}
              />
              <Card
                id={["RTVD","CRYPTOSER","RICARICHE PAY TV"]}
                setSearch={setSearch}
                icon="fa-tv"
                tab={tab}
                setTab={setTab}
                name={`Ricariche${"\n"}Tv Digitali`}
              />
              <Card
                id={["SCMS", "RICARICHE CONTI GIOCO"]}
                setSearch={setSearch}
                icon="icon-scomese"
                tab={tab}
                setTab={setTab}
                name={`Ricariche${"\n"}Conti Online`}
              />
              <Card
                icon="fa-user-headset"
                id={"4"}
                setSearch={setSearch}
                tab={tab}
                setTab={setTab}
                name="Servizi su Prenotazione"
              />
            </div>
          </div>
          <Servizi
            setService={setServ}
            setCategory={(cat)=>{
              setCategory(cat);
              props.setActiveCategory && props.setActiveCategory(cat)
            }}
            serviceSearched={serviceSearched}
            tab={tab}
            services={props.services}
            setTab={setTab}
            favorites={favorites}
            setSearch={setSearch}
          />
        </React.Fragment>
      ) : (
        <ServicesForms
          setService={(val) => (val === null && setPrenotaBolletino ? setPrenotaBolletino(false) : setServ(val))}
          services={props.services}
          activeCategory={activeCategory}
          activeService={activeService}
          favorites={favorites}
          toggleFavorite={toggleFavorite}
          accountInfo={accountInfo}
        />
      )}
    </section>
  );
};
const mstp = (state) =>{
const { main: { favorites }, auth: { accountInfo } }=state;
return ({
  favorites,
  accountInfo,
   isSepafin:isSepafinSelector(state),
})}

export default connect(mstp, MainActions)(Dashboard);
