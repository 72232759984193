import React from "react"
import { baseUrlImg } from "../../../config/api"
import images from "themes/images"

const CompaniesFavorite = ({
  CompaniesFav,
  setServiceType,
  changeService,
  togglePopUp,
  toggleFavorite,
  getFavorites,
  getServices,
  accountInfo,
}) => {
  return (
    <>
      {accountInfo?.profile?.role && (
        <div className="Favorites">
          {CompaniesFav &&
          Array.isArray(CompaniesFav) &&
          CompaniesFav.length >= 1 ? (
            CompaniesFav.map((comp) =>
              Object.keys(comp?.companies).map((key) =>
                Object.keys(comp.companies[key]).map((id) => (
                  <div
                    key={id}
                    className={`${
                      ["BOLL", "SBOLL", "BOLMR", "PPA", "RCPP"].includes(id)
                        ? "Pagamenti"
                        : ""
                    }`}
                    onClick={async (e) => {
                      if (e.target.tagName !== "I") {
                        setServiceType(comp.key)
                        if (["BOLL", "SBOLL"].includes(id)) {
                          window.location.hash = "dashboard/pagamenti"
                        } else {
                          changeService(
                            id,
                            comp.companies[key][id].services,
                            comp.companies[key][id].name,
                            "fav"
                          )
                          togglePopUp(true)
                        }
                      }
                    }}
                  >
                    <img
                      src={
                        comp.companies[key][id].services[0].icon
                          ? `${baseUrlImg}/${comp.companies[key][id].services[0].icon}`
                          : images[id]
                          ? images[id]
                          : `${baseUrlImg}/uncategorized/placeholder.jpg`
                      }
                      alt={id}
                    />

                    <i
                      id={`${id}`}
                      onClick={async () => {
                        await toggleFavorite(id, "remove")
                        setTimeout(() => {
                          getFavorites()
                          getServices()
                        }, 200)
                      }}
                      className={`fal fa-star favourite`}
                    ></i>
                  </div>
                ))
              )
            )
          ) : (
            <div className="NF">No Preferiti</div>
          )}
        </div>
      )}
    </>
  )
}

export default CompaniesFavorite
