import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import "antd/dist/antd.css"
import moment from "moment"
import { Azioni, Header, Loader } from "shared-components"
import { slicedAmount } from "utils"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css"
import { isArray } from "lodash"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import ModalResponsiveForTables from "shared-components/ModalResponsiveForTables/ModalResponsiveForTables"
import ModalResPForTabMain from "shared-components/ModalResponsiveForTables/ModalResPForTabMain"
import SpanFormater from "shared-components/SpanFormater/SpanFormater"
import { numberWithCommas } from "utils/HelperFunc"
import ModalRow from "shared-components/ModalResponsiveForTables/ModalRow"
import Excel from "./Excel"
import UseCode from "routes/views/UseCode"
//import ClickOut from "react-onclickout";
import Pdf from "./Pdf"
import { Select, Tooltip, Pagination, Popover } from "antd"
import { withRouter } from "react-router"
import { Form } from "antd"

const { Option } = Select
class Transazioni extends React.Component {
  state = {
    dashboardFromFilterTop: true,
    selectedFilter: 3,
    visible: false,
    indexT: null,
    username: "",
    usernames: null,
    services: null,
    hasVPT: this.props.history?.location?.pathname === "/transazioni/qrcodereader",
    service: "",
    barcode: "",
    name: "",
    address: "",
    phone: "",
    from: "",
    to: "",
    modalDetails: "",
    fromLabel: "",
    toLabel: "",
    perPage: 25,
    showModalResponsive: false,
    picker: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "var(--accent-bg)",
      },
    ],
    isCalendarOpen: false,
    isSearchUtenteOpen: false,
    isSearchDateOpen: false,
    isSearchServiceOpen: false,
    showCalendarM: true,
    downloadOptions: false,

    //1 for movimenti , 2 for refills
    tableType: 1,
    selectedOption: "Mese",
  }
  // (new Date()).setMonth(new Date().getMonth()-1)
  fromFilterTop = (val) => {
    this.setState({ dashboardFromFilterTop: val })
  }
  setCalendar = (val) => {
    this.setState({ isCalendarOpen: val })
  }
  setSearchUtente = (val) => {
    this.setState({ isSearchUtenteOpen: val })
  }
  setSearchDate = (val) => {
    this.setState({ isSearchDateOpen: val })
  }
  setCalendarM = (val) => {
    this.setState({ showCalendarM: val })
  }
  setDownloadOptions = (val) => {
    this.setState({ downloadOptions: val })
  }
  setSearchService = (val) => {
    this.setState({ isSearchServiceOpen: val })
  }

  showModal = (index, barcode, name, address, phone) => {
    this.setState({
      visible: true,
      indexT: index,
      barcode,
      name,
      address,
      phone,
    })
  }
  componentWillUnmount() {
    if (this.props.forAdmin === true) {
      this.props.openModalForAdmin(false)
      this.props.editModalDetails({})
    }
    this.props.setFromDateToDate(null)
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let { usernames, services } = prevState

    if (
      usernames === null &&
      nextProps.usernames &&
      nextProps.usernames.length > 0
    ) {
      usernames = nextProps.usernames
      return { ...prevState, usernames }
    }

    if (services === null && nextProps.services && nextProps.services.length > 0) {
      services = nextProps.services
      return { ...prevState, services }
    }

    return null
  }
  activateModalForAdmin = (item, index) => {
    this.props.openModalForAdmin(true)
    this.props.editModalDetails({
      index,
      barcode: item.barcode,
      agency_name: item.agency_name,
      address: item.agency_address,
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.history?.location?.pathname === "/transazioni/qrcodereader" &&
      !this.state.hasVPT
    ) {
      this.setState({ hasVPT: true })
    }
    const { username, from, to, perPage, service } = this.state
    const { activeSkinId } = this.props

    if (this.props.activeSkinId !== prevProps.activeSkinId && this.props.forAdmin) {
      this.props.getPayments(
        username !== "" ? username : "",
        from || "",
        to || "",
        1,
        perPage ? perPage : 10,
        activeSkinId,
        service || ""
      )
    }
    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY")

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 25,
      })
    }
  }

  handleCancel = (e) => {
    this.props.setPaymentsFromCode({})
    this.setState({
      visible: false,
    })
  }

  handleSubmit = async (e, refills) => {
    const { username, to, from, perPage, service } = this.state
    e.preventDefault()
    if (refills) {
      await this.setState({ tableType: 2 })
      this.props.getRefills(1, perPage)
    } else {
      await this.setState({ tableType: 1 })
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.props.forAdmin) {
            this.props.getPayments(
              username,
              from || "",
              to || "",
              1,
              perPage,
              this.props.activeSkinId,
              "",
              service == "TUTTI" ? "" : service
            )
            this.props.getStatistiche(
              this.props.activeSkinId,
              from || "",
              to || "",
              username,
              service == "TUTTI" ? "" : service
            )
          } else {
            this.props.getPayments(
              username,
              from || "",
              to || "",
              1,
              perPage,
              "",
              "",
              service == "TUTTI" ? "" : service
            )
          }
        }
      })
    }
  }

  changeSelected = (filter) => {
    const { username, perPage } = this.state
    this.setState({ selectedFilter: filter })
    if (filter === 0) {
      const fromDate = moment().format("YYYY-MM-DD")
      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: fromDate,
      })
      this.props.forAdmin
        ? this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            fromDate,
            1,
            perPage,
            this.props.activeSkinId
          )
        : this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            fromDate,
            1,
            perPage
          )
    }
    if (filter === 1) {
      const fromDate = moment().subtract(1, "days").format("YYYY-MM-DD")
      const toDate = moment().subtract(1, "days").format("YYYY-MM-DD")

      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: toDate,
      })
      this.props.forAdmin
        ? this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            fromDate,
            1,
            perPage,
            this.props.activeSkinId
          )
        : this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            toDate,
            1,
            perPage
          )
    }
    if (filter === 2) {
      const fromDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .format("YYYY-MM-DD")
      const toDate = moment().format("YYYY-MM-DD")
      this.setState({
        fromLabel: "",
        toLabel: "",
        from: fromDate,
        to: toDate,
      })
      this.props.forAdmin
        ? this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            toDate,
            1,
            perPage,
            this.props.activeSkinId
          )
        : this.props.getPayments(
            username !== "" ? username : "",
            fromDate,
            toDate,
            1,
            perPage
          )
    }
    if (filter === 3) {
      this.setState({
        from: "",
        to: "",
        fromLabel: "",
        toLabel: "",
      })
      this.props.forAdmin
        ? this.props.getPayments(
            username !== "" ? username : "",
            "",
            "",
            1,
            perPage,
            this.props.activeSkinId
          )
        : this.props.getPayments(username !== "" ? username : "", "", "", 1, perPage)
    }
  }

  handleSearch = (value) => {
    const { usernames } = this.props
    if (value && usernames) {
      let res = usernames.filter((user) =>
        user.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      )
      this.setState({ usernames: res })
    } else {
      this.setState({ usernames: [] })
    }
  }

  handleChange = (value) => {
    this.setState({ username: value, isSearchUtenteOpen: false })
    const { to, from, perPage, service } = this.state
    if (this.props.forAdmin) {
      this.props.getPayments(
        value,
        from || "",
        to || "",
        1,
        perPage,
        this.props.activeSkinId,
        "",
        service == "TUTTI" ? "" : service
      )
      this.props.getStatistiche(
        this.props.activeSkinId,
        from || "",
        to || "",
        value,
        service == "TUTTI" ? "" : service
      )
    } else {
      this.props.getPayments(
        value,
        from || "",
        to || "",
        1,
        perPage,
        "",
        "",
        service == "TUTTI" ? "" : service
      )
    }
  }

  handleChangeService = (value) => {
    this.setState({ service: value, isSearchServiceOpen: false })
    const { username, to, from, perPage } = this.state
    if (this.props.forAdmin) {
      this.props.getPayments(
        username,
        from || "",
        to || "",
        1,
        perPage,
        this.props.activeSkinId,
        "",
        value == "TUTTI" ? "" : value
      )
      this.props.getStatistiche(
        this.props.activeSkinId,
        from || "",
        to || "",
        username,
        value == "TUTTI" ? "" : value
      )
    } else {
      this.props.getPayments(
        username,
        from || "",
        to || "",
        1,
        perPage,
        "",
        "",
        value == "TUTTI" ? "" : value
      )
    }
  }

  handleService = (value) => {
    const { services } = this.props
    if (value && services) {
      let res = services.filter((service) =>
        service.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      )
      this.setState({ services: res })
    } else {
      this.setState({ services: [] })
    }
  }

  componentDidMount() {
    const { username } = this.state
    this.props.setActiveSkinId(
      this.props.accountInfo?.profile?.role?.name === "super_admin" ||
        this.props.accountInfo?.profile?.role?.name === "agent"
        ? this.props.accountInfo.profile.activity_id
        : this.props.activeSkinId !== -1
        ? this.props.activeSkinId
        : 1
    )
    this.props.forAdmin
      ? this.props.getPayments(
          username !== "" ? username : "",
          "",
          "",
          1,
          25,
          this.props.accountInfo?.profile?.role?.name === "super_admin" ||
            this.props.accountInfo?.profile?.role?.name === "agent"
            ? this.props.accountInfo.profile.activity_id
            : this.props.activeSkinId !== -1
            ? this.props.activeSkinId
            : 1
        )
      : this.props.getPayments(username !== "" ? username : "", "", "", 1, 25)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const { forAdmin, paymentsForExcel, screenWidth } = this.props
    const {
      // barcode,
      picker,
      isCalendarOpen,
      fromLabel,
      toLabel,
      to,
      from,
      username,
      service,
      perPage,
      // visible,
      modalDetails,
      tableType,
      showCalendarM,
      selectedOption,
    } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const {
      payments,
      accountInfo,
      loadingPayments,
      // paymentsFromCode,
      paymentsPages,
      navbarSearch,
      getCodiceTicket,
      // skinExtras,
      getPayments,
    } = this.props
    const filters = [
      { name: "Oggi", color: "#707070", key: 0 },
      { name: "Ieri", color: "#0078f", key: 1 },
      { name: "Settimana", color: "#00b850", key: 2 },
      { name: "Mese", color: "#e30000", key: 3 },
    ]

    const paymentsO =
      payments &&
      isArray(payments) &&
      payments.sort(function (a, b) {
        return new Date(b.executed_date) - new Date(a.executed_date)
      })
    return (
      <div
        className={`${forAdmin === true ? "" : "Container"}`}
        style={
          forAdmin === true
            ? { background: "transparent", width: "100%", height: "0px" }
            : { width: "auto" }
        }
      >
        {this.props.SameModals}
        {this.props.MessaggiComponent}

        {this.props.forAdmin === true ? null : (
          <React.Fragment>
            <Header></Header>
          </React.Fragment>
        )}
        {this.state.showModalResponsive === true &&
          this.props.screenWidth <= 1024 &&
          forAdmin && (
            <ModalResponsiveForTables
              Close={(e) => {
                this.setState({
                  modalDetails: "",
                  showModalResponsive: false,
                })
              }}
              Header={
                <React.Fragment>
                  <i className="fal fa-user-alt" aria-hidden="true"></i>
                  <span>{modalDetails.agency_name}</span>
                </React.Fragment>
              }
              beforeFooter={null}
              Footer={null}
              Rows={
                <React.Fragment>
                  <div className="ServiceRow">
                    <ModalRow title="Service" data={modalDetails.service_name} />
                  </div>
                  <div className="DateOraRow">
                    <ModalRow
                      title="Date Ora"
                      data={moment(modalDetails.executed_date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    />
                  </div>
                  <div className="OtherRow">
                    <div
                      onClick={() => {
                        console.log("clicked")
                      }}
                    >
                      <ModalRow
                        title="Barcode"
                        data={modalDetails.barcode}
                        // handleClick={() => {
                        //   getCodiceTicket(
                        //     modalDetails.barcode,
                        //     modalDetails.service_name
                        //   );
                        //   this.showModal(
                        //     this.state.index,
                        //     modalDetails.barcode,
                        //     modalDetails.agency_name,
                        //     modalDetails.agency_address,
                        //     modalDetails.agency_phone
                        //   );
                        // }}
                      />
                    </div>

                    <ModalRow title="User" data={modalDetails.agency_name} />
                    <ModalRow
                      title="Importo"
                      data={numberWithCommas(modalDetails.price1000 / 100)}
                    />
                    <ModalRow title="Commissione" data={modalDetails.commissione} />
                    <ModalRow title="Proviggione" data={modalDetails.percentage} />
                    <ModalRow title="Saldo" data={modalDetails.saldo} />
                  </div>
                </React.Fragment>
              }
            />
          )}
        {this.state.showModalResponsive === true &&
          this.props.screenWidth <= 800 &&
          !forAdmin && (
            <ModalResPForTabMain
              Close={(e) => {
                this.setState({
                  modalDetails: "",
                  showModalResponsive: false,
                })
              }}
              mobilePopUpData={modalDetails}
              exception={"sign"}
              handleClick={() => {
                getCodiceTicket(modalDetails.barcode, modalDetails.service_name)
                this.showModal(
                  this.state.index,
                  modalDetails.barcode,
                  modalDetails.agency_name,
                  modalDetails.agency_address,
                  modalDetails.agency_phone
                )
              }}
            />
          )}
        <div className="container-fluid overview ">
          {!forAdmin && (
            <Azioni activeMain="contabilita" active="transazioni"></Azioni>
          )}

          <div className="panels-container">
            {/* <div style={{display:'flex',width:'100%',height:'37px'}}>
               {this.props.forAdmin && <React.Fragment> <Form.Item>
                                  <Input
                                    style={{width:"150px"}}
                                    placeholder={"Commissione (from-to)"}
                                    onChange={this.handleCommissione}
                                  >
                              </Input>
                            </Form.Item>
                            <Form.Item>
                                  <Input
                                    style={{width:"150px"}}
                                    placeholder={"Proviggione (from-to)"}
                                    onChange={this.handleProviggione}
                                  >
                              </Input>
                            </Form.Item>
                            <Form.Item>
                                  <Input
                                    style={{width:"150px"}}
                                    placeholder={"Importo (from-to)"}
                                    onChange={this.handleImporto}
                                  >
                              </Input>
                            </Form.Item>
                            </React.Fragment>}
            </div> */}
            <div className="sort-annunci sort-trasazioni max-width border-0">
              {isCalendarOpen && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                  }}
                  picker={picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}
              <div className="MoviFirst movimenti">
                {<h1 className="heading-tab">Lista Movimenti</h1>}
                {forAdmin && (
                  <div className="DateUtent">
                    <div className="datepics ml-auto mr-2">
                      <Form
                        {...formItemLayout}
                        onSubmit={this.handleSubmit}
                        className="filters"
                      >
                        {
                          <React.Fragment>
                            <div className="dal">
                              {
                                <Form.Item>
                                  {getFieldDecorator(
                                    "username",
                                    {}
                                  )(
                                    // <Select
                                    //   value={this.state.username}
                                    //   onChange={this.handleCurrencyChange}
                                    // >
                                    //   <Option value="rmb">RMB</Option>
                                    //   <Option value="dollar">Dollar</Option>
                                    // </Select>
                                    <React.Fragment>
                                      <Select
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={true}
                                        onSearch={this.handleSearch}
                                        onChange={this.handleChange}
                                        // notFoundContent={null}
                                        placeholder={"Cerca Utente"}
                                      >
                                        {this.props.usernames &&
                                          this.props.usernames.length > 0 &&
                                          this.props.usernames.map((user) => (
                                            <Option key={user}>{user}</Option>
                                          ))}
                                      </Select>
                                      <i
                                        onClick={(e) => {
                                          this.handleSubmit(e)
                                        }}
                                        className="fal fa-search"
                                      ></i>
                                      {/* <AdminFilterButton
                                    screenWidth={screenWidth}
                                    specificScreenWidth={screenWidth <= 550}
                                    forAdmin={this.props.forAdmin}
                                    handleSubmit={this.handleSubmit}
                                    accountInfo={accountInfo}
                                    tableType={tableType}
                                  /> */}
                                    </React.Fragment>
                                  )}
                                </Form.Item>
                              }
                            </div>
                          </React.Fragment>
                        }

                        {
                          <React.Fragment>
                            <div className="dal">
                              {
                                <Form.Item>
                                  <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={true}
                                    onSearch={this.handleService}
                                    placeholder={"Cerca Servizio"}
                                    onChange={this.handleChangeService}
                                  >
                                    {this.props.services &&
                                      this.props.services.length > 0 &&
                                      this.props.services.map((service) => (
                                        <Option key={service}>{service}</Option>
                                      ))}
                                  </Select>
                                  <i
                                    onClick={(e) => {
                                      this.handleSubmit(e)
                                    }}
                                    className="fal fa-search"
                                  ></i>
                                </Form.Item>
                              }
                            </div>
                          </React.Fragment>
                        }

                        <div
                          className="dal calendar"
                          type="text"
                          onClick={() => {
                            this.setCalendar(true)
                          }}
                        >
                          <span>
                            {fromLabel
                              ? `${fromLabel} - ${toLabel}`
                              : "Seleziona la data"}
                            <i className="fal fa-calendar-alt"></i>
                          </span>
                        </div>
                      </Form>

                      <div className="codice"></div>
                    </div>
                  </div>
                )}

                {/* {!this.props.forAdmin && screenWidth > 1024 && (
                  <button
                    className={"filterBtn" + (tableType === 1 ? " active" : "")}
                    htmltype="submit"
                    onClick={this.handleSubmit}
                  >
                    Filter
                  </button>
                )} */}
              </div>
              {!this.props.forAdmin ? (
                <React.Fragment>
                  <div className="barCodeExport">
                    <button
                      onClick={() => this.setState({ hasVPT: true })}
                      className="barcodeBtn"
                    >
                      {screenWidth >= 550 ? "ricerca movimenti" : ""}{" "}
                      <i className="fal fa-barcode-read"></i>
                    </button>
                    <div className="date-selections">
                      {filters.map((filter) => (
                        <div
                          key={filter.key}
                          className={`select ${
                            selectedOption === filter.name ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState({ selectedOption: filter.name })
                            this.changeSelected(filter.key)
                          }}
                        >
                          {filter.name}
                        </div>
                      ))}
                    </div>
                    <div className="filesBtns">
                      <Pdf
                        paymentExcelLoading={this.props.paymentExcelLoading}
                        username={username}
                        from={from}
                        to={to}
                        service={service}
                        perPage={perPage}
                        payments={paymentsForExcel}
                        getPaymentsForExcel={this.props.getPaymentsForExcel}
                      />
                      <Excel
                        username={username}
                        from={from}
                        to={to}
                        service={service}
                        perPage={perPage}
                        payments={paymentsForExcel}
                      />
                    </div>
                  </div>
                  {/* {!forAdmin && screenWidth < 1024 && (
                    <button
                      className={
                        "filterBtn" + (tableType === 1 ? " active" : "")
                      }
                      htmltype="submit"
                      onClick={this.handleSubmit}
                    >
                      Filter
                    </button>
                  )} */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="barCodeExport">
                    <AdminFilterButton
                      screenWidth={screenWidth}
                      specificScreenWidth={screenWidth > 550}
                      forAdmin={this.props.forAdmin}
                      handleSubmit={this.handleSubmit}
                      accountInfo={accountInfo}
                      tableType={tableType}
                    />
                    <button
                      onClick={() => this.setState({ hasVPT: true })}
                      className="barcodeBtn"
                    >
                      {screenWidth >= 550 ? "ricerca movimenti" : ""}{" "}
                      <i className="fal fa-barcode-read"></i>
                    </button>
                    <div className="filesBtns">
                      <Pdf
                        paymentExcelLoading={this.props.paymentExcelLoading}
                        username={username}
                        from={from}
                        to={to}
                        service={service}
                        perPage={perPage}
                        payments={paymentsForExcel}
                        getPaymentsForExcel={this.props.getPaymentsForExcel}
                        activeSkinId={this.props.activeSkinId}
                      />
                      <Excel
                        username={username}
                        from={from}
                        to={to}
                        service={service}
                        perPage={perPage}
                        payments={paymentsForExcel}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row no-gutters max-width movimenti">
              <div className="col-md-12">
                {payments?.message && (
                  <div className="alert alert-danger text-center">
                    {payments?.message}
                  </div>
                )}

                {loadingPayments &&
                  (forAdmin ? <div className="loaderAdmin"></div> : <Loader />)}
                {!loadingPayments && (
                  <table
                    className={
                      "transTable Movimenti" + (tableType === 2 ? " refills" : "")
                    }
                  >
                    <thead>
                      <tr>
                        <td
                          className="wsNwp"
                          onClick={() => {
                            this.setCalendar(true)
                          }}
                        >
                          <span className="wsNwp-title">Date / Ora</span>
                          <i className="fal fa-calendar-alt" />
                        </td>
                        <td className="wsNwp">
                          {this.props.screenWidth < 450 && !forAdmin ? (
                            "Ora"
                          ) : (
                            <div onClick={() => this.setState({ hasVPT: true })}>
                              <span className="wsNwp-title">Barcode</span>
                              <i className="fal fa-barcode-read"></i>
                            </div>
                          )}
                        </td>
                        <td className="wsNwp">
                          <Select
                            showSearch
                            showArrow={false}
                            filterOption={true}
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                            placeholder={"Utente"}
                          >
                            {this.props.usernames &&
                              this.props.usernames.length > 0 &&
                              this.props.usernames.map((user) => (
                                <Option key={user}>{user}</Option>
                              ))}
                          </Select>
                          <i className="fal fa-search" />
                        </td>
                        <td className="wsNwp servizoTd">
                          {this.props.screenWidth < 450 && !forAdmin ? (
                            "Servizio"
                          ) : (
                            <>
                              <Select
                                showSearch
                                showArrow={false}
                                filterOption={true}
                                onSearch={this.handleService}
                                placeholder={"Servizio"}
                                onChange={this.handleChangeService}
                                // style={{display:"flex", alignItems:"center"}}
                              >
                                {this.props.services &&
                                  this.props.services.length > 0 &&
                                  this.props.services.map((service) => (
                                    <Option key={service}>{service}</Option>
                                  ))}
                              </Select>
                              <i className="fal fa-search" />
                            </>
                          )}
                        </td>
                        <td className="wsNwp right">
                          {this.props.screenWidth < 450 && !forAdmin
                            ? "Costo"
                            : "Importo"}
                        </td>
                        <td className="wsNwp right">Commissione</td>
                        {accountInfo.profile.role.name !== "user" && (
                          <td className=" wsNwp right">Proviggione</td>
                        )}
                        <td className=" wsNwp right">Saldo</td>
                        {this.props.screenWidth <= 865 && forAdmin && <td></td>}

                        {this.props.screenWidth <= 1024 && forAdmin ? (
                          <td className="wsNwp"></td>
                        ) : null}
                        {this.props.screenWidth < 450 && (
                          <td className="wsNwp right" style={{ paddingLeft: "6px" }}>
                            Dett.
                          </td>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!payments?.message &&
                      (paymentsO || []) &&
                      paymentsO?.length === 0 ? (
                        <tr className="NoData">
                          <td colSpan="8">
                            {" "}
                            <i className="fal fa-info-circle"></i>
                            <span>No Data</span>
                          </td>
                        </tr>
                      ) : (
                        (paymentsO || []).map((item, index) => {
                          return (
                            (
                              item.service_name &&
                              item.service_name.toString().toLowerCase()
                            ).includes(navbarSearch.toLowerCase()) && (
                              <tr
                                key={index}
                                onClick={(e) => {
                                  if ([...e.target.classList].includes("bc")) {
                                    getCodiceTicket(
                                      item.barcode,
                                      item.service_name,
                                      null,
                                      "right"
                                    )
                                  }
                                  if (e.target.tagName !== "I") {
                                    if (
                                      forAdmin &&
                                      this.props.screenWidth <= 402 &&
                                      ![...e.target.classList].includes("bc")
                                    ) {
                                      // this.setState({
                                      //   showModalResponsive: true,
                                      //   modalDetails: item,
                                      // });
                                    } else if (
                                      !forAdmin &&
                                      this.props.screenWidth <= 800 &&
                                      this.props.screenWidth > 450 &&
                                      ![...e.target.classList].includes("bc")
                                    ) {
                                      this.setState({
                                        showModalResponsive: true,
                                        modalDetails: item,
                                      })
                                    }
                                    forAdmin &&
                                    this.props.screenWidth > 1320 &&
                                    this.props.screenWidth > 450 &&
                                    [...e.target.classList].includes("bc")
                                      ? this.activateModalForAdmin(item, index)
                                      : [...e.target.classList].includes("bc") &&
                                        this.showModal(
                                          index,
                                          item.barcode,
                                          item.agency_name,
                                          item.agency_address,
                                          item.agency_phone
                                        )
                                  }
                                }}
                              >
                                <td className="wsNwp">
                                  {moment(item.executed_date).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </td>

                                {screenWidth <= 450 ? (
                                  <td
                                    onClick={() => {
                                      getCodiceTicket(
                                        item.barcode,
                                        item.service_name,
                                        null,
                                        "right"
                                      )
                                    }}
                                    className="wsNwp"
                                  >
                                    <div
                                      style={{
                                        color: "#2680EB",
                                        fontSize: "12px",
                                        marginTop: "4px",
                                        width: "80px",
                                        fontWeight: "500",
                                        lineHeight: "15px",
                                        padding: "0 5px",
                                        textDecoration: "underline",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div>
                                        {moment(item.executed_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: "3px",
                                          display: "flex",
                                        }}
                                      >
                                        {moment(item.executed_date).format(
                                          "HH:mm:ss"
                                        )}
                                      </div>
                                    </div>
                                    {/* <div
                                      className="bc"
                                      style={{
                                        color: "#2680EB",
                                        fontSize: "16px",
                                        borderBottom: "1px solid #2680EB",
                                      }}
                                    >
                                      {item.barcode}
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        width: "100%",
                                        marginTop: "20px",
                                        fontSize: "12px",
                                        letterSpacing: "-0.2px",
                                      }}
                                    >
                                      {moment(item.executed_date).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </div> */}
                                  </td>
                                ) : (
                                  <td className="wsNwp">
                                    <div className="bc">{item.barcode}</div>
                                  </td>
                                )}
                                {screenWidth <= 550 ? (
                                  <td className="wsNwp">
                                    <div>{item.agency_name}</div>
                                    <div>{item.service_name}</div>
                                  </td>
                                ) : accountInfo?.profile.role.name ===
                                    "main_admin" ||
                                  accountInfo?.profile.role.name === "support" ? (
                                  <Popover
                                    content={<div>{item.agency_username}</div>}
                                    trigger="click"
                                  >
                                    <td className="wsNwp">
                                      {" "}
                                      <i
                                        className={`fal fa-${
                                          item.user_role === "agency"
                                            ? "store"
                                            : "user-alt"
                                        }`}
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <Tooltip title={item.agency_name}>
                                        <SpanFormater
                                          myClassName="nomeTd"
                                          Word={item.agency_name}
                                          size={35}
                                          nrOfRows={1}
                                          formatWord={true}
                                        />
                                      </Tooltip>
                                    </td>
                                  </Popover>
                                ) : (
                                  <td className="wsNwp">
                                    {" "}
                                    <i
                                      className={`fal fa-${
                                        item.user_role === "agency"
                                          ? "store"
                                          : "user-alt"
                                      }`}
                                      aria-hidden="true"
                                    ></i>{" "}
                                    <Tooltip title={item.agency_name}>
                                      <SpanFormater
                                        myClassName="nomeTd"
                                        Word={item.agency_name}
                                        size={35}
                                        nrOfRows={1}
                                        formatWord={true}
                                      />
                                    </Tooltip>
                                  </td>
                                )}

                                <td className="wsNwp servizoTd">
                                  <span className="ellipsis">
                                    {item.service_name}
                                  </span>
                                </td>

                                <td className="wsNwp right importoTd">
                                  <i
                                    className={`fal fa-${
                                      item.sign === "-" ? "minus" : "plus"
                                    }-circle fa-sm`}
                                    style={{
                                      color:
                                        item.sign === "-" ? "#ff0000" : "#0da90f",
                                    }}
                                  />
                                  <span>
                                    {numberWithCommas(
                                      item.price1000
                                        ? slicedAmount(item.price1000 / 100)
                                        : "-"
                                    )}
                                    €
                                  </span>
                                </td>
                                <td className="wsNwp right">
                                  {item.commissione}€
                                  {/* {item.commissione ? item.commissione : "-"}{" "} */}
                                </td>
                                {accountInfo.profile.role.name !== "user" && (
                                  <td className="wsNwp right">
                                    {item.percentage}€
                                    {/* {parseInt(item.percentage) > 0
                                ? item.percentage
                                : "-"} */}
                                  </td>
                                )}

                                <td className="wsNwp right">
                                  {item.saldo !== "-" ? item.saldo + "€" : "-"}
                                </td>
                                {this.props.screenWidth < 450 && !forAdmin && (
                                  <td
                                    className="wsNwp right"
                                    onClick={(e) => {
                                      if ([...e.target.classList].includes("bc")) {
                                        getCodiceTicket(
                                          item.barcode,
                                          item.service_name,
                                          null,
                                          "right"
                                        )
                                      }
                                      if (
                                        this.props.screenWidth <= 450 &&
                                        ![...e.target.classList].includes("bc")
                                      ) {
                                        this.setState({
                                          showModalResponsive: true,
                                          modalDetails: item,
                                        })
                                      }
                                    }}
                                  >
                                    <i className="fal fa-plus-square"></i>
                                  </td>
                                )}

                                {this.props.screenWidth <= 1024 && forAdmin && (
                                  <td
                                    className="wsNwp"
                                    style={{ textAlign: "center" }}
                                    onClick={() => {
                                      this.setState({
                                        showModalResponsive: true,
                                        modalDetails: item,
                                        index: index,
                                      })
                                    }}
                                  >
                                    <i className="fal fa-eye"></i>
                                  </td>
                                )}
                              </tr>
                            )
                          )
                        })
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="paginationWrapper">
                <Pagination
                  onChange={(e) => {
                    if (tableType === 1) {
                      forAdmin
                        ? this.props.getPayments(
                            username !== "" ? username : "",
                            from || "",
                            to || "",
                            e,
                            perPage,
                            this.props.activeSkinId,
                            "",
                            service !== "" && service != "TUTTI" ? service : ""
                          )
                        : getPayments(
                            username !== "" ? username : "",
                            from || "",
                            to || "",
                            e,
                            perPage,
                            "",
                            "",
                            service == "TUTTI" ? "" : service
                          )
                    } else {
                      this.props.getRefills(e, perPage)
                    }
                  }}
                  total={
                    Object.keys(paymentsPages).length === 0
                      ? 1
                      : paymentsPages.total_pages * 10
                  }
                />
                <Select
                  defaultValue={25}
                  onChange={(e) => {
                    this.setState({ perPage: parseInt(e) })
                    if (tableType === 1) {
                      forAdmin
                        ? getPayments(
                            username !== "" ? username : "",
                            from || "",
                            to || "",
                            1,
                            e,
                            this.props.activeSkinId,
                            "",
                            service == "TUTTI" ? "" : service
                          )
                        : getPayments(
                            username !== "" ? username : "",
                            from || "",
                            to || "",
                            1,
                            e,
                            "",
                            "",
                            service == "TUTTI" ? "" : service
                          )
                    } else {
                      this.props.getRefills(1, e)
                    }
                  }}
                >
                  <Option value={10}>10 / Pagina</Option>
                  <Option value={25}>25 / Pagina</Option>
                  <Option value={50}>50 / Pagina</Option>
                </Select>
              </div>
            </div>
          </div>
          {screenWidth < 450 && (
            <div className="fixed-nav-footer">
              <div className="scrollmenu">
                <a
                  role="button"
                  onClick={() => this.setState({ isSearchUtenteOpen: true })}
                >
                  <i className="fal fa-user-circle"></i>
                </a>
                <a
                  role="button"
                  onClick={() => this.setState({ isSearchDateOpen: true })}
                >
                  <i className="fal fa-calendar-alt"></i>
                </a>
                <a role="button" onClick={() => this.setState({ hasVPT: true })}>
                  <i className="fal fa-barcode-read"></i>
                </a>
                <a
                  role="button"
                  onClick={() => this.setState({ isSearchServiceOpen: true })}
                >
                  <i className="fal fa-briefcase"></i>
                </a>
                <a onClick={() => this.setState({ downloadOptions: true })}>
                  <i className="fal fa-file-download"></i>
                </a>
              </div>
            </div>
          )}
        </div>
        {/* <!--Chat icon botm right corner--> */}
        <div className="chatSticky">
          <img src="img/chatSticky.svg" alt="" />
        </div>
        {this.state.hasVPT && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ hasVPT: false })
            }}
          >
            <UseCode
              className="vptCont-mobile"
              paymentsO={paymentsO}
              getCodiceTicket={getCodiceTicket}
              showModal={this.showModal}
            />
          </div>
        )}

        {screenWidth < 450 && this.state.isSearchUtenteOpen && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ isSearchUtenteOpen: false })
            }}
          >
            <div
              className="vptCont"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <React.Fragment>
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={true}
                  onSearch={this.handleSearch}
                  onChange={this.handleChange}
                  placeholder="Cerca Utente"
                >
                  {this.props.usernames &&
                    this.props.usernames.length > 0 &&
                    this.props.usernames.map((user) => (
                      <Option key={user}>{user}</Option>
                    ))}
                </Select>
                <i className="fal fa-user-circle" aria-hidden="true"></i>
              </React.Fragment>
            </div>
          </div>
        )}

        {screenWidth < 450 && this.state.isSearchDateOpen && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ isSearchDateOpen: false })
            }}
          >
            <div
              className="vptCont"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {this.state.showCalendarM && (
                <CalendarRangePicker
                  setStateFunc={(item) => {
                    this.setState({
                      picker: [item.selection],
                      from: moment(item.selection.startDate).format("YYYY-MM-DD"),
                      to: moment(item.selection.endDate).format("YYYY-MM-DD"),
                      fromLabel: moment(item.selection.startDate).format(
                        "DD/MM/YYYY"
                      ),
                      toLabel: moment(item.selection.endDate).format("DD/MM/YYYY"),
                    })
                  }}
                  setStateFuncEmpty={() => {
                    this.setState({
                      from: "",
                      to: "",
                      fromLabel: "",
                      toLabel: "",
                    })
                  }}
                  picker={picker}
                  setCalendar={this.setCalendar}
                  handleSubmit={this.handleSubmit}
                />
              )}

              <React.Fragment>
                <input
                  type="text"
                  className="calendar-placeholder "
                  placeholder={
                    fromLabel ? `${fromLabel} - ${toLabel}` : "Seleziona la data"
                  }
                  disabled
                />
                <i
                  onClick={() => {
                    this.setState({ showCalendarM: !showCalendarM })
                  }}
                  className="fal fa-calendar-alt"
                  aria-hidden="true"
                ></i>
                {/* <div
                        className="calendar-placeholder"
                        type="text"
                        onClick={() => {
                          this.setState({ showCalendarM: true });
                        }}
                      >
                        <span>
                          {fromLabel
                            ? `${fromLabel} - ${toLabel}`
                            : "Seleziona la data"}
                          <i className="fal fa-calendar-alt"></i>
                        </span>
                      </div> */}
              </React.Fragment>
            </div>
          </div>
        )}

        {screenWidth < 450 && this.state.downloadOptions && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ downloadOptions: false })
            }}
          >
            <div
              className="vptCont"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <React.Fragment>
                {/* <div className="download-options-container">
                  <img src={images.pdf_img} alt="pdf-img" />
                  <img src={images.csv_img} alt="csv-img" />
                </div> */}

                <div className="download-options-container">
                  <Pdf
                    paymentExcelLoading={this.props.paymentExcelLoading}
                    username={username}
                    from={from}
                    to={to}
                    service={service}
                    perPage={perPage}
                    payments={paymentsForExcel}
                    getPaymentsForExcel={this.props.getPaymentsForExcel}
                    activeSkinId={this.props.activeSkinId}
                  />
                  <Excel
                    username={username}
                    from={from}
                    to={to}
                    service={service}
                    perPage={perPage}
                    payments={paymentsForExcel}
                  />
                </div>
              </React.Fragment>
            </div>
          </div>
        )}

        {screenWidth < 450 && this.state.isSearchServiceOpen && (
          <div
            className="backDrop"
            onClick={() => {
              this.setState({ isSearchServiceOpen: false })
            }}
          >
            <div
              className="vptCont"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <React.Fragment>
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={true}
                  onSearch={this.handleService}
                  placeholder={"Cerca Servizio"}
                  onChange={this.handleChangeService}
                >
                  {this.props.services &&
                    this.props.services.length > 0 &&
                    this.props.services.map((service) => (
                      <Option key={service}>{service}</Option>
                    ))}
                </Select>
                <i className="fal fa-briefcase" aria-hidden="true"></i>
              </React.Fragment>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const TransazioniF = Form.create({ name: "Transazioni" })(Transazioni)

const AdminFilterButton = (props) => {
  const {
    screenWidth,
    specificScreenWidth,
    forAdmin,
    handleSubmit,
    accountInfo,
    tableType,
  } = props
  return (
    !!forAdmin &&
    !!specificScreenWidth && (
      <>
        {/* <button
          className={"filterBtn" + (tableType === 1 ? " active" : "")}
          htmltype="submit"
          onClick={(e) => {
            handleSubmit(e)
          }}
        >
          <i className="fas fa-filter"></i>
          Filter
        </button> */}
        {accountInfo.profile.role.name === "main_admin" && screenWidth > 550 && (
          <button
            className={"filterBtn" + (tableType === 2 ? " active" : "")}
            htmltype="submit"
            onClick={(e) => {
              handleSubmit(e, true)
            }}
          >
            <i className="fad fa-coins"></i>
            ENTRATE
          </button>
        )}
      </>
    )
  )
}

const getDropdownFilters = (forAdmin, inRow, filters, selectedFilter, onClick) => {
  return filters.map((item, index) => {
    return (
      <DropdownChild
        inRow={inRow}
        selected={selectedFilter === parseInt(index.toString())}
        onClick={() => onClick && onClick(index)}
        value={index.toString()}
        key={(item.name + index).toString()}
      >
        <span
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          {forAdmin ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              style={{
                marginRight: "15px",
              }}
            >
              <g className="a">
                <circle
                  className="b"
                  cx="7"
                  cy="7"
                  r="7"
                  style={{
                    fill: `${item.color}`,
                  }}
                />
                <circle
                  className="c"
                  cx="7"
                  cy="7"
                  r="4"
                  style={{ fill: "#ffffff" }}
                />
              </g>
            </svg>
          ) : (
            <i
              className="fas fa-dot-circle"
              style={{
                color: `${item.color}`,
                paddingRight: "12px",
                fontSize: "13px",
              }}
            ></i>
          )}
          {item.name}
        </span>
      </DropdownChild>
    )
  })
}

const DropdownChild = (props) => {
  const { inRow, children, value, onClick, selected, key } = props
  return inRow ? (
    <div
      className={`dropdown-row__child ${selected && "selected"}`}
      onClick={onClick}
    >
      {children}
    </div>
  ) : (
    <Option key={key} value={value}>
      {children}
    </Option>
  )
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service_id: state.auth.service_id,
  payments: state.auth.payments,
  loadingPayments: state.auth.loadingPayments,
  usernames: state.auth.usernames,
  services: state.auth.listed_services,
  accountInfo: state.auth.accountInfo,
  navbarSearch: state.main.navbarSearch,
  skinExtras: state.auth.skinExtras,
  paymentsFromCode: state.auth.paymentsFromCode,
  paymentsPages: state.auth.paymentsPages,
  screenWidth: state.main.screenWidth,
  paymentsForExcel: state.auth.paymentsForExcel,
  paymentExcelLoading: state.auth.paymentExcelLoading,
  fromDate: state.auth.fromDate,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(TransazioniF)
)
