import React from "react"
import { Button, Collapse, List, Spin } from "antd"

const { Panel } = Collapse

const DisplayCategories = ({
  categories,
  onSelectSubcategory,
  onSelectCategory,
  loading,
}) => {
  return (
    <div>
      {loading ? (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}>
        <Spin size="large"  />
      </div>
      ) : (
        <Collapse defaultActiveKey={["0"]} >
          {categories.map((category, index) => (
            <Panel
              header={category.name}
              key={index}
              extra={
                <Button
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation()
                    onSelectCategory(category)
                  }}
                >
                  Select
                </Button>
              }
            >
              <List
                dataSource={
                  category?.subcategories?.length > 0
                    ? category.subcategories
                    : category.selectedServices
                }
                renderItem={(subcategory) => (
                  <List.Item
                    key={subcategory.name}
                    extra={
                      <Button
                        type="link"
                        onClick={() => onSelectSubcategory(subcategory)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    <div>
                      {subcategory.name}
                      <div>
                        <ul>
                          {subcategory?.selectedServices?.map(
                            (service, serviceIndex) => (
                              <li key={serviceIndex}>{service}</li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  )
}

export default DisplayCategories
