import React from "react"
import { baseUrlImg } from "../../config/api"

const PrintTicketTopUp = ({ arr, barcode }) => {
  const rowLabels = [
    { label: "Service Name", field: "serviceName" },
    { label: "Terminal ID", field: "Terminal id" },
    { label: "Numero op", field: "Numero op." },
    { label: "Receipt Nr", field: "Receipt-Nr" },
    { label: "TID", field: "TID" },
    { label: "Numero di telefono", field: "NUMERO DI TELEFONO" },
    { label: "Importo", field: "Importo" },
    { label: "Date/Ora", field: "Data op." },
  ]

  return (
    <div className="pin-receipt">
      <div className="receipt-info">
        <span className="service-name">
          {JSON.parse(arr.receipt)["serviceName"]}
        </span>
        <img src={`${baseUrlImg}/${arr.logo}`} alt="service logo" />

        {rowLabels.map(({label, field}, index) => (
          <div key={index} className="receipt-row">
            {label}: <span>{JSON.parse(arr.receipt)[field]}</span>
          </div>
        ))}
      </div>

      {barcode !== 1 && (
        <div className="receipt-barcode">
          <img
            className="barcodeModal"
            src={`https://barcode.tec-it.com/barcode.ashx?data=${
              barcode || 0
            }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
            alt=""
          />
        </div>
      )}

      <div className="receipt-description">
        {JSON.parse(arr.receipt)["Description"]}
      </div>
    </div>
  )
}

export default PrintTicketTopUp
