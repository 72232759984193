import React, { useEffect, useState } from "react"
import images from "../../../themes/images"
import SliderComponent from "./SliderComponent"
import ImageUploadComponent from "./ImageUploadComponent"
import { Button, Form, Input, Select, Tabs } from "antd"
import { baseUrlImg } from "../../../config/api"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const { Option } = Select

const ServiziModal = ({
  open,
  onClose,
  openBonusModal,
  selectedCompany,
  getService,
  updateCompany,
}) => {
  const steps = [0, 500, 1000, 1500, 2000, 2500, 3000]
  const [value, setValue] = useState(steps[2])
  const [logo, setLogo] = useState(null)
  const [logoScontrino, setLogoScontrino] = useState(null)
  const [icon, setIcon] = useState(null)
  const [bgImage, setBgImage] = useState(null)
  const [selectedService, setSelectedService] = useState("default")
  const [selectedServiceId, setSelectedServiceId] = useState("default")
  const [formData, setFormData] = useState({
    default: {
      servizio: "",
      commissioni: "",
      provvigioni: "",
      iva: "",
    },
  })
  const [serviceChanges, setServiceChanges] = useState({})
  const [defaultChanges, setDefaultChanges] = useState({})
  const [content, setContent] = useState("")
  const [scontrinoContent, setScontrinoContent] = useState("")
  const [colorBg, setColorBg] = useState("")
  const [colorTesto, setColorTesto] = useState("")

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ align: [] }],
        ["link", "image", "video"],
      ],
    },
  }

  useEffect(() => {
    if (selectedCompany && selectedCompany.services) {
      const initialFormData = {}
      selectedCompany.services.forEach((service) => {
        initialFormData[service.id] = {
          servizio: service.cost || "",
          commissioni: service.commissione || "",
          provvigioni: service.agency_guadagno || "",
          iva: service.iva || "",
        }
      })
      setFormData(initialFormData)
    }
    setLogo(selectedCompany.logo)
    setBgImage(selectedCompany.background)
    setIcon(selectedCompany.icon)
    setLogoScontrino(selectedCompany.scontrino)
    setColorBg(selectedCompany.colorBg)
    setColorTesto(selectedCompany.colorTesto)
    setContent(selectedCompany.content)
  }, [selectedCompany])

  const handleSliderChange = (event) => {
    setValue(event.target.value)
  }

  const handleChangeService = (value) => {
    setSelectedService(value)
    value === "default"
      ? setSelectedServiceId("default")
      : setSelectedServiceId(selectedCompany?.services[value]?.id)
  }

  const handleSubmit = async () => {
    let servicesToSend
    if (Object.keys(defaultChanges).length > 0) {
      servicesToSend = defaultChanges
    } else {
      servicesToSend = serviceChanges
    }

    try {
      await updateCompany(
        selectedCompany.id,
        logo?.id,
        bgImage?.id,
        icon?.id,
        logoScontrino?.id,
        colorBg,
        colorTesto,
        servicesToSend,
        content,
        scontrinoContent
      )
    } catch (error) {
      console.error(error)
    } finally {
      onClose()
      getService()
      setDefaultChanges({})
      setServiceChanges({})
    }
  }

  const handleFieldChange = (serviceId, field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceId]: {
        ...prevFormData[serviceId],
        [field]: value,
      },
    }))
    if (serviceId === "default") {
      setDefaultChanges((prevDefaultChanges) => ({
        ...prevDefaultChanges,
        default: {
          ...prevDefaultChanges.default,
          [field]: value,
        },
      }))
    } else {
      setServiceChanges((prevChanges) => {
        const updatedChanges = { ...prevChanges }
        if (!updatedChanges[serviceId]) {
          updatedChanges[serviceId] = {}
        }
        updatedChanges[serviceId][field] = value
        return updatedChanges
      })
    }
  }

  const handleQuillChange = (value, isScontrino) => {
    isScontrino ? setScontrinoContent(value) : setContent(value)
  }

  const handleImageSelect = (selectedImage, imageType) => {
    if (imageType === "logo") {
      setLogo(selectedImage)
    } else if (imageType === "icon") {
      setIcon(selectedImage)
    } else if (imageType === "logoScontrino") {
      setLogoScontrino(selectedImage)
    } else if (imageType === "bgImage") {
      setBgImage(selectedImage)
    }
  }

  if (!open) return null
  return (
    <div className="Servizi">
      <div className="backDrop" style={{ zIndex: 8 }} onClick={onClose}></div>
      <div className="Servizi--Container">
        <div className="Servizi--Container--Up text-center">
          <div className="Servizi--Container--Up--Title">
            <div className="service-name">{selectedCompany.full_name}</div>
            <div className="d-flex">
              {selectedCompany.active === 1 ? (
                <div className="service-active">
                  ACTIVE <i className="far fa-check-circle"></i>
                </div>
              ) : (
                <div className="service-inactive">
                  INACTIVE <i className="far fa-times-circle"></i>
                </div>
              )}
              <div className="service-settings">
                BONUS <i className="far fa-badge-percent"></i>
              </div>
            </div>
          </div>
          <div className="Servizi--Container--Up--Logo">
            <img
              src={`${baseUrlImg}/${selectedCompany.logo}` || images["placeholder"]}
              alt="service"
            />
          </div>
        </div>
        <div className="Servizi--Container--Down">
          <div className="Servizi--Container--Down--Form">
            <Form>
              <div className="semi">
                <ImageUploadComponent
                  label="Logo"
                  imageType="logo"
                  value={logo}
                  onImageSelect={(e) => handleImageSelect(e, "logo")}
                  selectedCompany={selectedCompany}
                />

                <ImageUploadComponent
                  label="Icon"
                  imageType="icon"
                  value={icon}
                  onImageSelect={(e) => handleImageSelect(e, "icon")}
                  selectedCompany={selectedCompany}
                />
              </div>
              <div className="semi">
                <ImageUploadComponent
                  label="Logo scontrino"
                  value={logoScontrino}
                  imageType="scontrino"
                  onImageSelect={(e) => handleImageSelect(e, "logoScontrino")}
                  selectedCompany={selectedCompany}
                />
                <ImageUploadComponent
                  label="Background"
                  value={bgImage}
                  imageType="background"
                  onImageSelect={(e) => handleImageSelect(e, "bgImage")}
                  selectedCompany={selectedCompany}
                />
              </div>
              <div className="semi">
                <Form.Item name="colorBg" label="Colore background">
                  <Input
                    value={colorBg}
                    className="color-input"
                    onChange={(e) => {
                      setColorBg(e.target.value)
                    }}
                    prefix={
                      <>
                        <input
                          type="color"
                          onChange={(e) => {
                            setColorBg(e.target.value)
                          }}
                          value={colorBg}
                          style={{ width: 30, height: 30 }}
                        />
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item name="colorTesto" label="Colore Testo">
                  <Input
                    value={colorTesto}
                    className="color-input"
                    onChange={(e) => {
                      setColorTesto(e.target.value)
                    }}
                    prefix={
                      <input
                        type="color"
                        onChange={(e) => {
                          setColorTesto(e.target.value)
                        }}
                        value={colorTesto}
                        style={{ width: 30, height: 30 }}
                      />
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item name="selectedService" label="Select Service">
                <Select
                  allowClear
                  showSearch={true}
                  style={{ width: "100%" }}
                  value={selectedService}
                  onChange={(value) => {
                    if (selectedService?.services?.length === 1) {
                      console.log(value)
                    }
                    handleChangeService(value)
                  }}
                >
                  <Option key="default" value="default">
                    Tutti
                  </Option>
                  {selectedCompany?.services?.map((option, i) => (
                    <Option key={option} value={i}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="semi">
                <Form.Item name="servizio" label="Costo Servizio">
                  <Input
                    placeholder="Costo Servizio"
                    value={formData[selectedServiceId]?.servizio}
                    onChange={(e) =>
                      handleFieldChange(
                        selectedServiceId,
                        "servizio",
                        e.target.value
                      )
                    }
                    suffix={
                      <>
                        <i className="far fa-euro-sign"></i>
                        {/* <i className="far fa-percent"></i> */}
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item name="commissioni" label="Commissioni">
                  <Input
                    value={formData[selectedServiceId]?.commissioni}
                    onChange={(e) =>
                      handleFieldChange(
                        selectedServiceId,
                        "commissioni",
                        e.target.value
                      )
                    }
                    placeholder="Commissioni"
                    suffix={
                      <>
                        <i className="far fa-euro-sign"></i>
                        {/* <i className="far fa-percent"></i> */}
                      </>
                    }
                  />
                </Form.Item>
              </div>
              <div className="semi">
                <Form.Item name="provvigioni" label="Provvigioni agenzia">
                  <Input
                    value={formData[selectedServiceId]?.provvigioni}
                    onChange={(e) =>
                      handleFieldChange(
                        selectedServiceId,
                        "provvigioni",
                        e.target.value
                      )
                    }
                    placeholder="Provvigioni agenzia"
                    suffix={
                      <>
                        {/* <i className="far fa-euro-sign"></i> */}
                        <i className="far fa-percent"></i>
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item name="bonus" label="Bonus agenzia">
                  <Input
                    className="bonus-ag"
                    placeholder="Bonus agenzia"
                    suffix={
                      <i
                        className="far fa-cog"
                        onClick={() => {
                          openBonusModal()
                          // onClose()
                        }}
                      />
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="aggio"
                label="Aggio netto senza calcolare l'eventuale bonus agenzia"
              >
                <Input placeholder="Aggio" />
              </Form.Item>
              <div className="semi">
                <Form.Item name="iva" label="IVA" className="iva">
                  <Input
                    placeholder="IVA"
                    value={formData[selectedServiceId]?.iva}
                    onChange={(e) =>
                      handleFieldChange(selectedServiceId, "iva", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="descrizione"
                  label="Descrizione IVA"
                  className="descrizione"
                >
                  <Input placeholder="Descrizione IVA" />
                </Form.Item>
              </div>

              <Form.Item
                name="limita"
                className="slider"
                label="Imposta limite giornaliero per agenzia"
              >
                <SliderComponent
                  value={value}
                  steps={steps}
                  handleSliderChange={handleSliderChange}
                />
              </Form.Item>

              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Guida all’uso" key="1">
                  <ReactQuill
                    value={content}
                    onChange={(e) => handleQuillChange(e, false)}
                    modules={modules}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Informazioni scontrino" key="2">
                  <ReactQuill
                    value={scontrinoContent}
                    onChange={(e) => handleQuillChange(e, true)}
                    modules={modules}
                  />
                </Tabs.TabPane>
              </Tabs>

              <div className="Servizi--Container--Down--Form--buttons">
                <button
                  className="Servizi--Container--Down--Form--buttons--Cancel"
                  onClick={onClose}
                >
                  <i className="fal fa-times"></i>
                  <span>CANCEL</span>
                </button>
                <Button
                  htmlType="submit"
                  className="Servizi--Container--Down--Form--buttons--Conferma"
                  onClick={handleSubmit}
                >
                  <i className="fal fa-check"></i>
                  <span>SAVE</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const FormServiziModal = Form.create()(ServiziModal)

const mapStateToProps = (state) => ({
  selectedCompany: state.main.selectedCompany,
})

export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  FormServiziModal
)
