import React, { Fragment, useMemo, useEffect, useState, useCallback } from "react"
import ReturnFormItem from "./FormHelperComp"
import { onCodeChange } from "utils/HelperFunc"
import QrCodeReader from "shared-components/QrCodeReader"
import { connect } from "react-redux"
import { ImportoFormItem } from "./BolletiniForms"
import BollettinoPrivacyLinks from "./Bolletino/BollettinoPrivacyLinks"
import { AuthActions, MainActions } from "redux-store/models"

const PagoPaMrPay = (props) => {
  const {
    barcodeData,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    screenWidth,
    scannedBarcode
  } = props
  const [qrCodeVis, setQrCodeVis] = useState(false)
  const code = getFieldValue("code") || ""

  useEffect(() => {
    onCodeChange(code, setFieldsValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  // const showFields = getFieldValue("importo") && getFieldValue("ente_creditore") && getFieldValue("codice_aviso");
  const closeQrCode = () => setQrCodeVis(false)
  const openQrCode = useCallback(
    () =>
      (window.location.href = `${window.location.origin}/#/scan-mobile?redirectUrl=${window.location.href}?click=PRDPST,PagoPA`),
    []
  )
  const codeIcon = useMemo(
    () => <i class="fa fa-qrcode " onClick={openQrCode} />,
    [openQrCode]
  )

  return (
    <Fragment>
      {screenWidth <= 991 ? (
        <QrCodeReader visibility={qrCodeVis} close={closeQrCode}>
          {(data) => {
            if (data && data !== "No result" && data !== getFieldValue("code")) {
              setFieldsValue({ code: data })
            }
            data && onCodeChange(code, setFieldsValue)

            return (
              <ReturnFormItem
                className="qrCodeInput"
                descName="Scan Code"
                name="code"
                barcodeData={barcodeData}
                getFieldDecorator={getFieldDecorator}
                icon={codeIcon}
              />
            )
          }}
        </QrCodeReader>
      ) : (
        <ReturnFormItem
          descName="Scan Code"
          name="code"
          barcodeData={barcodeData}
          getFieldDecorator={getFieldDecorator}
          icon={codeIcon}
        />
      )}
      <ReturnFormItem
        descName="Codice avviso"
        name="codice_aviso"
        message="Inserisci Codice Avviso!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        maxLength={18}
        defaultValue={getFieldValue("codice_aviso")}
      />
      <ReturnFormItem
        descName="Codice ente creditore"
        name="ente_creditore"
        message="Inserisci Ente Creditore!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        maxLength={11}
        defaultValue={getFieldValue("ente_creditore")}
      />
      {/* <ReturnFormItem
        descName="Importo"
        name="importo"
        message="Inserisci Importo!"
        barcodeData={barcodeData}
        setFieldsValue={setFieldsValue}
        getFieldDecorator={getFieldDecorator}
        icon={<i class="fas fa-euro-sign"/>}
      /> */}
      <ImportoFormItem {...{ barcodeData, getFieldDecorator, setFieldsValue }} />
      <ReturnFormItem
        descName="Eseguito da"
        name="eseguito_da"
        placeholder="Nome Cognome"
        message="Inserisci Eseguito da!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <BollettinoPrivacyLinks/>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
})
export const PagoPaLeftFormMrPay = connect(mapStateToProps, { ...AuthActions, ...MainActions})(PagoPaMrPay)

export const PagoPaLeftForm = ({ barcodeData, getFieldDecorator }) => (
  <Fragment>
    <ReturnFormItem
      descName="Codice Avviso"
      name="codice_aviso"
      message="Inserisci Codice Avviso!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
    <ReturnFormItem
      descName="CF/P.IVA Ente Creditore"
      name="codice_fiscale_bol"
      message="Inserisci Creditore!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
  </Fragment>
)
