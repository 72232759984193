export { default as Annunci } from "./views/Annunci"
export { default as Dashboard } from "./views/Dashboard"
export { default as CaricaConto } from "./views/Carica-conto"
export { default as Configura } from "./views/Configura"
export { default as UseCode } from "./views/UseCode"
export { default as Transazioni } from "./views/Transazioni"
export { default as Depositi } from "./views/Depositi"
export { default as Messages } from "./views/Messages"

export { default as AnnunciAdmin } from "./views/Annunci-admin"
export { default as Operazioni } from "./views/Operazioni"
export { default as Impostazioni } from "./views/Impostazioni"
export { default as AccountInfo } from "./views/AccountInfo"
export { default as UnderConstruction} from "./views/UnderConstruction"

export { default as Register } from "./views/Register"
export { default as RegisterEndUser } from "./views/RegisterEndUser"
export { default as RegisterAgency } from "./views/RegisterAgency"
export { default as RegisterAgent } from "./views/RegisterAgent"

export { default as Login } from "./views/Login"
export { default as Verify } from "./views/Verify"
export { default as Wallet } from "./views/Wallet"
export { default as QRDesktop } from "./views/QRDesktop"
export { default as PrenotaBolletini} from "./views/PrenotaBolletini"
export {default as Autenticazione} from "./views/Autenticazione"
export { default as Spedizioni} from "./views/Spedizioni"

//          export { default as } from './views/'
export { default as AdminPanelListaMovimenti } from "./views/AdminPanelListaMovimenti"
export { default as AdminPanelAllMovimenti } from "./views/AdminPanelAllMovimenti"
export { default as AdminPanelListaUtenti } from "./views/adminPanelListaUtenti"
export { default as AdminPanelMessaggi} from "./views/AdminPanelMessaggi"
export { default as AdminPanelPrenotazioni } from "./views/AdminPanelPrenotazioni"
export { default as AdminPanelServizi } from "./views/AdminPanelServizi"
export { default as AreaDownload } from "./views/AreaDownload"
export { default as Fatura } from "./views/Fatura"
export { default as CreateSkin } from "./views/CreateSkin"
export { default as FormDetails } from "./views/FormDetails"
export { default as Forms } from "./views/Forms"
export { default as Support } from "./views/Support"
export { default as UnderDevelopment } from "./views/UnderDevelopment"
export { default as Visure } from "./views/Visure"
export { default as VisureDetaggli } from "./views/VisureDetaggli"
export { default as AdminPanelErrorList } from "./views/AdminPanelErrorList"
export { default as MamaneroCaffe } from "./views/MamaneroCaffe"
export { default as SuperAdminRegistraAgenzia} from "./views/SuperAdminRegistraAgenzia"
export { default as SuperAdminRegistraAgente} from "./views/SuperAdminRegistraAgente"
export { default as SuperAdminFatura} from "./views/SuperAdminFatura" 
export { default as SuperAdminConfigura} from "./views/SuperAdminCofigura"
export { default as AdminConfiguraPanel} from "./views/AdminConfiguraPanel"
export { default as AdminPanelDepositi } from "./views/AdminPanelDepositi"
export { default as AdminPanelStatistiche } from "./views/AdminPanelStatistiche"
export { default as AdminPanelCategoryManagement} from "./views/AdminPanelCategoryManagement"
export { default as AdminPanelGallery} from "./views/AdminPanelGallery"
export { default as NewServicesAdmin} from "./views/NewServicesAdmin"