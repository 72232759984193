import React, { Fragment, useEffect, useMemo, useState } from "react"
import ReturnFormItem from "./FormHelperComp"
import images from "themes/images"
import QrCodeReader from "shared-components/QrCodeReader"
import { Select, Radio } from "antd"
import { mrPayScanBarcode } from "../../utils/HelperFunc"
import BollettinoPrivacyLinks from "./Bolletino/BollettinoPrivacyLinks"

const LefFormCommon = (props) => {
  const { service_id, barcodeData, getFieldDecorator, hideCasuale, setFieldsValue } =
    props
return (
    <Fragment>
      {/* <ReturnFormItem
        descName="di Euro €"
        name="importo"
        message="Inserisci Importo!"
        placeholder="00,00"
        maximum={["BOL001", "SBOL001"].includes(service_id) && 250}
        {...{ barcodeData, getFieldDecorator, setFieldsValue }}
      /> */}
      <ImportoFormItem
        {...{ service_id, barcodeData, getFieldDecorator, setFieldsValue }}
      />
      {!hideCasuale && (
        <ReturnFormItem
          descName="Causale"
          name="causale"
          message="Inserisci Causale!"
          {...{ barcodeData, getFieldDecorator }}
        />
      )}
    </Fragment>
  )
}

export const ImportoFormItem = (props) => {
  const { barcodeData, getFieldDecorator, service_id, setFieldsValue } = props
  return (
    <div className="InputItem">
      <span className="desc">
        Importo (max € 1.000,00 in contanti e 2.498,00 con pos)
        <span className="Red">*</span>
      </span>
      <div className="input-container importo-form">
        <div className="importo-euro">
          <div className="icon-container">
            <img src={images.euro_sign} alt="€" />
          </div>

          <ReturnFormItem
            name="importo"
            placeholder="00"
            message="Inserisci Importo!"
            maximum={["BOL001", "SBOL001"].includes(service_id) && 250}
            {...{ barcodeData, getFieldDecorator, setFieldsValue }}
          />
        </div>
        <div className="importo-cent">
          <div className="icon-container">
            <img src={images.cent_sign} alt="₵" />
          </div>
          <ReturnFormItem
            name="importo_cent"
            // defaultValue="00"
            placeholder="00"
            maxLength={2}
            //message="Inserisci Importo!"
            maximum={["BOL001", "SBOL001"].includes(service_id) && 250}
            {...{ barcodeData, getFieldDecorator, setFieldsValue }}
          />
        </div>
      </div>
    </div>
  )
}

const RightFormCommon = ({ getFieldDecorator, barcodeData }) => {
  return (
    <>
      <ReturnFormItem
        descName="Indirizzo"
        name="via_piazza"
        placeholder="Indirizzo"
        message="Inserisci indirizzo!"
        {...{ barcodeData, getFieldDecorator }}
      />
      <ReturnFormItem
        descName="Città"
        name="citta"
        message="Inserisci la tuo citta!"
        {...{ barcodeData, getFieldDecorator }}
      />
    </>
  )
}

export const SepafinBolletiniRightForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  hasCodiceFiscale,
}) => {
  const searchIcon = useMemo(() => <i className="fal fa-search" />, [])
  const questionIcon = useMemo(() => <i className="fal fa-question-circle" />, [])
  const personTypeVal = getFieldValue("person_type")

  return (
    <Fragment>
      <ReturnFormItem
        descName="Persona"
        name="person_type"
        message="Please input your persona tipo!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        defaultValue="F"
        NotInput={
          <Radio.Group>
            <Radio value="F">Fisica</Radio>
            <Radio value="G">Giuridica</Radio>
          </Radio.Group>
        }
      />{" "}
      {personTypeVal === "F" || typeof personTypeVal === "undefined" ? (
        <Fragment>
          <div className="input-container">
            <ReturnFormItem
              descName="Nome"
              name="nome"
              message="Please input your Nome!"
              {...{ barcodeData, getFieldDecorator }}
            />
            <ReturnFormItem
              descName="Cognome"
              name="cognome"
              message="Please input your Cognome!"
              {...{ barcodeData, getFieldDecorator }}
            />
          </div>

          {hasCodiceFiscale && (
            <ReturnFormItem
              descName="Codice Fiscale"
              name="codice_fiscale"
              message="Inserisci Codice Fiscale!"
              {...{ barcodeData, getFieldDecorator }}
              icon={questionIcon}
              tooltip={
                <div>
                  Buongiorno,
                  <br />
                  <br />
                  Per il pagamento del bollettino indirizzato a Compass è necessario
                  inserire in anagrafica dell'esecutore il codice fiscale poichè
                  richiesto dal biller ai fini della corretta riconciliazione.
                  <br />
                  Per il bollettino indirizzato a sky è necessario inserire in
                  causale il codice cliente dato richiesto dal biller per la
                  riconciliazione.
                  <br />
                  <br />
                  Restiamo a disposizione,
                  <br />
                  <br />
                  Cordiali saluti,
                </div>
              }
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <ReturnFormItem
            descName="Denominazione"
            name="denominazione"
            message="Please input your Denominazione!"
            {...{ barcodeData, getFieldDecorator }}
            icon={searchIcon}
          />
          {hasCodiceFiscale && (
            <ReturnFormItem
              descName="Partiva IVA"
              name="partita_iva"
              message="Please input your Partiva IVA!"
              {...{ barcodeData, getFieldDecorator }}
            />
          )}
        </Fragment>
      )}
      <RightFormCommon {...{ barcodeData, getFieldDecorator }} />
      <div className="InputItem">
        {" "}
        <section style={{ fontSize: "12px", fontWeight: "500", paddingTop: "16px" }}>
          Tutti i dati forniti sono trattati nel rispetto della normativa vigente sul
          trattamento dei dati, come riportato nella nostra{" "}
          <a href="#">Privacy Policy</a>.
        </section>
      </div>
    </Fragment>
  )
}

export const SepafinBolletiniLeftForm = ({
  barcodeData,
  getFieldDecorator,
  service_id,
  isSepafin,
  children,
  getFieldValue,
  openCodiceBolInfo,
  setFieldsValue,
  newFields,
  hideCasuale,
  isPrenotaBolletino,
}) => {
  const searchIcon = useMemo(() => <i className="fal fa-search" />, [])
  const questionIcon = useMemo(
    () => <i onClick={openCodiceBolInfo} className="fal fa-question-circle" />,
    [openCodiceBolInfo]
  )
  const service = ["896", "674"].includes(getFieldValue("tipologia"))
    ? "SBOL002"
    : "SBOL001"

  return (
    <Fragment>
      {(isSepafin || !service_id || isPrenotaBolletino || service === "SBOL002") && (
        <ReturnFormItem
          descName="Tipo Bollettino"
          name="tipologia"
          {...{ barcodeData, getFieldDecorator }}
          defaultValue={service_id === "SBOL002" ? "896" : "123"}
          NotInput={
            <Select>
              <Select.Option value={"123"}>Bianco Generico (TD 123)</Select.Option>
              <Select.Option value={"451"}>
                Bianco Personalizzato (TD 451)
              </Select.Option>
              <Select.Option value={"674"}>
                Premarcato Non Fatturatore (TD 674)
              </Select.Option>
              <Select.Option value={"896"}>
                Premarcato Fatturatore (TD 896)
              </Select.Option>
            </Select>
          }
        />
      )}
      <div className="InputItem conto-corrento-container">
        <div className="icon-container">
          <img src={images.euro_icon} alt="€" />
        </div>
        <ReturnFormItem
          descName="Numero Conto Corrente"
          name="numero_conto_corrente"
          message="Inserisci Numero Conto!"
          {...{ barcodeData, getFieldDecorator }}
          icon={searchIcon}
        />
      </div>

      {children && children}
      {service === "SBOL002" && (
        <ReturnFormItem
          descName="Codice Bolletino"
          name="codice_identificativo"
          message="Inserisci Codice Identificativo!"
          {...{ barcodeData, getFieldDecorator }}
          icon={questionIcon}
        />
      )}
      <ImportoFormItem
        {...{ service_id, barcodeData, getFieldDecorator, setFieldsValue }}
      />
      {!hideCasuale && (
        <ReturnFormItem
          descName="Causale"
          name="causale"
          message="Inserisci Causale!"
          {...{ barcodeData, getFieldDecorator }}
        />
      )}
      {(newFields || []).map(({ TagName, TagLabel, Id }) => (
        <ReturnFormItem
          key={Id}
          descName={TagLabel}
          name={TagName || ""}
          message={`Inserisci ${TagName}`}
          {...{ barcodeData, getFieldDecorator }}
        />
      ))}
    </Fragment>
  )
}

export const OldMrPayBolletiniRightForm = ({ barcodeData, getFieldDecorator }) => (
  <Fragment>
    <ReturnFormItem
      descName="Provincia"
      name="provincia"
      message="Inserisci Provincia!"
      {...{ barcodeData, getFieldDecorator }}
    />
    <RightFormCommon {...{ barcodeData, getFieldDecorator }} />
    <ReturnFormItem
      descName="Cap"
      name="cap"
      message="Inserisci Cap!"
      {...{ barcodeData, getFieldDecorator }}
    />
    <BollettinoPrivacyLinks/>
  </Fragment>
)

export const OldMPayBolletiniLeftForm = ({
  barcodeData,
  getFieldDecorator,
  service_id,
  mobile,
  toggleScanner,
  setFieldsValue,
  screenWidth,
  getFieldValue,
}) => {
  const searchIcon = useMemo(() => <i className="fal fa-search" />, [])
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.services
  const code = getFieldValue("code") || ""
  const codeIcon = useMemo(
    () => <i className="fa fa-qrcode " onClick={toggleScanner} />,
    [toggleScanner]
  )
  const [qrCodeVis, setQrCodeVis] = useState(false)

  const closeQrCode = () => setQrCodeVis(false)

  useEffect(() => {
    mrPayScanBarcode(code, setFieldsValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])
  return (
    <Fragment>
      {[pagamentiServices["Paytipper Bill payments"], "3611776","103859", "BOL002"].includes(
        service_id
      ) && (
        <>
          {screenWidth <= 991 ? (
            <QrCodeReader visibility={qrCodeVis} close={closeQrCode}>
              {(data) => {
                if (data && data !== "No result" && data !== getFieldValue("code")) {
                  setFieldsValue({ code: data })
                }
                data && mrPayScanBarcode(code, setFieldsValue)

                return (
                  <ReturnFormItem
                    className="qrCodeInput"
                    descName="Scan Code"
                    name="code"
                    barcodeData={barcodeData}
                    getFieldDecorator={getFieldDecorator}
                    icon={codeIcon}
                  />
                )
              }}
            </QrCodeReader>
          ) : (
            <ReturnFormItem
              descName="Scan Code"
              name="code"
              barcodeData={barcodeData}
              getFieldDecorator={getFieldDecorator}
              icon={codeIcon}
              maxLength={50}
            />
          )}
          {/* <ReturnFormItem
        descName="Scan Code"
        name="code"
        message="Please input your Code!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        icon={codeIcon}
      /> */}
          <ReturnFormItem
            descName="Tipo Bollettino"
            name="tipologia"
            {...{ barcodeData, getFieldDecorator }}
            defaultValue={
              [
                pagamentiServices["Paytipper Bill payments"],
                "3611776",
                "103859",
                "BOL002",
              ].includes(service_id)
                ? "896"
                : "674"
            }
            NotInput={
              <Select>
                <Select.Option value={"896"}>896</Select.Option>
                <Select.Option value={"674"}>674</Select.Option>
              </Select>
            }
          />
        </>
      )}
      <ReturnFormItem
        descName="Numero Conto Corrente"
        name="numero_conto_corrente"
        message="Inserisci Numero Conto Corrente!"
        {...{ barcodeData, getFieldDecorator }}
        icon={searchIcon}
        maxLength={12}
        defaultValue={getFieldValue("numero_conto_corrente")}
      />
      {[pagamentiServices["Paytipper Bill payments"], "3611776","103859", "BOL002"].includes(
        service_id
      ) && (
        <ReturnFormItem
          descName="Codice Identificativo"
          name="codice_identificativo"
          message="Inserisci Codice Identificativo!"
          maxLength={18}
          defaultValue={getFieldValue("codice_identificativo")}
          {...{ barcodeData, getFieldDecorator }}
        />
      )}
      <LefFormCommon {...{ service_id, barcodeData, getFieldDecorator }} />
      {mobile && <div className="DatiPagatori">DATI PAGATORE</div>}
      <ReturnFormItem
        descName="Intestato a"
        name="intestato_a"
        message="Inserisci Intestato a!"
        {...{ barcodeData, getFieldDecorator }}
      />
      <ReturnFormItem
        descName="Eseguito Da"
        name="eseguito_da"
        message="Inserisci Eseguito da!"
        {...{ barcodeData, getFieldDecorator }}
      />
    </Fragment>
  )
}

export const MrPayBolletiniRightForm = (props) => {
  const {
    barcodeData,
    setFieldsValue,
    getFieldValue,
    service_id,
    getFieldDecorator,
    BookingBoletinoInfo,
    // pagamentiServices
  } = props

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.services

  const { cognome, conto_corrente } = BookingBoletinoInfo || {}
  useEffect(() => {
    const { cognome, conto_corrente, causale } = BookingBoletinoInfo || {}
    setFieldsValue({ cognome, conto_corrente, causale })
  }, [BookingBoletinoInfo, setFieldsValue])
  if (
    !(
      service_id === pagamentiServices["Paytipper Bill payments no edit Miniprogram"]
    ) &&
    // !(service_id === "110067") &&
    !(service_id === "BOL001")
  )
    return <OldMrPayBolletiniRightForm {...props} />
  return (
    <Fragment>
      {!getFieldValue("id_prenotazione") || !BookingBoletinoInfo ? (
        <><div className="display-flex margin-top-20  align-children-center justify-content-between">
          <div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.paytipper.com/booking/"
              >
                Prenota bollettino
              </a>
            </div>
            ID AGENZIA: 16386
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bpoint.store/wp-content/uploads/2022/06/prenotazione_bollettini_bianchi.pdf"
          >
            Guida come prenotare
          </a>
        </div>
       </>
    
      ) : (
        <Fragment>
          <ReturnFormItem
            descName="Cognome"
            name="cognome"
            value={cognome}
            disabled={true}
            message="Inserisci Cognome!"
            {...{ barcodeData, getFieldDecorator }}
          />
          <ReturnFormItem
            descName="Conto Corrento"
            name="conto_corrente"
            value={conto_corrente}
            disabled={true}
            message="Inserisci Conto Corrento!"
            {...{ barcodeData, getFieldDecorator }}
          />
          <ReturnFormItem
            descName="Causale"
            disabled={true}
            name="causale"
            message="Inserisci Causale!"
            {...{ barcodeData, getFieldDecorator }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export const MPayBolletiniLeftForm = (props) => {
  const {
    barcodeData,
    setFieldsValue,
    getFieldValue,
    service_id,
    getFieldDecorator,
    getBookingsBolMr,
    BookingBoletinoInfo,
    // pagamentiServices,
  } = props
  const searchIcon = useMemo(() => {
    return (
      <i
        className="fal fa-search"
        onClick={() => {
          getBookingsBolMr(
            getFieldValue("id_prenotazione"),
            service_id,
            accountData.profile.id
          )
        }}
      />
    )
  }, [getBookingsBolMr, getFieldValue])
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.services
  useEffect(() => {
    const { nome, importo, data_prenotazione } = BookingBoletinoInfo || {}
    setFieldsValue({
      nome,
      importo:
        importo !== undefined
          ? new Intl.NumberFormat("it-IT").format(parseFloat(importo.toString()))
          : "",
      //importo: new Intl.NumberFormat("it-IT").format(parseFloat((importo || "").toString())),
      data_prenotazione,
    })
  }, [BookingBoletinoInfo, setFieldsValue])
  if (
    !(
      service_id === pagamentiServices["Paytipper Bill payments no edit Miniprogram"]
    ) &&
    // !(service_id === "110067") &&
    !(service_id === "BOL001")
  )
    return <OldMPayBolletiniLeftForm {...props} />

  return (
    <Fragment>
      <ReturnFormItem
        descName="ID Prenotazione *"
        name="id_prenotazione"
        message="Inserisci Id Prenotazione!"
        {...{ barcodeData, getFieldDecorator }}
        icon={searchIcon}
      />
       <BollettinoPrivacyLinks/>

      {getFieldValue("id_prenotazione") && BookingBoletinoInfo && (
        <Fragment>
          <ReturnFormItem
            descName="Nome"
            disabled={true}
            name="nome"
            message="Inserisci Nome!"
            {...{ barcodeData, getFieldDecorator }}
          />
          <ReturnFormItem
            descName="Importo"
            disabled={true}
            name="importo"
            message="Inserisci Importo!"
            {...{ barcodeData, getFieldDecorator, setFieldsValue }}
          />
          <ReturnFormItem
            descName="Data Prenotazione"
            disabled={true}
            name="data_prenotazione"
            message="Inserisci Data Prenotazione!"
            {...{ barcodeData, getFieldDecorator }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
