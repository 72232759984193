import React, { useState } from "react";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import CommonBolletinoWrapper from "./CommonBolletinoWrapper";
import moment from "moment";
import {
  F24LeftForm,
  F24RightForm,
  LineTable,
  LastPartForm,
  SeperateInputs,
  returnCodice,
  setValues,
  clearLineTables,
  returnMotivoDelPagamentoList,
} from "shared-components/ModulePopUp/F24Forms";
import images from "themes/images";
import { notification } from "antd";

const handleSubmit = (e, form, setBokingSep, nrOfRows, CodiceUfficio, CodiceAtto) => {
  e.preventDefault();
  form.validateFieldsAndScroll((err, values) => {
    let coUf = returnCodice(3, "codice_ufficio");
    let coAt = returnCodice(11, "codice_atto");
    if (!err && coUf && coAt && coUf.length >= 3 && coAt >= 11) {
      setBokingSep(
        "SPAGF24",
        values?.person_type,
        values?.via_piazza,
        values?.citta,
        values?.provincia,
        values?.gender,
        values?.vat,
        coUf ? coUf : CodiceUfficio,
        coAt ? coAt : CodiceAtto,
        moment(values?.data_pagamento)?.format("YYYY-MM-DD")
          ? moment(values?.data_pagamento).format("YYYY-MM-DD")
          : null,
        document.querySelector("#saldo_finale")?.value ? document.querySelector("#saldo_finale")?.value : null,
        JSON.stringify(returnMotivoDelPagamentoList(form.getFieldValue, nrOfRows)),
        values?.nome,
        values?.cognome,
        values?.codice_fiscale,
        values?.denominazione,
        values?.partita_iva,
        values?.email,
        values?.phone_number,
        values?.codice_fiscale_optional,
        form.resetFields,
        this.props.accountInfo.profile.username
      );
    } else {
      notification["error"]({
        message: "Ops...",
        description: "Controlla le tue caselle vuote o accetta le condizioni",
      });
    }
  });
};
const F24 = ({ setService, activeService, setBokingSep, allFavServices }) => {
  const commonBolletinoProps= {
    title: (
      <div>
        <i className="fal fa-receipt"></i> <span>F24</span>
      </div>
    ),
    favService: "BOLL",
    image: (
      <div className="bolletini-beforeh">
        <div className="abPart">
          <span>Mod.</span>
          <span>F24</span>
        </div>
        <div>
          <img src={images["agenzia_entrata"]} alt="" />
        </div>
        <div>
          <span>Modello di pagamento unificato</span>
          <span>Per l’accredito alla tesoreria competente</span>
        </div>
      </div>
    ),
  };
  const [CodiceUfficio, setCodiceUfficio] = useState("");
  const [classNameCodiceUfficio, setClassNameCodiceUfficio] = useState("");
  const [CodiceAtto, setCodiceAtto] = useState("");
  const [classNameCodiceAtto, setClassNameCodiceAtto] = useState("");
  const [coFiAtUfVis, setCoFiAtUfVis] = useState(false);
  const [moDePaVis, setMoDePaVis] = useState(false);
  const [pagamentoRows, setPagamentoRows] = useState(1);
  const [datPagVis, setDatPagVis] = useState("");
  // useEffect(() => {
  //   const element = document.querySelector("#saldo_finale")
  //   let saldo = calculateSaldoVal(pagamentoRows, form.getFieldValue)
  //   if (saldo) {
  //     element.value = saldo
  //   }
  // })
  //   useEffect(() => {
  //     if (Object.values(bolletiniBianchi).length > 0)
  //       notification[bolletiniBianchi.errors ? "error" : "success"]({
  //         message: bolletiniBianchi.message,
  //         description: Object.values(bolletiniBianchi.errors || {}),
  //       });
  //   }, [bolletiniBianchi]);
  //   useEffect(() => {
  //     if (bolletiniLoading) {
  //       notification["info"]({
  //         message: "Transazione di caricamento...",
  //       });
  //     }
  //   }, [bolletiniLoading]);
  return (
    <CommonBolletinoWrapper
      commonBolletinoProps={commonBolletinoProps}
      className="f24Mob"
      pagamentoRows={pagamentoRows}
      handleSubmit={(e, form) => {
        handleSubmit(e, form, setBokingSep, pagamentoRows, CodiceUfficio, CodiceAtto);
      }}
      {...{ activeService, setService, allFavServices }}
    >
      {(form, barcode) => (
        <React.Fragment>
          <div
            onClick={() => {
              setDatPagVis(!datPagVis);
            }}
            className="bolletini--subh"
          >
            <span>Dati Pagatore</span>
            <span>
              <i className={`fal fa-chevron-${!datPagVis ? "down" : "up"}`} />
            </span>
          </div>
          <div className={`DatPag ${datPagVis ? "" : "none"}`}>
            <F24LeftForm
              barcodeData={barcode}
              mobile
              getFieldDecorator={form.getFieldDecorator}
              getFieldValue={form.getFieldValue}
            />
            <F24RightForm barcodeData={barcode} getFieldDecorator={form.getFieldDecorator} />
          </div>

          <div className="SezErrar">
            <div
              className="Vis"
              onClick={() => {
                setCoFiAtUfVis(!coFiAtUfVis);
              }}
            >
              <span>Sezione erario ed altro</span>
              <span>
                <i className={`fal fa-chevron-${!coFiAtUfVis ? "down" : "up"}`} />
              </span>
            </div>
            <div className={`Inputs ${coFiAtUfVis ? "" : "none"}`}>
              <span>
                <div>Codice ufficio</div>

                <SeperateInputs
                  number={3}
                  word="codice_ufficio"
                  setStateValue={(value) => {
                    setCodiceUfficio(value);
                  }}
                  setValues={setValues}
                  returnCodice={returnCodice}
                  setStateClass={(value) => {
                    setClassNameCodiceUfficio(value);
                  }}
                  classNameVal={classNameCodiceUfficio}
                />
              </span>
              <span>
                <div>Codice atto</div>
                <SeperateInputs
                  number={11}
                  setValues={setValues}
                  word="codice_atto"
                  setStateValue={(value) => {
                    setCodiceAtto({ codice_atto: value });
                  }}
                  returnCodice={returnCodice}
                  setStateClass={(value) => {
                    setClassNameCodiceAtto({ classNameAtto: value });
                  }}
                  classNameVal={classNameCodiceAtto}
                />
              </span>
            </div>
          </div>
          <div
            className="Vis"
            onClick={() => {
              setMoDePaVis(!moDePaVis);
            }}
          >
            <span>Motivo del pagamento</span>
            <span>
              <i className={`fal fa-chevron-${!moDePaVis ? "down" : "up"}`} />
            </span>
          </div>
          <div className={`MotivoPag ${!moDePaVis ? "none" : ""}`}>
            <div className="Table">
              <div className="RowContainer">
                {[...new Array(pagamentoRows)].map((item, index) => (
                  <div className="Table--Row" key={index}>
                    <LineTable id={index} mobile barcodeData={barcode} getFieldDecorator={form.getFieldDecorator} />
                  </div>
                ))}
              </div>
            </div>
            <div className="TableActions">
              <div
                onClick={() => {
                  clearLineTables(pagamentoRows, form.setFieldsValue);
                }}
              >
                <i className="fal fa-trash-alt" />
                <span>Svuota tutti i campi</span>
              </div>
              <div
                onClick={() => {
                  setPagamentoRows(pagamentoRows + 1);
                }}
              >
                <span>Aggiungi Riga</span>
                <i className="fal fa-plus" />
              </div>
            </div>
          </div>
          <div className="Data">
            <div className="Head">Estremi Del Versamento</div>
            <div className="Form">
              <LastPartForm barcodeData={barcode} getFieldDecorator={form.getFieldDecorator} />
            </div>
          </div>
        </React.Fragment>
      )}
    </CommonBolletinoWrapper>
  );
};

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo
});

export default connect(mapsStateToProps, AuthActions)(F24);