import React from "react"
import AuthActions from "redux-store/models/auth"
import { Time, MainAdminCarousel } from "shared-components"
import { connect } from "react-redux"
const TopHeader = ({ accountInfo, logOut, role }) => {
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"
  return (
    <div className="TopHeader">
      <Time />
      {role !== "super_admin" && role !== "agent" && (
        <MainAdminCarousel accountInfo={accountInfo} />
      )}
      <span className="creditoD" style={{ marginLeft: 10 }}>
        Credito : {accountInfo?.profile?.wallet}€{" "}
      </span>
      {isEPay && (
        <span className="creditoD">
          Profit :{" "}
          {parseFloat(accountInfo?.profile?.profit_wallet)
            .toString()
            .replace(/(\.0+|0+)$/, "")
            .replace(".", ",")}
          €{" "}
        </span>
      )}
      <span
        className="logOutBtn"
        onClick={() => {
          logOut()
          window.location.hash = "login"
        }}
      >
        Log OUT
      </span>
    </div>
  )
}

const mapStatToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
})
export default connect(mapStatToProps, AuthActions)(TopHeader)
