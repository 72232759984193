import React from "react"
import { Pie } from "@ant-design/plots"
import { connect } from "react-redux"
import { AuthActions } from "redux-store/models"
import { Card, Icon } from "antd"

const DonutStatistiche = (props) => {
  const { latestServiceStatistic } = props
  const totalSum =
    latestServiceStatistic &&
    latestServiceStatistic?.payments &&
    Object.values(latestServiceStatistic?.payments).reduce(
      (sum, payment) => sum + payment?.sum,
      0
    )
  const transformedData =
    latestServiceStatistic &&
    latestServiceStatistic.payments &&
    Object.entries(latestServiceStatistic.payments)?.map(([type, data]) => ({
      type,
      value: parseFloat(data?.percentage),
    }))

  const config = {
    appendPadding: 10,
    data: transformedData !== undefined ? transformedData : [],
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.7,
    label: false,
    tooltip: {
      formatter: (datum) => {
        const percentage = datum.value.toFixed(2)
        return {
          name: datum.type,
          value: `${percentage}%`,
        }
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "22px",
        },
        content: `Totale: €${totalSum?.toFixed(2)}`,
      },
    },
  }

  return (
    <div className="donut-statistiche">
      <Card
        title="Percentuali sul totale"
        bordered={false}
        extra={<Icon type="ellipsis" key="ellipsis" />}
      >
        {" "}
        <Pie {...config} />
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  latestServiceStatistic: state.auth.latestServiceStatistic,
})

export default connect(mapStateToProps, AuthActions)(DonutStatistiche)
