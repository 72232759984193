import React, { useEffect, useState } from "react"
import MySpan from "./MySpan"
import { Radio } from "antd"
import { connect } from "react-redux"
import { AuthActions } from "redux-store/models"

function NewStep2(props) {
  const { addEditSkin, addEditSkinDetails } = props
  const [step2, setStep2] = useState({
    bank_name: "",
    account_name: "",
    iban: "",
    rent_payment_type: "",
    agency_rent: "",
    perc_skinguadag_fromagguadag: "",
    perc_agguadag_total: "",
    rent_payment_method: "",
    skin_rent: "",
    minimum_agencies: "",
  })

  const getErrorMessage = (field) => {
    if (addEditSkin?.errors && addEditSkin?.errors[field]) {
      return addEditSkin?.errors[field][0]
    }
    return ""
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const newStep2 = { ...step2, [name]: value }
    setStep2(newStep2)
    addEditSkinDetails({
      ...addEditSkin,
      step2: newStep2,
    })
  }

  const validateFields = () => {
    const requiredFields = [
      "bank_name",
      "account_name",
      "iban",
      "rent_payment_type",
      "agency_rent",
      "perc_skinguadag_fromagguadag",
      "perc_agguadag_total",
      "rent_payment_method",
    ]
    const newErrors = {}
    let hasError = false

    requiredFields.forEach((field) => {
      if (!step2[field]) {
        newErrors[field] = ["This field is required."]
        hasError = true
      }
    })

    if (step2.rent_payment_method === "Pagamento Fisso" && !step2.skin_rent) {
      newErrors["skin_rent"] = ["This field is required."]
      hasError = true
    }

    if (
      step2.rent_payment_method === "Minimo Garantito" &&
      !step2.minimum_agencies
    ) {
      newErrors["minimum_agencies"] = ["This field is required."]
      hasError = true
    }

    return { newErrors, hasError }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { newErrors, hasError } = validateFields()

    if (hasError) {
      addEditSkinDetails({
        ...addEditSkin,
        errors: newErrors,
      })
    } else {
      addEditSkinDetails({
        ...addEditSkin,
        step2: step2,
        skinPannel: true,
        stepNumber: addEditSkin?.stepNumber + 1,
        errors: {},
      })
    }
  }

  const handleFormChange = (event, index) => {
    let data = [...step2.rent_payment_method]
    data[index][event.target.name] = event.target.value
    setStep2((prevStep2) => ({
      ...prevStep2,
      rent_payment_method: data,
    }))
  }

  const addFields = () => {
    let object = {
      from: "",
      to: "",
      percentage: "",
    }
    setStep2((prevStep2) => ({
      ...prevStep2,
      rent_payment_method: [...step2.rent_payment_method, object],
    }))
  }

  const removeFields = (index) => {
    let data = [...step2.rent_payment_method]
    data.splice(index, 1)
    setStep2((prevStep2) => ({
      ...prevStep2,
      rent_payment_method: data,
    }))
  }

  useEffect(() => {
    const skinDetails = addEditSkin?.skinDetails
    if (skinDetails && Object.keys(skinDetails).length > 0) {
      console.log("test")
      setStep2((prevStep2) => ({
        ...prevStep2,
        bank_name: skinDetails.extra?.bank_name || prevStep2.bank_name,
        account_name: skinDetails.extra?.account_name || prevStep2.account_name,
        iban: skinDetails.extra?.iban || prevStep2.iban,
        rent_payment_type:
          skinDetails.rent_payment_type || prevStep2.rent_payment_type,
        agency_rent: skinDetails.agency_rent || prevStep2.agency_rent,
        perc_skinguadag_fromagguadag:
          skinDetails.perc_skinguadag_fromagguadag ||
          prevStep2.perc_skinguadag_fromagguadag,
        perc_agguadag_total:
          skinDetails.perc_agguadag_total || prevStep2.perc_agguadag_total,
        rent_payment_method:
          skinDetails.rent_payment_method || prevStep2.rent_payment_method,
        skin_rent: skinDetails.skin_rent || prevStep2.skin_rent,
        minimum_agencies: skinDetails.minimum_agencies || prevStep2.minimum_agencies,
      }))
    }
    if (addEditSkin?.step2) {
      setStep2((prevStep2) => ({
        ...prevStep2,
        ...addEditSkin.step2,
      }))
    }
  }, [addEditSkin])

  return (
    <div className="AdminLogin--Step1">
      <div className="AdminLogin--Step1--Step2">
        <i
          className="fal fa-long-arrow-left"
          onClick={() => {
            addEditSkinDetails({
              ...addEditSkin,
              step2: step2,
              skinId: addEditSkin?.skinId,
              skinName: addEditSkin?.skinName,
              skinPannel: true,
              stepNumber: addEditSkin?.stepNumber - 1,
            })
          }}
        ></i>
        <div className="AdminLogin--Step1--Right">
          <div className="AdminLogin--Step1--Right--Important">
            <h1>PAGAMENTO</h1>
            <MySpan
              title="Nome Banca"
              iconClass="fal fa-university"
              handleChange={handleChange}
              name="bank_name"
              error={getErrorMessage("bank_name")}
              defaultValue={step2.bank_name}
            />
            <MySpan
              title="Societa Beneficiaria"
              iconClass="fal fa-university"
              handleChange={handleChange}
              name="account_name"
              error={getErrorMessage("account_name")}
              defaultValue={step2.account_name}
            />
            <MySpan
              title="IBAN"
              iconClass="fal fa-university"
              name="iban"
              handleChange={handleChange}
              defaultValue={step2.iban}
              error={getErrorMessage("iban")}
            />
          </div>
          <div className="AdminLogin--Step1--Right--Important">
            <h1>INFORMAZIONI SULL'AFFITTO</h1>
            <div>
              <span>
                <select
                  className="spanField"
                  style={{
                    padding: "0px 10px",
                    borderColor: getErrorMessage("rent_payment_type") && "red",
                  }}
                  onChange={handleChange}
                  name="rent_payment_type"
                  defaultValue={step2.rent_payment_type}
                >
                  <option value="Tipo di Affitto">Tipo di Affitto</option>
                  <option value="Mensile">Mensile</option>
                  <option value="Annuale">Annuale</option>
                </select>
              </span>
              {getErrorMessage("rent_payment_type") && (
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: 12 }}
                >
                  {getErrorMessage("rent_payment_type")}
                </div>
              )}
            </div>

            <MySpan
              title="Canone Mensile Agenzie"
              iconClass="fal fa-money-bill"
              handleChange={handleChange}
              name="agency_rent"
              defaultValue={step2.agency_rent}
              error={getErrorMessage("agency_rent")}
            />
          </div>
          <div className="AdminLogin--Step1--Right--Important">
            <h1>BENEFICI PERCENTUALI</h1>
            <MySpan
              title="Agenzia Percentuale Predefinita"
              iconClass="fal fa-store"
              handleChange={handleChange}
              defaultValue={step2.perc_skinguadag_fromagguadag}
              name="perc_skinguadag_fromagguadag"
              error={getErrorMessage("perc_skinguadag_fromagguadag")}
            />
            <MySpan
              title="Percentuale di Agende dell'Agenzia Guadagno"
              iconClass="fal fa-user-alt"
              handleChange={handleChange}
              name="perc_agguadag_total"
              defaultValue={step2.perc_agguadag_total}
              error={getErrorMessage("perc_agguadag_total")}
            />
          </div>
          <button
            onClick={() => {
              addEditSkinDetails({
                ...addEditSkin,
                step2: step2,
                skinId: addEditSkin?.skinId,
                skinName: addEditSkin?.skinName,
                skinPannel: true,
                stepNumber: addEditSkin?.stepNumber - 1,
              })
            }}
          >
            TORNA INDIETRO
          </button>
        </div>
        <div className="AdminLogin--Step1--Left">
          <div className="AdminLogin--Step1--Left--Affitto">
            <h1>METODO DI AFFITTO</h1>
            <span
              style={{ paddingLeft: "0px", height: "fit-content", margin: "10px 0" }}
            >
              <Radio.Group
                name="rent_payment_method"
                style={{ height: "fit-content", margin: "10px 0" }}
                onChange={handleChange}
                value={step2.rent_payment_method}
              >
                <Radio value={"Pagamento Fisso"}>Pagamento Fisso</Radio>
                <Radio value={"Minimo Garantito"}>Minimo Garantito</Radio>
                <Radio value={"Affito d'agenzia"}>Affitto d'agenzia</Radio>
              </Radio.Group>
            </span>
            {step2.rent_payment_method === "Pagamento Fisso" && (
              <MySpan
                title="Affitto della skin"
                iconClass="fal fa-money-bill"
                handleChange={handleChange}
                defaultValue={step2.skin_rent}
                name="skin_rent"
                error={getErrorMessage("skin_rent")}
              />
            )}

            {step2.rent_payment_method === "Minimo Garantito" && (
              <div>
                <MySpan
                  title="Agenzia minima"
                  iconClass="fal fa-store"
                  handleChange={handleChange}
                  defaultValue={step2.minimum_agencies}
                  name="minimum_agencies"
                  error={getErrorMessage("minimum_agencies")}
                />
                <h1 style={{ marginTop: "10px" }}>AGGIUNGI INTERVALLO</h1>
                {Array.isArray(step2?.rent_payment_method) && step2?.rent_payment_method?.length > 0 &&
                  step2?.rent_payment_method?.map((form, index) => {
                    return (
                      <div key={index} className="rangeList">
                        <input
                          name="from"
                          type="number"
                          placeholder="Da"
                          onChange={(event) => handleFormChange(event, index)}
                          defaultValue={form.from}
                        />
                        <input
                          name="to"
                          placeholder="A"
                          type="number"
                          onChange={(event) => handleFormChange(event, index)}
                          defaultValue={form.to}
                        />
                        <input
                          name="percentage"
                          placeholder="Percentuale"
                          type="number"
                          onChange={(event) => handleFormChange(event, index)}
                          defaultValue={form.percentage}
                        />
                        <i
                          onClick={() => removeFields(index)}
                          className="fa fa-trash"
                        />
                      </div>
                    )
                  })}
                <button onClick={addFields}>Aggiungi nuovo intervallo</button>
              </div>
            )}
          </div>
          <button onClick={handleSubmit}>PASSO SUCCESSIVO</button>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  addEditSkin: state.auth.addEditSkin,
  newSkinId: state.auth.newSkinId,
  registerSkin: state.auth.registerSkin,
})

export default connect(mapsStateToProps, AuthActions)(NewStep2)
