import React, { useState } from "react"
import images from "themes/images"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import { Form } from "antd"
import "./contiGiocoModal.css"
import { useEffect } from "react"

const ContiGiocoModal = ({
  open,
  onClose,
  contiAccount,
  service,
  setNewContiAccount,
  newContiStatus,
}) => {
  const [newUsername, setNewUsername] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const contiAccountCopy =contiAccount && JSON.parse(JSON.stringify(contiAccount))

  useEffect(() => {
    if (newContiStatus === 200) {
      onClose()
    }
  }, [newContiStatus, onClose])

  if (!open) return null
  return (
    <div className="ContiGioco">
      <div className="backDrop" style={{ zIndex: 8 }} onClick={onClose}></div>
      <div className="ContiGioco--Container">
        <div className="ContiGioco--Container--Up text-center">
          <div className="ContiGioco--Container--Up--Logo">
            <img src={images[service.name] || images["placeholder"]} alt="service" />
          </div>
          <div className="ContiGioco--Container--Up--Description">
            Cambio Password
          </div>
        </div>
        <div className="ContiGioco--Container--Down">
          <div className="ContiGioco--Container--Down--Form">
            <Form className="login-form">
              <Form.Item
                name="stateEnum"
                label="Username"
                rules={[{ required: true, message: "Please input your Username!" }]}
              >
                <input
                  type="text"
                  placeholder="Username"
                  onChange={(e) => {
                    setNewUsername(e.target.value)
                  }}
                  defaultValue={contiAccountCopy?.conti_username}
                />
              </Form.Item>
              <Form.Item
                name="stateEnum"
                label="Password"
                rules={[{ required: true, message: "Please input your Password!" }]}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => {
                    setNewPassword(e.target.value)
                  }}
                  defaultValue={contiAccountCopy?.conti_password}
                  autoComplete="new-password"
                />
                <i
                  className={showPassword ? "fal fa-eye" : "fal fa-eye-slash"}
                  onClick={() => setShowPassword((prevState) => !prevState)}
                ></i>
              </Form.Item>
            </Form>
            <div className="ContiGioco--Container--Down--Form--buttons">
              <button
                className="ContiGioco--Container--Down--Form--buttons--Cancel"
                onClick={onClose}
              >
                <i className="fal fa-times"></i>
                <span>CANCEL</span>
              </button>
              <button
                className="ContiGioco--Container--Down--Form--buttons--Conferma"
                onClick={async () => {
                  await setNewContiAccount(service.service_id, newUsername, newPassword)
                }}
              >
                <i className="fal fa-check"></i>
                <span>SAVE</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  contiAccount: state.auth.contiAccount,
  newContiStatus: state.auth.newContiStatus,
})
export default connect(mapStateToProps, AuthActions)(ContiGiocoModal)
