import React from "react";
import AdminPanelDom from "../domains/adminPanel/adminPanelDom";
import { connect } from "react-redux";
import RegisterAgency from "./RegisterAgency";
class SuperAdminRegistraAgenzia extends React.Component {
  render() {
    return (
      <AdminPanelDom
     component={()=>
          <RegisterAgency/>
        }
        {...this.props}
      />
    );
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
});
export default connect(mapStatToProps, null)(SuperAdminRegistraAgenzia);