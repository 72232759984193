import React from "react"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import "./adminLeftForm.css"
import { connect } from "react-redux"
import { get } from "lodash"
import { Menu } from "antd"
import Chat from "shared-components/Chat/Chat"

class AdminLeftForm extends React.Component {
  state = {
    statModal: false,
    wallModal: false,
    ultModal: false,
    activeAdd: { id: 0 },
    collapsed: false,
    selectedKeys:
      this.props.activeSkinId != -1 ? [this.props.activeSkinId.toString()] : ["1"],
  }

  async componentDidMount() {
    const { Statistiche, TrCoPro, leUltimeTransazioniDet } = this.props

    this.props.editStatModal({
      visibility: false,
      data: {
        graphData: Statistiche,
        Tranzacioni: TrCoPro?.importo,
        Commisione: TrCoPro?.commissione,
        Proviggioni: TrCoPro?.proviggioni,
        Aggio: TrCoPro?.system_aggio,
      },
    })

    this.props.editUltModal({
      visibility: false,
      data: {
        leUltimeTransazioniDet: leUltimeTransazioniDet,
      },
    })

    this.updateSelectedKeys()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.activeSkinId !== prevProps.activeSkinId) {
      const { Statistiche, TrCoPro, leUltimeTransazioniDet } = this.props

      this.props.editStatModal({
        visibility: false,
        data: {
          graphData: Statistiche,
          Tranzacioni: TrCoPro?.importo,
          Commisione: TrCoPro?.commissione,
          Proviggioni: TrCoPro?.proviggioni,
          Aggio: TrCoPro?.system_aggio,
        },
      })

      this.props.editUltModal({
        visibility: false,
        data: {
          leUltimeTransazioniDet: leUltimeTransazioniDet,
        },
      })

      this.updateSelectedKeys()
    }
  }

  updateSelectedKeys = () => {
    const { setActiveSkinName } = this.props
    const skin = this.props.skinList.find(
      (skin) => skin.id === this.props.activeSkinId
    )

    const activeSkinName = skin ? skin.username : ""
    this.setState({
      selectedKeys:
        this.props.activeSkinId !== -1
          ? [this.props.activeSkinId.toString()]
          : ["-1"],
    })

    setActiveSkinName(activeSkinName)
  }

  CloseWindowOnClick = () => {
    if (this.props.screenWidth <= 550) {
      this.props.setVisible(!this.props.visible)
    }
  }
  returnIcon = (id) => {
    var skinId = id === 8 ? 6 : id === 51 ? 7 : id
    var url = ""
    try {
      url = require(`../../../assets/images${skinId}/favicon-32x32.svg`)
    } catch (err) {
      url = require(`../../../assets/images${1}/favicon-32x32.svg`)
    }
    return url
  }

  nuovoBillerButton = () => {
    return (
      <div
        className="AdminLeftForm--LastBox--Box biller"
        onClick={() => this.props.toggleNuovoBiller(true)}
      >
        <i className="fal fa-address-card"></i>
        <span>Aggiungi biller</span>
      </div>
    )
  }

  render() {
    const {
      setActiveSkinId,
      leUltimeTransazioniDet,
      editDepModal,
      editStatModal,
      editUltModal,
      skinList,
      activeSkinId,
      accountInfo,
      activeSkinName,
      setActiveSkinName,
    } = this.props

    const role = get(accountInfo, "profile.role.name")
    return (
      <div className="AdminLeftForm">
        <div
          className={
            role !== "main_admin"
              ? "AdminLeftForm--FirstBox super-admin"
              : "AdminLeftForm--FirstBox"
          }
        >
          {/* MAIN ADMIN MENU */}
          {role !== "super_admin" && role !== "agent" && role !== "support" && (
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={this.state.selectedKeys}
              defaultOpenKeys={["1"]}
            >
              <Menu.SubMenu
                key={1}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-address-book" />
                      <span>SKINS</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-address-book" />
                    </span>
                  )
                }
                onTitleClick={() => {
                  setActiveSkinId(-1)
                  this.setState({ selectedKeys: [""] })
                }}
              >
                {role !== "super_admin" &&
                  role !== "agent" &&
                  skinList &&
                  Array.isArray(skinList) &&
                  skinList
                    .filter((skin) => skin.status === 1)
                    .map((skin) => (
                      <Menu.Item
                        key={skin.id}
                        onClick={(e) => {
                          setActiveSkinId(activeSkinId === skin.id ? -1 : skin.id)
                          setActiveSkinName(
                            activeSkinName === skin.username ? "" : skin.username
                          )
                          this.state.selectedKeys[0] == [e.key]
                            ? this.setState({ selectedKeys: [""] })
                            : this.setState({ selectedKeys: [e.key] })
                          this.CloseWindowOnClick()
                        }}
                      >
                        <div className="AdminLeftForm--FirstBox--Box--Skinsvg">
                          {" "}
                          <img src={this.returnIcon(skin.id)} alt="" />
                          <span>{skin.username.toUpperCase()}</span>
                        </div>
                      </Menu.Item>
                    ))}
              </Menu.SubMenu>
              <Menu.SubMenu
                onClick={(e) => {
                  this.state.selectedKeys[0] == [e.key]
                    ? this.setState({ selectedKeys: [""] })
                    : this.setState({ selectedKeys: [e.key] })
                }}
                key={2}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-shopping-cart" />
                      <span>SHOP</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-shopping-cart" />
                    </span>
                  )
                }
              >
                <Menu.Item className="menu-item">
                  <i className="fal fa-shopping-bag" />
                  <span>Prodotti</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    this.props.history.push("/back-office/ordine-shop")
                  }}
                >
                  <i className="fal fa-receipt" />
                  <span>Ordini</span>
                </Menu.Item>
                <Menu.Item className="menu-item">
                  <i className="fal fa-user" />
                  <span> Clients</span>
                </Menu.Item>
                <Menu.Item className="menu-item">
                  <i className="fal fa-store" />
                  <span> Fornitori</span>
                </Menu.Item>
                <Menu.Item className="menu-item">
                  <i className="fal fa-megaphone" />
                  <span> Marketing</span>
                </Menu.Item>
                <Menu.Item className="menu-item">
                  <i className="fal fa-sliders-v" />
                  <span> Impostazioni</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
                onClick={(e) => {
                  this.state.selectedKeys[0] == [e.key]
                    ? this.setState({ selectedKeys: [""] })
                    : this.setState({ selectedKeys: [e.key] })
                }}
                key={3}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-university" />
                      <span>FINANZIARIO</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-university small" />
                    </span>
                  )
                }
              >
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    this.props.history.push("/back-office/allMovimenti")
                  }}
                >
                  <i className="fal fa-calendar-alt"></i>
                  <span> Tutti Movimenti</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { depModalVis } = this.props
                    if (depModalVis) {
                      editDepModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editDepModal({
                        visibility: true,
                        data: {
                          graphData: "full",
                        },
                      })
                    }

                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-coins" />
                  <span>Depositi / Prelievi</span>
                </Menu.Item>
                <Menu.Item className="menu-item">
                  <i className="fal fa-receipt" />
                  <span> Fatture</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { Statistiche, TrCoPro, statModalVis } = this.props
                    if (statModalVis) {
                      editStatModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editStatModal({
                        visibility: true,
                        data: {
                          graphData: Statistiche,
                          Tranzacioni: TrCoPro?.importo,
                          Commisione: TrCoPro?.commissione,
                          Proviggioni: TrCoPro?.proviggioni,
                          Aggio: TrCoPro?.system_aggio,
                        },
                      })
                    }
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-analytics"></i>
                  <span>Statistiche</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push("/back-office/messaggi")
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-envelope" />
                    <span>MESSAGGI</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-envelope" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item className="not-submenu">
                {this.props.collapsed ? (
                  <Chat />
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-comments-alt" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push(
                    this.props.accountInfo?.profile.role.name === "main_admin"
                      ? "/back-office/configure"
                      : "/back-office/configura"
                  )
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-cogs" />
                    <span>SETTINGS</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-cogs" />
                  </span>
                )}
              </Menu.Item>
              {role === "main_admin" && (
                <Menu.Item
                  className="not-submenu"
                  onClick={() => {
                    this.props.history.push("/back-office/gallery")
                  }}
                >
                  {this.props.collapsed ? (
                    <span>
                      {" "}
                      <i className="fal fa-images"></i>
                      <span>GALLERY</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-images" />
                    </span>
                  )}
                </Menu.Item>
              )}
              {role === "main_admin" && (
                <Menu.Item
                  className="not-submenu"
                  onClick={() => {
                    this.props.history.push("/back-office/CreateSkin")
                    this.props.addEditSkinDetails({
                      skinId: -1,
                      skinName: "newSkin",
                      skinPannel: false,
                      stepNumber: 0,
                    })
                  }}
                >
                  {this.props.collapsed ? (
                    <span>
                      {" "}
                      <i className="fal fa-plus"></i>
                      <span>AGGIUNGI SKIN</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-plus" />
                    </span>
                  )}
                </Menu.Item>
              )}
              {role === "main_admin" && (
                <Menu.Item
                  className="not-submenu"
                  onClick={() => {
                    this.props.history.push("/back-office/category")
                  }}
                >
                  {this.props.collapsed ? (
                    <span>
                      {" "}
                      <i className="fal fa-plus"></i>
                      <span>CREATE CATEGORY</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-plus" />
                    </span>
                  )}
                </Menu.Item>
              )}
            </Menu>
          )}

          {/* SUPPORT MENU */}
          {role === "support" && (
            <Menu theme="light" mode="inline" selectedKeys={this.state.selectedKeys}>
              <Menu.SubMenu
                key={1}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-address-book" />
                      <span>SKINS</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-address-book" />
                    </span>
                  )
                }
              >
                {role !== "super_admin" &&
                  role !== "agent" &&
                  skinList &&
                  Array.isArray(skinList) &&
                  skinList
                    .filter((skin) => skin.status === 1)
                    .map((skin) => (
                      <Menu.Item
                        key={skin.id}
                        onClick={(e) => {
                          setActiveSkinId(activeSkinId === skin.id ? -1 : skin.id)
                          setActiveSkinName(
                            activeSkinName === skin.username ? "" : skin.username
                          )
                          this.state.selectedKeys[0] == [e.key]
                            ? this.setState({ selectedKeys: [""] })
                            : this.setState({ selectedKeys: [e.key] })
                          this.CloseWindowOnClick()
                        }}
                      >
                        <div className="AdminLeftForm--FirstBox--Box--Skinsvg">
                          {" "}
                          <img src={this.returnIcon(skin.id)} alt="" />
                          <span>{skin.username.toUpperCase()}</span>
                        </div>
                      </Menu.Item>
                    ))}
              </Menu.SubMenu>
              <Menu.SubMenu
                onClick={(e) => {
                  this.state.selectedKeys[0] == [e.key]
                    ? this.setState({ selectedKeys: [""] })
                    : this.setState({ selectedKeys: [e.key] })
                }}
                key={2}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-university" />
                      <span>FINANZIARIO</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-university small" />
                    </span>
                  )
                }
              >
                <Menu.Item
                  className="menu-item"
                  onClick={() => this.props.toggleNuovoBiller(true)}
                >
                  <div className="AdminLeftForm--LastBox--Box biller">
                    <i className="fal fa-address-card"></i>
                    <span>Aggiungi biller</span>
                  </div>
                  <i className="fal fa-address-card"></i>
                  <span> Aggiungi Biller</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { depModalVis } = this.props
                    if (depModalVis) {
                      editDepModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editDepModal({
                        visibility: true,
                        data: {
                          graphData: "full",
                        },
                      })
                    }

                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-coins" />
                  <span>Depositi / Prelievi</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { ultModalVis } = this.props
                    if (ultModalVis) {
                      editUltModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editUltModal({
                        visibility: true,
                        data: {
                          leUltimeTransazioniDet: leUltimeTransazioniDet,
                        },
                      })
                    }
                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-heart-rate"></i>
                  <span>Transazioni</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { Statistiche, TrCoPro, statModalVis } = this.props
                    if (statModalVis) {
                      editStatModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editStatModal({
                        visibility: true,
                        data: {
                          graphData: Statistiche,
                          Tranzacioni: TrCoPro?.importo,
                          Commisione: TrCoPro?.commissione,
                          Proviggioni: TrCoPro?.proviggioni,
                          Aggio: TrCoPro?.system_aggio,
                        },
                      })
                    }
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-analytics"></i>
                  <span>Statistiche</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item className="not-submenu">
                {this.props.collapsed ? (
                  <Chat />
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-comments-alt" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push(
                    this.props.accountInfo?.profile.role.name === "main_admin"
                      ? "/back-office/configure"
                      : "/back-office/configura"
                  )
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-cogs" />
                    <span>SETTINGS</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-cogs" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push("/back-office/gallery")
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    {" "}
                    <i className="fal fa-images"></i>
                    <span>GALLERY</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-images" />
                  </span>
                )}
              </Menu.Item>
            </Menu>
          )}

          {/* SUPER ADMIN MENU */}
          {role === "super_admin" && (
            <Menu theme="light" mode="inline" selectedKeys={this.state.selectedKeys}>
              <Menu.Item
                className="not-submenu"
                style={{ marginTop: "unset" }}
                onClick={() => {
                  this.props.history.push("/back-office/registraAgenzia")
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-plus" />
                    <span>REGISTRA AGENZIA</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-plus" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push("/back-office/registraAgente")
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-plus" />
                    <span>REGISTRA AGENTE</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-plus" />
                  </span>
                )}
              </Menu.Item>

              <Menu.SubMenu
                onClick={(e) => {
                  this.state.selectedKeys[0] == [e.key]
                    ? this.setState({ selectedKeys: [""] })
                    : this.setState({ selectedKeys: [e.key] })
                }}
                key={2}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-university" />
                      <span>FINANZIARIO</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-university small" />
                    </span>
                  )
                }
              >
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { Statistiche, TrCoPro, statModalVis } = this.props
                    if (statModalVis) {
                      editStatModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editStatModal({
                        visibility: true,
                        data: {
                          graphData: Statistiche,
                          Tranzacioni: TrCoPro?.importo,
                          Commisione: TrCoPro?.commissione,
                          Proviggioni: TrCoPro?.proviggioni,
                          Aggio: TrCoPro?.system_aggio,
                        },
                      })
                    }
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-analytics"></i>
                  <span>Statistiche</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { ultModalVis } = this.props
                    if (ultModalVis) {
                      editUltModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editUltModal({
                        visibility: true,
                        data: {
                          leUltimeTransazioniDet: leUltimeTransazioniDet,
                        },
                      })
                    }
                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-heart-rate"></i>
                  <span>Transazioni</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { depModalVis } = this.props
                    if (depModalVis) {
                      editDepModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editDepModal({
                        visibility: true,
                        data: {
                          graphData: "full",
                        },
                      })
                    }

                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-coins" />
                  <span>Depositi / Prelievi</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item className="not-submenu">
                {this.props.collapsed ? (
                  <Chat />
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-comments-alt" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push(
                    this.props.accountInfo?.profile.role.name === "main_admin"
                      ? "/back-office/configure"
                      : "/back-office/configura"
                  )
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-cogs" />
                    <span>SETTINGS</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-cogs" />
                  </span>
                )}
              </Menu.Item>
            </Menu>
          )}

          {/* AGENT MENU */}
          {role === "agent" && (
            <Menu theme="light" mode="inline" selectedKeys={this.state.selectedKeys}>
              <Menu.Item
                className="not-submenu"
                style={{ marginTop: "unset" }}
                onClick={() => {
                  this.props.history.push("/back-office/registraAgenzia")
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-plus" />
                    <span>REGISTRA AGENZIA</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-plus" />
                  </span>
                )}
              </Menu.Item>
              <Menu.SubMenu
                onClick={(e) => {
                  this.state.selectedKeys[0] == [e.key]
                    ? this.setState({ selectedKeys: [""] })
                    : this.setState({ selectedKeys: [e.key] })
                }}
                key={2}
                title={
                  this.props.collapsed ? (
                    <span>
                      <i className="fal fa-university" />
                      <span>FINANZIARIO</span>
                    </span>
                  ) : (
                    <span className="menu-item-small">
                      {" "}
                      <i className="fal fa-university small" />
                    </span>
                  )
                }
              >
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { depModalVis } = this.props
                    if (depModalVis) {
                      editDepModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editDepModal({
                        visibility: true,
                        data: {
                          graphData: "full",
                        },
                      })
                    }

                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-coins" />
                  <span>Depositi / Prelievi</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { ultModalVis } = this.props
                    if (ultModalVis) {
                      editUltModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editUltModal({
                        visibility: true,
                        data: {
                          leUltimeTransazioniDet: leUltimeTransazioniDet,
                        },
                      })
                    }
                    editStatModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-heart-rate"></i>
                  <span>Transazioni</span>
                </Menu.Item>
                <Menu.Item
                  className="menu-item"
                  onClick={() => {
                    const { Statistiche, TrCoPro, statModalVis } = this.props
                    if (statModalVis) {
                      editStatModal({
                        visibility: false,
                        data: "",
                      })
                    } else {
                      editStatModal({
                        visibility: true,
                        data: {
                          graphData: Statistiche,
                          Tranzacioni: TrCoPro?.importo,
                          Commisione: TrCoPro?.commissione,
                          Proviggioni: TrCoPro?.proviggioni,
                          Aggio: TrCoPro?.system_aggio,
                        },
                      })
                    }
                    editUltModal({
                      visibility: false,
                      data: "",
                    })
                    editDepModal({
                      visibility: false,
                      data: "",
                    })
                    this.CloseWindowOnClick()
                  }}
                >
                  <i className="fal fa-analytics"></i>
                  <span>Statistiche</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item className="not-submenu">
                {this.props.collapsed ? (
                  <Chat />
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-comments-alt" />
                  </span>
                )}
              </Menu.Item>
              <Menu.Item
                className="not-submenu"
                onClick={() => {
                  this.props.history.push(
                    this.props.accountInfo?.profile.role.name === "main_admin"
                      ? "/back-office/configure"
                      : "/back-office/configura"
                  )
                }}
              >
                {this.props.collapsed ? (
                  <span>
                    <i className="fal fa-cogs" />
                    <span>SETTINGS</span>
                  </span>
                ) : (
                  <span className="menu-item-small">
                    {" "}
                    <i className="fal fa-cogs" />
                  </span>
                )}
              </Menu.Item>
            </Menu>
          )}
        </div>
      </div>
    )
  }
}
const mstp = (state) => ({
  screenWidth: state.main.screenWidth,
  skinList: state.auth.skinList,
  activeSkinId: state.main.activeSkinId,
  TrCoPro: state.auth.Statistiche?.total,
  Statistiche: state.auth.Statistiche?.data,
  leUltimeTransazioniDet: state.auth.leUltimeTransazioniDet,
  accountInfo: state.auth.accountInfo,
  statModalVis: state.auth.statModal.visibility,
  ultModalVis: state.auth.ultModal.visibility,
  depModalVis: state.auth.depModal.visibility,
  adminMessagesModal: state.main.adminMessagesModal,
  privMsg: state.auth.privMsg,
  ads: state.auth.msg,
  activeSkinName: state.main.activeSkinName,
})
export default connect(mstp, { ...MainActions, ...AuthActions })(AdminLeftForm)
