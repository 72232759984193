import React, { useState } from "react"
import { Input, Form, Modal } from "antd"
import Gallery from "../../../routes/views/Gallery"
import { baseUrlImg } from "../../../config/api"

const ImageUploadComponent = ({
  label,
  imageType,
  onImageSelect,
  value,
  selectedCompany,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null)

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const renderImage = (type) => {
    if (selectedCompany?.[type]) {
      return (
        <img
          src={`${baseUrlImg}/${selectedCompany?.[type]}`}
          onClick={() => setIsModalVisible(true)}
          style={{ width: 40, height: 40, objectFit: "contain", marginRight: 5, cursor: "pointer" }}
          alt={`${type} image`}
        />
      )
    } else {
      return <i className="far fa-camera" onClick={() => setIsModalVisible(true)} />
    }
  }

  return (
    <>
      <Form.Item name={imageType} label={label}>
        <Input
          placeholder={label}
          value={selectedGalleryImage ? selectedGalleryImage.name : value}
          suffix={renderImage(imageType)}
        />
      </Form.Item>
      <Modal
      className="gallery-modal"
        width={550}
        title="Gallery"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={() => {
          setIsModalVisible(false)
          onImageSelect(selectedGalleryImage)
        }}
      >
        <Gallery
          onSelect={(image) => setSelectedGalleryImage(image, imageType)}
          modalCardStyle={{ width: "100px", height: "100px" }}
          selectedImage={selectedGalleryImage}
          modalWidth={{ width: "100px", height: "30px" }}
          showActions={false}
          imageType={imageType}
        />
      </Modal>
    </>
  )
}

export default ImageUploadComponent
