import React from "react"
import { baseUrlImg } from "../../config/api"

const PrintTicketPaysafe = ({ arr, barcode }) => {
  return (
    <div className="pin-receipt">
      <div className="receipt-info">
        <span className="service-name">
          {JSON.parse(arr.receipt)["paysafecard"]}
        </span>
        <img src={`${baseUrlImg}/${arr.logo}`} alt="service logo" />
        <div className="receipt-row">
          Service Name: <span>{JSON.parse(arr.receipt)["paysafecard"]}</span>
        </div>
        <div className="receipt-row">
          Date/Ora:{" "}
          <span>
            {JSON.parse(arr.receipt)["Date"]}{" "}
            {JSON.parse(arr.receipt)["Time"]}
          </span>
        </div>
        <div className="receipt-row">
          Trace-Nr: <span>{JSON.parse(arr.receipt)["Trace-Nr."]}</span>
        </div>
        <div className="receipt-row">
          Receipt-Nr: <span>{JSON.parse(arr.receipt)["Receipt-Nr."]}</span>
        </div>
        <div className="receipt-row">
          Importo: <span>{JSON.parse(arr.receipt)["Importo"]}</span>
        </div>
        <div className="receipt-row">
          PIN: <span>{JSON.parse(arr.receipt)["PIN "]}</span>
        </div>
        <div className="receipt-row">
          Numero Seriale: <span>{JSON.parse(arr.receipt)["Numero Seriale"]}</span>
        </div>
       
      </div>
      {barcode !== 1 && (
        <div className="receipt-barcode">
          <img
            className="barcodeModal"
            src={`https://barcode.tec-it.com/barcode.ashx?data=${
              barcode || 0
            }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
            alt=""
          />
        </div>
      )}

      <div className="receipt-description">
        {JSON.parse(arr.receipt)["Description"]}
      </div>
    </div>
  )
}

export default PrintTicketPaysafe
