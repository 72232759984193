import React, { Fragment } from "react"
import ReturnFormItem from "./FormHelperComp"
import { Radio, DatePicker } from "antd"
import { ImportoFormItem } from "./BolletiniForms"
import BollettinoPrivacyLinks from "./Bolletino/BollettinoPrivacyLinks"

export const SepafinMavRavRightForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  codiceFiscaleOrPartita,
}) => (
  <Fragment>
    <ReturnFormItem
      descName="Persona"
      name="person_type"
      message="Inserisci persona tipo!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
      defaultValue={"F"}
      NotInput={
        <Radio.Group>
          <Radio value="F">Fisica</Radio>
          <Radio value="G">Giuridica</Radio>
        </Radio.Group>
      }
    />

    {getFieldValue("person_type") === "F" ||
    typeof getFieldValue("person_type") === "undefined" ? (
      <Fragment>
        <ReturnFormItem
          descName="Nome"
          name="nome"
          message="Inserisci Nome!"
          barcodeData={barcodeData}
          getFieldDecorator={getFieldDecorator}
        />
        <ReturnFormItem
          descName="Cognome"
          name="cognome"
          message="Inserisci Cognome!"
          barcodeData={barcodeData}
          getFieldDecorator={getFieldDecorator}
        />
        {codiceFiscaleOrPartita && (
          <ReturnFormItem
            descName="Codice Fiscale"
            name="codice_fiscale"
            message="Inserisci Codice Fiscale!"
            barcodeData={barcodeData}
            getFieldDecorator={getFieldDecorator}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <ReturnFormItem
          descName="Denominazione"
          name="denominazione"
          message="Inserisci Denominazione!"
          barcodeData={barcodeData}
          getFieldDecorator={getFieldDecorator}
          icon={<i className="fal fa-search" />}
        />
        {codiceFiscaleOrPartita && (
          <ReturnFormItem
            descName="Partiva IVA"
            name="partita_iva"
            message="Inserisci Partiva IVA!"
            barcodeData={barcodeData}
            getFieldDecorator={getFieldDecorator}
          />
        )}
      </Fragment>
    )}
    <ReturnFormItem
      descName="Causale"
      name="causale"
      message="Per favore inserisci Causale!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
    <ReturnFormItem
      descName="Telefono"
      name="phone_number"
      message="Inserisci telefono!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
  </Fragment>
)

export const SepafinMavRavLeftForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  setServiceID,
  service_s,
  mobile,
  setFieldsValue
}) => (
  <Fragment>
    <ReturnFormItem
      descName="Mav/Rav"
      name="mav_rav"
      message="Inserisci persona tipo!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
      defaultValue={"2"}
      NotInput={
        <Radio.Group
          onChange={(e) => {
            setServiceID &&
              setServiceID(
                e.target.value === "1"
                  ? mobile
                    ? service_s.services[0].service_id
                    : service_s.services[0]
                  : mobile
                  ? service_s.services[1].service_id
                  : service_s.services[1]
              )
          }}
        >
          <Radio value="2">MAV</Radio>
          <Radio value="1">RAV</Radio>
        </Radio.Group>
      }
    />

    <ImportoFormItem {...{ barcodeData, getFieldDecorator, setFieldsValue }} />
    <ReturnFormItem
      descName={`Codice ${
        getFieldValue("mav_rav") === "2" ||
        typeof getFieldValue("mav_rav") === "undefined"
          ? "Mav"
          : "Rav"
      }`}
      name="codice"
      message="Inserisci Codice!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
    {mobile && <div className="DatiPagatori">DATI PAGATORE</div>}
    <ReturnFormItem
      descName="Indirizzo"
      name="via_piazza"
      message="Inserisci Indirizzo!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
    <ReturnFormItem
      descName="Città"
      name="citta"
      message="Inserisci  Città!"
      barcodeData={barcodeData}
      getFieldDecorator={getFieldDecorator}
    />
  </Fragment>
)

export const MrPayMavRavLeftForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  setServiceID,
  service_s,
  mobile,
  setFieldsValue,
}) => {
  const isMav =
    getFieldValue("mav_rav") === "1" ||
    typeof getFieldValue("mav_rav") === "undefined"
  const codeName = isMav ? "Mav" : "Rav"
  return (
    <Fragment>
      <ReturnFormItem
        descName="Mav/Rav"
        name="mav_rav"
        message="Inserisci persona tipo!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        defaultValue={"1"}
        NotInput={
          <Radio.Group
            onChange={(e) => {
              setServiceID &&
                setServiceID(
                  e.target.value === "1"
                    ? mobile
                      ? service_s.services[0].service_id
                      : service_s.services[0]
                    : mobile
                    ? service_s.services[1].service_id
                    : service_s.services[1]
                )
            }}
          >
            <Radio value="1">MAV</Radio>
            <Radio value="2">RAV</Radio>
          </Radio.Group>
        }
      />

      <ImportoFormItem {...{ barcodeData, getFieldDecorator, setFieldsValue }} />
      <ReturnFormItem
        descName={codeName}
        name="rav"
        message={`Inserisci ${codeName}!`}
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <ReturnFormItem
        descName="Numero conto corrente"
        name="numero_conto_corrente"
        message="Inserisci Numero Conto Corrente!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      {isMav && (
        <ReturnFormItem
          descName="Indirizzo"
          name="indirizzo"
          message="Inserisci Indirizzo!"
          barcodeData={barcodeData}
          getFieldDecorator={getFieldDecorator}
        />
      )}
      <ReturnFormItem
        descName="Citta"
        name="citta"
        message="Inserisci Citta!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
    </Fragment>
  )
}

export const MrPayMavRavRightForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  codiceFiscaleOrPartita,
}) => {
  const isMav =
    getFieldValue("mav_rav") === "1" ||
    typeof getFieldValue("mav_rav") === "undefined"
  const capName = isMav ? "Cap" : "Codice Fiscale"
  const nrDocName = isMav ? "Riferimento" : "Numero documento"
  return (
    <Fragment>
      <ReturnFormItem
        descName="Eseguito da"
        name="eseguito_da"
        message="Inserisci Eseguito da!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <ReturnFormItem
        descName="Provincia"
        name="provincia"
        message="Inserisci Provincia!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <ReturnFormItem
        descName={capName}
        name="codice_fiscale"
        message={`Inserisci ${capName}!`}
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <ReturnFormItem
        descName={nrDocName}
        name="numero_documento"
        message={`Inserisci ${nrDocName}!`}
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />

      <ReturnFormItem
        descName="Scadenza"
        name="scadenza"
        message="Inserisci Scadenza!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        datepicker
        NotInput={<DatePicker showTime format="DD/MM/YYYY" />}
      />
    </Fragment>
  )
}

export const EPayMavRavLeftForm = ({
  barcodeData,
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  setServiceID,
  service_s,
  mobile,
}) => {
  const isMav = getFieldValue("mav_rav") === "2"
  const codeName = isMav ? "Mav" : "Rav"
  return (
    <Fragment>
      <ReturnFormItem
        descName="Mav/Rav"
        name="mav_rav"
        message="Inserisci Persona Tipo!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        defaultValue={"1"}
        NotInput={
          <Radio.Group
            onChange={(e) => {
              setServiceID &&
                setServiceID(
                  e.target.value === "1"
                    ? mobile
                      ? service_s.services[0].service_id
                      : service_s.services[0]
                    : mobile
                    ? service_s.services[1].service_id
                    : service_s.services[1]
                )
            }}
          >
            <Radio value="1">RAV</Radio>
            <Radio value="2">MAV</Radio>
          </Radio.Group>
        }
      />

      {/* <ReturnFormItem
        descName="Importo"
        name="importo"
        message="Inserisci Importo!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      /> */}
      <ImportoFormItem {...{ barcodeData, getFieldDecorator, setFieldsValue }} />
      <ReturnFormItem
        descName={codeName}
        name="rav"
        message={`Inserisci ${codeName}!`}
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
        // maxLength={18}
      />
    </Fragment>
  )
}

export const EPayMavRavRightForm = ({ barcodeData, getFieldDecorator }) => {
  return (
    <Fragment>
      <ReturnFormItem
        descName="Eseguito da"
        name="eseguito_da"
        message="Inserisci Eseguito da!"
        barcodeData={barcodeData}
        getFieldDecorator={getFieldDecorator}
      />
      <BollettinoPrivacyLinks />
    </Fragment>
  )
}
