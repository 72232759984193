import React from "react"
import images from "themes/images"
import { isSepafinSelector } from "utils/HelperFunc"
import { connect } from "react-redux"

const TopWrapper = ({ services, setService, activeService, isSepafin }) => {
  const pagaamentiCategory = isSepafin ? "SPRDPST" : "PRDPST"

  return (
    <div className="wrapperTop">
      {services[pagaamentiCategory] &&
        Object.keys(services[pagaamentiCategory]).map((keyBolletines) => {
          return keyBolletines !== "name" &&
            keyBolletines !== "group" &&
            ["SBOL001", "BOL001"].includes(
              services[pagaamentiCategory][keyBolletines].services[0].service_id
            )
            ? services[pagaamentiCategory][keyBolletines].services.map((item) => {
                return (
                  <div
                    key={item.service_id}
                    onClick={() => {
                      setService(item?.service_id)
                    }}
                    className={
                      "bolletini--services__item" +
                      (activeService === item?.service_id ? " active" : "")
                    }
                  >
                    <img
                      src={
                        item?.service_id === "SBOL006" ||
                        item?.service_id === "SPPA001" ||
                        item?.service_id === "SPAGF24"
                          ? images[`${item.service_id}-Black-Mobile`]
                          : images[`BOLL-Black-Mobile`]
                      }
                      alt=""
                    />
                    <span>{item.name}</span>
                  </div>
                )
              })
            : keyBolletines !== "name" &&
                keyBolletines !== "group" &&
                services[pagaamentiCategory][keyBolletines].services[0]
                  .service_id !== "SBOL001" && services[pagaamentiCategory][keyBolletines].services[0].service_id !== "RPP001" && (
                  <div
                    key={
                      services[pagaamentiCategory][keyBolletines].services[0]
                        .service_id
                    }
                    onClick={() => {
                      setService(
                        services[pagaamentiCategory][keyBolletines].services[0]
                          .service_id
                      )
                    }}
                    className={
                      "bolletini--services__item" +
                      (["SBOL004", "SBOL003", "BOL004", "BOL003"].includes(
                        activeService
                      ) &&
                      ["SBOL004", "SBOL003", "BOL004", "BOL003"].includes(
                        services[pagaamentiCategory][keyBolletines].services[0]
                          .service_id
                      )
                        ? " active"
                        : services[pagaamentiCategory][keyBolletines].services[0]
                            .service_id === activeService
                        ? " active"
                        : "")
                    }
                  >
                    <img
                      src={
                        images[
                          services[pagaamentiCategory][keyBolletines].services[0]
                            .service_id === "SPPA001"
                            ? `${services[pagaamentiCategory][keyBolletines].services[0].service_id}-Black-Mobile`
                            : `${keyBolletines === "RCPP" ? "RCPP" : "BOLL"}${
                                keyBolletines === "RCPP" ? "" : `-Black-Mobile`
                              }`
                        ]
                      }
                      alt=""
                    />
                    <span>{services[pagaamentiCategory][keyBolletines].name}</span>
                  </div>
                )
        })}
    </div>
  )
}

const mstp = (state) => ({
  isSepafin: isSepafinSelector(state),
})

export default connect(mstp, null)(TopWrapper)
