import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Modal } from "antd"
import images from "themes/images"
import { Document, Page } from "react-pdf"
import ReactToPrint from "react-to-print"
import { b64toBlob } from "utils"
import { capitalize } from "lodash"
import DownloadVisure from "shared-components/DownloadVisure"
import moment from "moment"

class PrintTicket extends React.Component {
  printPdfReceipt = (data, type) => {
    if (data.receipt_type === "base64") {
      var myBlob = b64toBlob(data.receipt, "application/pdf")
      var blobUrl = URL.createObjectURL(myBlob)
      if (type === "print") {
        window
          .open(
            blobUrl,
            "_blank",
            "toolbar=no,scrollbars=no,resizable=no,top=50,left=500,width=700,height=700"
          )
          .print()
      }
      if (type === "download") {
        const linkSource = `data:application/pdf;base64,${data.receipt}`
        const downloadLink = document.createElement("a")
        const fileName = "Ticket.pdf"

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
      if (type === "return") {
        return blobUrl
      }
    }
  }

  returnCoffeeStringHtml = (coffeDetails) => {
    let htmlTemplate = ""
    const unAllowedValues = ["created_at", "updated_at", "id", "barcode"]
    Object.keys(coffeDetails).forEach((coffeDetailKey) => {
      if (!unAllowedValues.includes(coffeDetailKey)) {
        htmlTemplate += `<br/>${coffeDetailKey
          .split("_")
          .map((word) => capitalize(word))
          .join(" ")} <div class='marginB'></div><div class='marginC'>${
          coffeDetails[coffeDetailKey]
        }</div><br/>`
      }
    })
    return htmlTemplate
  }

  getPrintBtn = () => {
    return (
      <ReactToPrint
        trigger={() => <div className="printBtn">Print</div>}
        content={() => this.componentRef}
        bodyClass="afterprint"
        // copyStyles="false"
      />
    )
  }

  generateTicketBody = (barcode, receipt) => {
    const { arr, skinExtras, activeSkinId } = this.props

    let receipt_spedizioni = {}
    if (arr.receipt_type == "json_spedizionepro")
      receipt_spedizioni = JSON.parse(arr.receipt)

    const isVisure = ["JSON", "json_bitnovo"].includes(arr.receipt_type)
    const visureReceipt = () =>
      arr.receipt &&
      isVisure &&
      typeof arr.receipt === "string" &&
      JSON.parse(arr.receipt)
    const keysToMap = [
      { label: "Date/Ora", field: "date_ora" },
      { label: "Codice Fiscale", field: "cd" },
      { label: "Codice Destinario", field: "codiceDestinatario" },
      { label: "Descrizione Ateco", field: "descrizioneAteco" },
      { label: "Data Iscrizione", field: "dataIscirzione" },
      { label: "Codice Ateco", field: "codAteco" },
      { label: "Support code", field: "support_code" },
      { label: "Order id", field: "order_id" },
    ]
    const keysToNotChange = [
      "date_ora",
      "piva",
      "cf",
      "agency_name",
      "agency_address",
      "bitnovo_category",
    ].concat(keysToMap.map(({ field }) => field))

    const visureTicketDetails = () => [
      ...Object.keys(visureReceipt())
        .filter((key) => !keysToNotChange.includes(key))
        .map((key) => ({
          field: key,
          label: capitalize(key),
        })),
      { label: "Date/Ora", field: "date_ora" },
      { label: "Codice Fiscale", field: "cd" },
      { label: "Codice Destinario", field: "codiceDestinatario" },
      { label: "Descrizione Ateco", field: "descrizioneAteco" },
      { label: "Data Iscrizione", field: "dataIscirzione" },
      { label: "Codice Ateco", field: "codAteco" },
      { label: "Support code", field: "support_code" },
      { label: "Order id", field: "order_id" },
    ]

    const stanleySkin = this.props?.skins.find((skin) => skin.id === 57)

    return (
      <div
        className={`printModal rootTicket ${isVisure ? "visure" : ""}`}
        ref={(el) => (this.componentRef = el)}
      >
        <div className="headerModal">
          <img className={`logo ${skinExtras.name}`} src={images.logo} alt="" />
          <span className="superSmall text-bold">
            <span> {skinExtras.account_name} </span>
          </span>
          <span className="superSmall">{skinExtras.address}</span>
          <span className="superSmall link">{skinExtras.email}</span>
          <span className="superSmall ">Tel: {skinExtras.cel}</span>
          <span className="superSmall tel">
            {" "}
            {activeSkinId === 57 && (
              <span className="xs-text">By {stanleySkin?.rag_soc} </span>
            )}
            <span className="superSmall">P.IVA 03852290406</span>
          </span>

          {/* <span>BPOINT</span> */}

          <span className="fontSmall text-bold">{arr.agency_name}</span>
          <span className="fontSmall address">{arr.agency_address}</span>
          {/* <span className="userCel">
                    {" "}
                    Telefono: <b>{this.state.phone}</b>{" "}
                  </span> */}
          {/* BPOINT<br></br>
                    GALASSI GABRIELE<br></br>
                  VIA DEL LAVORO, 29 - IMOLA<br></br>
                  Telefono: 335398618<br></br> */}
        </div>
        {arr && arr.receipt_type === "base64" ? (
          <div>
            {/* <embed
                      type="application/pdf"
                      style={{
                        width: "100%",
                        height: "443px",
                      }}
                      id="iframepdf"
                      src={`data:application/pdf;base64,${arr.receipt}`}
                    ></embed> */}
            <Document renderMode="canvas" file={this.printPdfReceipt(arr, "return")}>
              <Page width={380} pageNumber={1} />
            </Document>

            <div
              className="printBtn"
              onClick={() => {
                this.printPdfReceipt(arr, "print")
              }}
            >
              Print Ticket
            </div>
            <div
              className="printBtn"
              onClick={() => {
                this.printPdfReceipt(arr, "download")
              }}
            >
              Download Ticket
            </div>
          </div>
        ) : isVisure ? (
          <>
            <span className="border-bottom"></span>
            <div>
              <h1>{visureReceipt()["visure_nome"]}</h1>
              {visureTicketDetails().map(({ label, field }) => {
                const canCurrency = ["importo", "iva", "totale"].includes(field)
                const currencyLabel =
                  canCurrency &&
                  new Intl.NumberFormat("it-IT", {
                    style: "currency",
                    currency: "EUR",
                  }).format(parseFloat(visureReceipt()[field]))

                return (
                  field in visureReceipt() && (
                    <div className="visure__row" key={label}>
                      <label>{label}</label>
                      <span>{currencyLabel || visureReceipt()[field]}</span>
                    </div>
                  )
                )
              })}
              {arr.receipt_type === "json_bitnovo" && (
                <img
                  className="bitnovo-logo"
                  src={images["bitnovo-black"]}
                  alt="bitnovologo"
                />
              )}
              {arr.receipt_type === "JSON" &&
                !(arr.barcode || "").includes("000024") && (
                  <h1 className="disponibile-text">{`Tempo di consegna: ${
                    visureReceipt()["consegna"]
                  }`}</h1>
                )}
            </div>
          </>
        ) : arr.receipt_type === "json_spedizionepro" ? (
          <div className="json_spedizionepro">
            <h5 className="d-flex justify-content-center font-weight-bold ">
              {receipt_spedizioni.receipt_name.toUpperCase()}
            </h5>
            <div>
              Data/Ora:{" "}
              <span>
                {" "}
                {moment(receipt_spedizioni.date_ora).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </div>
            <div>
              Nr: <span>{arr.barcode}</span>
            </div>
            <div>
              Data Ritiro: <span>{receipt_spedizioni.dataRitiroIT}</span>
            </div>
            <div className="font-weight-bold">
              Data Consegna: <span>{receipt_spedizioni.dataConsegnaPrevistaIT}</span>
            </div>
            <div>
              Servizio: <span>{receipt_spedizioni.servizio.toUpperCase()}</span>
            </div>
            <div>
              Corriere: <span>{receipt_spedizioni.corriere.toUpperCase()}</span>
            </div>
            <div>
              Peso:{" "}
              <span>
                {receipt_spedizioni.colli.reduce((total, collo) => {
                  return total + collo.pesoReale
                }, 0)}
                kg
              </span>
            </div>
            <div>
              Nr Colli: <span>{receipt_spedizioni.numeroColli}</span>
            </div>
            <div>
              Dimensione pacco:
              <span>
                {receipt_spedizioni.colli
                  .map(
                    (collo) =>
                      `${collo.altezza}x${collo.larghezza}x${collo.profondita}cm`
                  )
                  .join(", ")}
              </span>
            </div>
            <div className="font-weight-bold mt-3">
              Mitente:{" "}
              <span>
                {receipt_spedizioni.nominativoMittente}{" "}
                {receipt_spedizioni.pressoMittente}
              </span>
            </div>
            <div>
              Indirizzo: <span>{receipt_spedizioni.indirizzoMittente} </span>
            </div>
            <div>
              Provincia: <span>{receipt_spedizioni.provinciaMittente}</span>
            </div>
            <div>
              Cap e Comune:{" "}
              <span>
                {receipt_spedizioni.capMittente} {receipt_spedizioni.comuneMittente}
              </span>
            </div>
            <div>
              Tel: <span>{receipt_spedizioni.telefonoMittente}</span>
            </div>
            <div>
              E-mail: <span>{receipt_spedizioni.emailMittente}</span>
            </div>
            <div className="font-weight-bold mt-3">
              Destinatario:{" "}
              <span>
                {receipt_spedizioni.nominativoDestinatario}{" "}
                {receipt_spedizioni.pressoDestinatario}
              </span>
            </div>
            <div>
              Indirizzo: <span>{receipt_spedizioni.indirizzoDestinatario} </span>
            </div>
            <div>
              Provincia: <span>{receipt_spedizioni.provinciaDestinatario}</span>
            </div>
            <div>
              Cap e Comune:{" "}
              <span>
                {receipt_spedizioni.capDestinatario}{" "}
                {receipt_spedizioni.comuneDestinatario}
              </span>
            </div>
            <div>
              Tel: <span>{receipt_spedizioni.telefonoDestinatario}</span>
            </div>
            <div>
              E-mail: <span>{receipt_spedizioni.emailDestinatario}</span>
            </div>
            <div>
              Importo: <span>{(receipt_spedizioni.totale / 1.22).toFixed(2)} €</span>
            </div>
            <div>
              IVA:
              <span>{(0.22 * (receipt_spedizioni.totale / 1.22)).toFixed(2)} €</span>
            </div>
            <div className="font-weight-bold">
              Totale: <span>{receipt_spedizioni.totale} €</span>
            </div>
          </div>
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  arr &&
                  arr.receipt &&
                  arr.receipt
                    // .replace(/</g, "&lt;")
                    // .replace(/>/g, "&gt;")
                    .replace(/\t/g, "\u00a0")
                    .replace(/\n/g, "<br/> ")
                    .replace(/\+/g, " ")
                    .replace(/: /g, ":<div class='marginB'></div>")
                    .replace(
                      /<div class='marginB'><\/div>([^>]+)<br\/>/g,
                      "<div class='marginB'></div><div class='marginC'>$1</div><br/>"
                    )
                    .replace(
                      /https:\/\/web.my-gate.eu\/v\?c=\w+/g,
                      "<a href='$&' target='_blank'>$&</a>"
                    )
                    .replace(
                      /(<img src="[^"]+"[^>]*>)/g,
                      '<div style="display: flex; flex-direction: column; align-items: center;"><div>Scansiona il Qr code</div>$1</div>'
                    ) +
                    (arr.caffeDetails
                      ? this.returnCoffeeStringHtml(arr.caffeDetails)
                      : ""),
              }}
            ></div>
          </>
        )}

        <img
          className="barcodeModal"
          src={`https://barcode.tec-it.com/barcode.ashx?data=${
            barcode || 0
          }&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
          alt=""
        />

        {isVisure && (
          <h2 className="black-background-title">TRANSAZIONE ESEGUITA</h2>
        )}
        <div className="barcode-info">
          {isVisure && (
            <p>
              Se non hai dato una mail per scaricare il pdf puoi usare la ricerca
              dello scontrino su{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${window.location.origin}/#/transazioni/qrcodereader`}
              >{`${window.location.host}/#/transazioni/qrcodereader`}</a>{" "}
              {/* oppure eseguire scansione del qrcode in destra con il link del documento:
              <a
                href={`${window.location.origin}/#/qr/${barcode}`}
                target="_blank"
                rel="noopener noreferrer"
              >{`${window.location.host}/#/qr/${barcode}`}</a> */}
            </p>
          )}
          {arr.receipt_type === "json_spedizionepro" &&
            receipt_spedizioni.trackLink && (
              <div className="d-flex flex-column">
                <h3 className="tracklink">TRACK LINK</h3>
                <div className="tracklink">
                  {" "}
                  <p>
                    Se non hai dato una mail per scaricare il pdf puoi usare la
                    ricerca dello scontrino su services.bpoint.store/#/qrcodereader
                    oppure eseguire scansione del qrcode in destra con il link del
                    documento: services.bpoint.store/0000074395699
                  </p>{" "}
                  <img
                    className="qrCodeImg"
                    src={
                      receipt_spedizioni?.trackLink !== "" &&
                      receipt_spedizioni?.trackLink
                    }
                    alt="track link"
                  />
                </div>
              </div>
            )}
          {arr.receipt_type !== "json_spedizionepro" && (
            <img
              className="qrCodeImg"
              src={`https://api.qrserver.com/v1/create-qr-code/?data=https%3A%2F%2F${window.location.host}%2F%23%2Fqr%2F${barcode}&size=420x420&margin=10`}
              alt=""
            />
          )}
        </div>
        <div className="buttons">
          {arr.receipt_type === "JSON" &&
            !(arr.barcode || "").includes("000024") && (
              <DownloadVisure
                handleClick={() => this.props.getVisureDocument(barcode)}
              />
            )}
          {this.getPrintBtn()}
        </div>
        {arr.receipt_type === "json_bitnovo" && (
          <div className="bitnovo-footer">
            <h5>Come riscattare il PIN:</h5>
            <span className="separator" />
            <p>{`Accedi alla sezione Voucher sull’App Bitnovo o alla sezione Riscattare il Voucher sul sito www.bitnovo.com/it
- Inserisci il codice PIN ed il tuo Wallet
- Conferma l’operazione
Il PIN scade dopo 3 mesi dalla data di acquisto.
Il PIN non è rimborsabile. Si applicano tutti i termini su
www.bitnovo.com/it/termini-e-condizioni
Distributore: Giulida srl - P.Iva ${visureReceipt()["piva"] || "03109830590"}`}</p>
          </div>
        )}
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.print !== this.props.print) {
      document.querySelector(".printBtn").click()
    }
  }

  render() {
    const { arr, onlyForPrint } = this.props

    const barcode = Array.isArray(arr) ? arr[0]?.barcode : arr?.barcode || 1
    const receipt = Array.isArray(arr) ? arr[0]?.receipt : arr?.receipt
    //console.log("ca ka tickettt", barcode, receipt, arr);
    return (
      !!(barcode && receipt) &&
      (onlyForPrint ? (
        <div style={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}>
          {this.generateTicketBody(barcode, receipt)}
        </div>
      ) : (
        <Modal
          title={null}
          visible={
            arr.visible == "right" &&
            this.props.screenWidth > 1320 &&
            this.props.forAdmin
              ? false
              : true
          }
          onOk={this.handleOk}
          onCancel={() => {
            this.props.setPaymentsFromCode({})
            this.props.setPrint && this.props.setPrint(false)
            this.props.setBolletiniBianchi([])
            this.props.setShippingOrder({})
            //this.props.setSocketReceipt({});
          }}
          footer={null}
        >
          {this.generateTicketBody(barcode, receipt)}
        </Modal>
      ))
    )
  }
}

const mapsStateToProps = (state) => ({
  isShowing: state.main.isShowing,
  service_id: state.auth.service_id,
  skinExtras: state.auth.skinExtras,
  screenWidth: state.main.screenWidth,
  activeSkinId: state.main.activeSkinId,
  skins: state.auth.skinList,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  PrintTicket
)
