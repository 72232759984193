import React from "react"
import images from "themes/images"

const AdminWallet = ({ accountInfo }) => {
  if (accountInfo?.profile?.role?.name === "main_admin") {
    return (
      <div className="admin-wallets">
        <div className="admin-wallets-carousel">
          {Object.keys(accountInfo?.profile?.supplier_wallets || {}).map(
            (wallet, i) => {
              if (wallet === "FlashbetFlashpoints") return
              else
                return (
                  <div key={i}>
                    <img src={images[wallet]} />
                    {/* <span>{wallet} </span> */}
                    <span>
                      &euro; {accountInfo?.profile?.supplier_wallets?.[wallet]}
                    </span>
                  </div>
                )
            }
          )}
        </div>
      </div>
    )
  }
  return null
}

export default AdminWallet
