import React, { useEffect, useState } from "react"
import MySpan from "./MySpan"
import { connect } from "react-redux"
import { AuthActions } from "redux-store/models"
import LogoUploader from "./LogoUploader"

function NewStep1(props) {
  const { addEditSkin, addEditSkinDetails } = props
  const [step1, setStep1] = useState({
    nome_skin: "",
    url: "",
    upload_logo1: "",
    base64_upload_logo1: "",
    upload_logo2: "",
    base64_upload_logo2: "",
    upload_logo3: "",
    base64_upload_logo3: "",
    upload_logo4: "",
    base64_upload_logo4: "",
    mail: "",
    email: "",
    indirizzo: "",
    cel: "",
    link1: "",
    link2: "",
    link3: "",
    link4: "",
    link5: "",
    link6: "",
    fb: "",
    ig: "",
    pin: "",
    yt: "",
    accentBg: "000000",
    accentTxt: "000000",
    headerBg: "000000",
    headerTxt: "000000",
    footerBg: "000000",
    footerTxt: "000000",
    navigationColor: "000000",
    paginationColor: "000000",
    provider: "E-Pay",
  })

  const validateEmail = (mail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(mail)
  }

  const validateURL = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return urlRegex.test(url)
  }

  console.log(addEditSkin)

  const draggerProps = {
    name: "file",
    multiple: false,
    listType: "picture",
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const newStep1 = { ...step1, [name]: value }
    setStep1(newStep1)
    addEditSkinDetails({
      ...addEditSkin,
      step1: newStep1,
    })
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const validateFields = () => {
    const requiredFields = [
      "nome_skin",
      "url",
      "mail",
      "email",
      "indirizzo",
      "cel",
      "link1",
      "link2",
      "link3",
      "link4",
      "link5",
      "link6",
      "accentBg",
      "accentTxt",
      "headerBg",
      "headerTxt",
      "footerBg",
      "footerTxt",
      "navigationColor",
      "paginationColor",
      "provider",
    ]
    const newErrors = {}
    let hasError = false

    requiredFields.forEach((field) => {
      if (!step1[field]) {
        newErrors[field] = ["This field is required."]
        hasError = true
      } else if (field === "mail" || field === "email") {
        if (!validateEmail(step1[field])) {
          newErrors[field] = ["Invalid email address."]
          hasError = true
        }
      } else if (field === "url") {
        if (!validateURL(step1[field])) {
          newErrors[field] = ["Invalid URL."]
          hasError = true
        }
      }
    })

    return { newErrors, hasError }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { newErrors, hasError } = validateFields()

    if (hasError) {
      addEditSkinDetails({
        ...addEditSkin,
        errors: newErrors,
      })
    } else {
      console.log("No errors found, proceeding to next step.")
      addEditSkinDetails({
        ...addEditSkin,
        step1: step1,
        skinPannel: true,
        stepNumber: addEditSkin?.stepNumber + 1,
        errors: {},
      })
    }
  }

  const getErrorMessage = (field) => {
    if (addEditSkin?.errors && addEditSkin?.errors[field]) {
      return addEditSkin?.errors[field][0]
    }
    return ""
  }

  useEffect(() => {
    const skinDetails = addEditSkin?.skinDetails
    if (skinDetails && Object.keys(skinDetails).length > 0) {
      console.log("test")
      setStep1((prevStep1) => ({
        ...prevStep1,
        nome_skin: skinDetails.name || prevStep1.nome_skin,
        url: skinDetails.url || prevStep1.url,
        mail: skinDetails.extra?.mail || prevStep1.mail,
        email: skinDetails.email || prevStep1.email,
        cel: skinDetails.extra?.cel || prevStep1.cel,
        indirizzo: skinDetails.address || prevStep1.indirizzo,
        link1: skinDetails.extra?.link1 || prevStep1.link1,
        link2: skinDetails.extra?.link2 || prevStep1.link2,
        link3: skinDetails.extra?.link3 || prevStep1.link3,
        link4: skinDetails.extra?.link4 || prevStep1.link4,
        link5: skinDetails.extra?.link5 || prevStep1.link5,
        link6: skinDetails.extra?.link6 || prevStep1.link6,
        accentBg: skinDetails.extra?.accentBg || prevStep1.accentBg,
        accentTxt: skinDetails.accentTxt || prevStep1.accentTxt,
        headerBg: skinDetails.headerBg || prevStep1.headerBg,
        headerTxt: skinDetails.headerTxt || prevStep1.headerTxt,
        footerBg: skinDetails.extra?.footerBg || prevStep1.footerBg,
        footerTxt: skinDetails.footerTxt || prevStep1.footerTxt,
        navigationColor: skinDetails.navigationColor || prevStep1.navigationColor,
        paginationColor: skinDetails.paginationColor || prevStep1.paginationColor,
        provider: skinDetails.provider || prevStep1.provider,
        fb: skinDetails.extra?.fb || prevStep1.fb,
        ig: skinDetails.extra?.ig || prevStep1.ig,
        pin: skinDetails.extra?.pin || prevStep1.pin,
        yt: skinDetails.extra?.yt || prevStep1.yt,
      }))
    }

    if (addEditSkin?.step1) {
      setStep1((prevStep1) => ({
        ...prevStep1,
        ...addEditSkin.step1,
      }))
    }
  }, [addEditSkin])

  return (
    <div className="AdminLogin--Step1">
      <i
        className="fal fa-long-arrow-left"
        onClick={() => {
          addEditSkinDetails({
            ...addEditSkin,
            step1: step1,
            skinPannel: true,
            stepNumber: addEditSkin?.stepNumber - 1,
          })
        }}
      ></i>
      <div className="AdminLogin--Step1--Right">
        <div className="AdminLogin--Step1--Right--Important">
          <h1>INFORMAZIONI SITO SERVIZI</h1>
          <MySpan
            title="Nome skin"
            iconClass="fal fa-user"
            handleChange={handleChange}
            name="nome_skin"
            defaultValue={step1.nome_skin}
            error={getErrorMessage("nome_skin")}
          />
          <MySpan
            title="Link servizi"
            iconClass="fal fa-globe"
            handleChange={handleChange}
            name="url"
            defaultValue={step1.url}
            error={getErrorMessage("url")}
          />
          <MySpan
            title="E-mail"
            iconClass="fal fa-envelope"
            handleChange={handleChange}
            name="mail"
            defaultValue={step1.mail}
            error={getErrorMessage("mail")}
          />
          <MySpan
            title="Noreplay E-mail"
            iconClass="fal fa-envelope"
            handleChange={handleChange}
            name="email"
            defaultValue={step1.email}
            error={getErrorMessage("email")}
          />
          <MySpan
            title="Indirizzo"
            iconClass="fal fa-map-marker-alt"
            handleChange={handleChange}
            name="indirizzo"
            defaultValue={step1.indirizzo}
            error={getErrorMessage("indirizzo")}
          />
          <MySpan
            title="Telefono"
            iconClass="fal fa-mobile"
            handleChange={handleChange}
            name="cel"
            defaultValue={step1.cel}
            error={getErrorMessage("cel")}
          />
        </div>
        <div className="AdminLogin--Step1--Right--Skin">
          <h1>URL SITO DI PRESENTAZIONE</h1>
          <MySpan
            title="Home"
            iconClass="fal fa-globe"
            handleChange={handleChange}
            defaultValue={step1.link1}
            name="link1"
            error={getErrorMessage("link1")}
          />
          <MySpan
            title="Chi Siamo"
            iconClass="fal fa-globe"
            handleChange={handleChange}
            name="link2"
            defaultValue={step1.link2}
            error={getErrorMessage("link2")}
          />
          <MySpan
            title="Contatti"
            iconClass="fal fa-globe"
            defaultValue={step1.link3}
            handleChange={handleChange}
            error={getErrorMessage("link3")}
            name="link3"
          />
          <MySpan
            title="Servizi"
            defaultValue={step1.link4}
            handleChange={handleChange}
            name="link4"
            iconClass="fal fa-globe"
            error={getErrorMessage("link4")}
          />
          <MySpan
            title="Affiliazoni"
            iconClass="fal fa-globe"
            handleChange={handleChange}
            defaultValue={step1.link5}
            name="link5"
            error={getErrorMessage("link5")}
          />

          <MySpan
            title="Area download"
            iconClass="fal fa-globe"
            handleChange={handleChange}
            defaultValue={step1.link6}
            name="link6"
            error={getErrorMessage("link6")}
          />
        </div>
      </div>
      <div className="AdminLogin--Step1--Left">
        <div className="AdminLogin--Step1--Left--SkinColor">
          <h1>PROVIDER</h1>
          <div>
            <span>
              <select
                className="spanField"
                style={{
                  padding: "0px 10px",
                  borderColor: getErrorMessage("provider") && "red",
                }}
                onChange={handleChange}
                name="provider"
                defaultValue={step1.provider}
              >
                <option value="E-Pay">E-Pay</option>
                <option value="Mr-Pay">Mr-Pay</option>
                <option value="Sepafin">Sepafin</option>
              </select>
            </span>
            {getErrorMessage("provider") && (
              <div className="error-message" style={{ color: "red", fontSize: 12 }}>
                {getErrorMessage("provider")}
              </div>
            )}
          </div>
        </div>
        <div className="AdminLogin--Step1--Left--SkinLogo">
          <h1>LOGO</h1>
          <div className="AdminLogin--Step1--Left--SkinLogo--Fields">
            <LogoUploader
              draggerProps={draggerProps}
              step={step1}
              setStep={setStep1}
              toBase64={toBase64}
              fieldName="upload_logo1"
              labelText="Logo a colori"
              removeHandler={() => {
                setStep1((prevStep1) => ({
                  ...prevStep1,
                  upload_logo1: "",
                  base64_upload_logo1: "",
                }))
              }}
              errorMessage={getErrorMessage("upload_logo1")}
            />

            <LogoUploader
              draggerProps={draggerProps}
              step={step1}
              setStep={setStep1}
              toBase64={toBase64}
              fieldName="upload_logo2"
              labelText="Logo bianco"
              removeHandler={() => {
                setStep1((prevStep1) => ({
                  ...prevStep1,
                  upload_logo2: "",
                  base64_upload_logo2: "",
                }))
              }}
              errorMessage={getErrorMessage("upload_logo2")}
            />

            <LogoUploader
              draggerProps={draggerProps}
              step={step1}
              setStep={setStep1}
              toBase64={toBase64}
              fieldName="upload_logo3"
              labelText="Logo per scontrino"
              removeHandler={() => {
                setStep1((prevStep1) => ({
                  ...prevStep1,
                  upload_logo3: "",
                  base64_upload_logo3: "",
                }))
              }}
              errorMessage={getErrorMessage("upload_logo3")}
            />

            <LogoUploader
              draggerProps={draggerProps}
              step={step1}
              setStep={setStep1}
              toBase64={toBase64}
              fieldName="upload_logo4"
              labelText="Favicon"
              removeHandler={() => {
                setStep1((prevStep1) => ({
                  ...prevStep1,
                  upload_logo4: "",
                  base64_upload_logo4: "",
                }))
              }}
              errorMessage={getErrorMessage("upload_logo4")}
            />
          </div>
        </div>
        <div className="AdminLogin--Step1--Left--SkinColor">
          <h1>COLORI GLOBALI</h1>
          <div className="AdminLogin--Step1--Left--SkinColor--Inserts">
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    accentBg: colorValue,
                  }))
                }}
                value={`#${step1.accentBg}`}
              />
              <p>Accent background</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                name="head"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    accentTxt: colorValue,
                  }))
                }}
                value={`#${step1.accentTxt}`}
              />
              <p>Accent text</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    headerBg: colorValue,
                  }))
                }}
                value={`#${step1.headerBg}`}
              />
              <p>Header background</p>
            </div>

            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    headerTxt: colorValue,
                  }))
                }}
                value={`#${step1.headerTxt}`}
              />
              <p>Header text</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                name="head"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    footerBg: colorValue,
                  }))
                }}
                value={`#${step1.footerBg}`}
              />
              <p>Footer background</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    footerTxt: colorValue,
                  }))
                }}
                value={`#${step1.footerTxt}`}
              />
              <p>Footer text</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    navigationColor: colorValue,
                  }))
                }}
                value={`#${step1.navigationColor}`}
              />
              <p>Swiper navigation</p>
            </div>
            <div className="AdminLogin--Step1--Left--SkinColor--Inserts--Fields">
              <input
                type="color"
                onChange={(e) => {
                  const colorValue = e.target.value.replace("#", "")
                  setStep1((prevStep1) => ({
                    ...prevStep1,
                    paginationColor: colorValue,
                  }))
                }}
                value={`#${step1.paginationColor}`}
              />
              <p>Swiper pagination</p>
            </div>
          </div>
        </div>
        <div className="AdminLogin--Step1--Left--SkinColor">
          <h1>SOCIAL MEDIA</h1>
          <div className="AdminLogin--Step1--Left--SocialMedia--Inserts">
            <MySpan
              title="Facebook"
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.7"
                  height="15.4"
                  viewBox="0 0 7.7 15.4"
                >
                  <path
                    className="a"
                    d="M132.813,5.294V3.369a.963.963,0,0,1,.962-.962h.963V0h-1.925a2.887,2.887,0,0,0-2.887,2.887V5.294H128V7.7h1.925v7.7h2.887V7.7h1.925l.962-2.406Z"
                    transform="translate(-128)"
                  />
                </svg>
              }
              handleChange={handleChange}
              name="fb"
              error={getErrorMessage("fb")}
              defaultValue={step1.fb}
            />
            <MySpan
              title="Instagram"
              iconClass="fab fa-instagram"
              handleChange={handleChange}
              name="ig"
              defaultValue={step1.ig}
              error={getErrorMessage("ig")}
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.7"
                  height="15.4"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />{" "}
                </svg>
              }
            />
            <MySpan
              title="Pinterest"
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.704"
                  height="15.4"
                  viewBox="0 0 11.704 15.4"
                >
                  <path
                    className="a"
                    d="M1.832,8.368c.019-.048.212-.789.226-.839a5.175,5.175,0,0,1-.379-3.14c.277-1.767,2.149-2.635,3.916-2.635v0c2.213,0,3.869,1.233,3.871,3.353,0,1.66-1,3.854-2.706,3.854h0A2.435,2.435,0,0,1,5.176,8.41,1.213,1.213,0,0,1,5.008,7.3a14.684,14.684,0,0,0,.733-2.892A1.092,1.092,0,0,0,4.6,3.206,1.621,1.621,0,0,0,3.113,4.321a3,3,0,0,0,.018,2.186,14.207,14.207,0,0,1-.677,2.969C1.98,11.1,1.331,13.155,1.47,14.3L1.6,15.4l.673-.882A22.983,22.983,0,0,0,4.427,9.38a3.454,3.454,0,0,0,2.522,1.01A5.183,5.183,0,0,0,11.7,5.019C11.637,2.279,9.632,0,6.027,0V0C2.6,0,.6,1.833.109,4.145-.34,6.289.658,7.921,1.832,8.368Zm0,0"
                    transform="translate(0.001)"
                  />
                </svg>
              }
              handleChange={handleChange}
              name="pin"
              defaultValue={step1.pin}
              error={getErrorMessage("pin")}
            />
            <MySpan
              title="Youtube"
              iconClass="fab fa-youtube"
              handleChange={handleChange}
              name="yt"
              defaultValue={step1.yt}
              component={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-youtube"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />{" "}
                </svg>
              }
              error={getErrorMessage("yt")}
            />
          </div>
        </div>
        <div className="AdminLogin--Step1--Left--Submit">
          <button
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
            PASSO SUCCESSIVO
          </button>
        </div>
      </div>
    </div>
    //
  )
}

const mapsStateToProps = (state) => ({
  addEditSkin: state.auth.addEditSkin,
  newSkinId: state.auth.newSkinId,
  registerSkin: state.auth.registerSkin,
})

export default connect(mapsStateToProps, AuthActions)(NewStep1)
