import { createActions, createReducer } from "reduxsauce"

const { Types, Creators } = createActions({
  getServices: [],
  setServices: ["services"],
  toggleOverview: ["showOverview"],
  togglePopUp: ["isShowing"],
  setAccount: ["activeAccount"],
  setUsers: ["userList"],
  setSkinUsers: ["skinUserList"],
  setUsersSimple: ["userListSimple"],
  getUsers: [
    "search_user",
    "skin_id",
    "limit",
    "page_number",
    "LOAD_FALSE",
    "username",
    "ragione_sociale",
    "city",
    "user_id",
    "credito",
    "from_last_login",
    "to_last_login",
  ],
  getSkinUsers: ["skin_id"],
  getUsersSimple: [],
  getUsersBySearch: ["search_user"],
  setUsersBySearch: ["userListBySearch"],
  getOverviewDashboard: ["period"],
  setOverviewDashboard: ["dashboardData"],
  setSystemModes: ["systemModes"],
  setNavbarSearch: ["navbarSearch"],
  setScreenW: ["screenWidth"],
  setFavorites: ["favorites"],
  getFavorites: [],
  toggleFavorite: ["id", "sType"],
  updateSystemMode: ["sysmode", "password"],
  setActiveSkinId: ["activeSkinId"],
  setActiveSkinName: ["activeSkinName"],
  setLoaderForAdminUtenti: ["LoaderAU"],
  getUserPhotos: ["id"],
  getSystemModes: [],
  setPhotoLoading: ["photoLoading"],
  setUserPhotos: ["userPhotos"],
  getSearchedUsers: ["search_user"],
  sendPrenotazione: ["objectData", "resetState"],
  forgotPassword: ["email"],
  setAdminMessagesModal: ["adminMessagesModal"],
  getRegisterToken: ["agency_id", "callBack"],
  getSelectedCompany: ["service_id"],
  setSelectedCompany: ["selectedCompany"],
  getAllImages: ["group"],
  setAllImages: ["images"],
  uploadNewImage: ["fileList", "group"],
  updateImage: ["id", "name", "group"],
  deleteImage: ["id"],
  updateCompany: [
    "id",
    "logo",
    "backgroundImage",
    "icon",
    "logoScontrino",
    "colorBg",
    "colorTesto",
    "services",
    "content",
    "content_scontrino",
  ],
  getServicesData: [],
  setServicesData: ["servicesData"],
  getServicesTree: [],
  setServicesTree: ["servicesTree"],
  changeServiceCategory: ["companyId", "categoryId"]
})

export const MainTypes = Types
export default Creators

const INITIAL_STATE = {
  photoLoading: false,
  userPhotos: {},
  favorites: [],
  services: {},
  showOverview: true,
  isShowing: false,
  activeAccount: 2,
  userList: [],
  skinUserList: [],
  userListSimple: [],
  userListBySearch: [],
  dashboardData: {},
  systemModes: [],
  navbarSearch: "",
  activeSkinId: -1,
  activeSkinName: "",
  screenWidth: window.innerWidth,
  LoaderAU: false,
  adminMessagesModal: false,
  selectedCompany: {},
  images: [],
  servicesData: [],
  servicesTree: []
}

export const reducer = createReducer(INITIAL_STATE, {
  SET_ADMIN_MESSAGES_MODAL: (state) => ({
    ...state,
    adminMessagesModal: !state.adminMessagesModal,
  }),
  SET_PHOTO_LOADING: (state, payload) => {
    return {
      ...state,
      photoLoading: payload.photoLoading,
    }
  },
  SET_USER_PHOTOS: (state, { userPhotos }) => ({
    ...state,
    userPhotos,
  }),
  SET_FAVORITES: (state, { favorites }) => ({
    ...state,
    favorites,
  }),
  SET_NAVBAR_SEARCH: (state, { navbarSearch }) => ({
    ...state,
    navbarSearch,
  }),
  SET_SCREEN_W: (state, { screenWidth }) => ({
    ...state,
    screenWidth,
  }),
  SET_OVERVIEW_DASHBOARD: (state, { dashboardData }) => ({
    ...state,
    dashboardData,
  }),
  SET_SYSTEM_MODES: (state, { systemModes }) => ({
    ...state,
    systemModes,
  }),
  TOGGLE_OVERVIEW: (state, { showOverview }) => ({
    ...state,
    showOverview,
  }),
  SET_ACCOUNT: (state, { activeAccount }) => ({
    ...state,
    activeAccount,
  }),
  SET_SERVICES: (state, { services }) => ({
    ...state,
    services,
  }),
  SET_USERS: (state, { userList }) => ({
    ...state,
    userList,
  }),
  SET_SKIN_USERS: (state, { skinUserList }) => ({
    ...state,
    skinUserList,
  }),
  SET_USERS_SIMPLE: (state, { userListSimple }) => ({
    ...state,
    userListSimple,
  }),
  SET_USERS_BY_SEARCH: (state, { userListBySearch }) => ({
    ...state,
    userListBySearch,
  }),
  TOGGLE_POP_UP: (state, { isShowing }) => ({
    ...state,
    isShowing,
  }),
  SET_ACTIVE_SKIN_ID: (state, { activeSkinId }) => ({
    ...state,
    activeSkinId,
  }),
  SET_ACTIVE_SKIN_NAME: (state, { activeSkinName }) => ({
    ...state,
    activeSkinName,
  }),
  SET_LOADER_FOR_ADMIN_UTENTI: (state, { LoaderAU }) => ({
    ...state,
    LoaderAU,
  }),
  SET_SELECTED_COMPANY: (state, { selectedCompany }) => ({
    ...state,
    selectedCompany,
  }),
  SET_ALL_IMAGES: (state, { images }) => ({
    ...state,
    images,
  }),
  SET_SERVICES_DATA: (state, { servicesData }) => ({
    ...state,
    servicesData,
  }),
  SET_SERVICES_TREE: (state, { servicesTree }) => ({
    ...state,
    servicesTree,
  }),
})
