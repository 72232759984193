import React, { useEffect, useCallback, useMemo, useState } from "react";
import images from "themes/images";
import { connect } from "react-redux";
import { Modal, notification } from "antd";
import ReactToPrint from "react-to-print";
import { AuthActions, MainActions} from "redux-store/models"
import { Document, Page } from "react-pdf";
import "./pagoPaConfirm.css";
import { b64toBlob } from "utils";
import ConfirmPaymentModal from "./ConfirmPaymentModal";
import _ from "lodash";

class PrintTicketSerap extends React.Component {
  state = {
    visible: true,
    fattureVis: false,
    file: null,
  };

  componentWillUnmount = () => {
    document.body.classList.remove(`sepa-ticket`);
  };
  printPdfReceipt = (data, type) => {
    if (data.receipt_type === "base64") {
      var myBlob = b64toBlob(data.receipt, "application/pdf");
      var blobUrl = URL.createObjectURL(myBlob);
      if (type === "print") {
        window
          .open(blobUrl, "_blank", "toolbar=no,scrollbars=no,resizable=no,top=50,left=500,width=700,height=700")
          .print();
      }
      if (type === "download") {
        const linkSource = `data:application/pdf;base64,${data.receipt}`;
        const downloadLink = document.createElement("a");
        const fileName = "Ticket.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      if (type === "return") {
        return blobUrl;
      }
    }
  };

  CopyToClipboard = () => {
    navigator.clipboard.writeText(this.props.bolletiniBianchi.barcode);
    notification["success"]({
      message: "Il barcode è stato copiato con successo",
      placement: "topRight",
    });
  };

  componentDidMount() {
    document.body.classList[this.isBase64() || this.isConfimation() ? "add" : "remove"](`sepa-ticket`);
    this.setState({
      file: this.printPdfReceipt(this.props.bolletiniBianchi, "return"),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.bolletiniBianchi, this.props.bolletiniBianchi)) {
      document.body.classList[this.isBase64() || this.isConfimation() ? "add" : "remove"](`sepa-ticket`);
      this.setState({
        file: this.printPdfReceipt(this.props.bolletiniBianchi, "return"),
      });
    }
  }

  isBase64 = () => this.props.bolletiniBianchi?.receipt_type === "base64";
  isConfimation = () => {
    const { pagamento_id, CheckVerificationDebtPositionId } = this.props.bolletiniBianchi || {};
    return [pagamento_id, CheckVerificationDebtPositionId].some((prop) => prop !== undefined);
  };

  close = () => {
    this.setState({ visible: false });
    this.props.setBolletiniBianchi(null);
  };

  pay = () => {
    this.props.pagoTicket(this.props.bolletiniBianchi.barcode,null,this.props.accountInfo.profile.username);
  };

  confirmPayment = (isPos) => {
    const { service_id, setPayFSaga, payPagoPa, setBolletiniBianchi, bolletiniBianchi , accountInfo } = this.props;
    const { TotalAmount, pagamento_id, FeesAmount, fee, importo } = bolletiniBianchi || {};
    if (service_id === "SPAGF24" || pagamento_id) {
      setPayFSaga(service_id, importo, fee, pagamento_id, isPos , accountInfo.profile.username);
    } else {
      payPagoPa(service_id, TotalAmount, FeesAmount, bolletiniBianchi.CheckVerificationDebtPositionId, isPos , accountInfo.profile.username);
    }
    setBolletiniBianchi(null);
  };

  confirmPaymentWithoutPos = () => this.confirmPayment(false);
  confirmPaymentPos = (e, pos) => this.confirmPayment(pos);

  cancelPayment = () => {
    const { pagamento_id, CheckVerificationDebtPositionId } = this.props.bolletiniBianchi || {};
    const pagamentoId = pagamento_id || CheckVerificationDebtPositionId;
    if (this.props.service_id === "SPAGF24") {
      this.close();
    } else {
      this.props.cancelPagamento(pagamentoId, this.props.service_id, this.close);
    }
  };

  render() {
    const { bolletiniBianchi, skinExtras, screenWidth } = this.props;
    const {
      receipt_type,
      paymentNotFinished,
      TotalAmount,
      CheckVerificationDebtPositionId,
      pagamento_id,
      Amount,
      FeesAmount,
      fee,
      importo,
      receipt,
    } = bolletiniBianchi || {};
    const { visible } = this.state;
    const isBase64 = receipt_type === "base64" || this.isConfimation();
    const base64Booking = isBase64 && paymentNotFinished;
    return (
      <Modal
        title={null}
        visible={visible}
        className={`${isBase64 ? "base64" : ""} ${this.isConfimation() ? "is-confirm" : ""} ${
          CheckVerificationDebtPositionId ? "Conferma" : ""
        }`}
        onOk={this.close}
        onCancel={this.close}
        footer={null}
      >
        {bolletiniBianchi &&
          (receipt && !pagamento_id && !fee ? (
            <div className="printModal aaa" >
              <div className="body" style={isBase64 ? { overflow: "auto" } : {}}>
                {!isBase64 && (
                  <div className="headerModal" ref={(el) => (this.componentRef = el)}>
                    <img className="logo" src={images.logo} alt="" />
                    <span className="superSmall text-bold">
                      <span> {skinExtras.account_name} </span>
                    </span>
                    <span className="superSmall">{skinExtras.address}</span>
                    <span className="superSmall link">{skinExtras.email}</span>
                    <span className="superSmall ">Tel: {skinExtras.cel}</span>
                    <span className="superSmall tel">P.IVA 03852290406</span>
                    <span className="fontSmall text-bold">{bolletiniBianchi.agency_name}</span>
                    <span className="fontSmall address">{bolletiniBianchi.agency_address}</span>
                  </div>
                )}
                {isBase64 ? (
                  <div ref={(el) => (this.componentRef = el)}>
                    <div className="base64-barcode-body">
                      {/* <h4>{`Gentile ${bolletiniBianchi.agency_name} ,`}</h4>
                  <h4>La invitiamo a stampare questo documento e portarlo con se in agenzia per perfezionare il pagamento</h4>
                  <h2>RIEPILOGO PRENOTAZIONE</h2> */}

                      {/* <img src={images["sepafinInst"]}/> */}
                      {/* <div className="show-hide-fatture" onClick={()=>{this.setState({fattureVis:!this.state.fattureVis})}}>
                    <span>{`Dettaglio prenotazione`}</span>
                    <i className="far fa-file-invoice"></i>
                  </div>
                  */}
                  <div className={`animate-height show is-visible`}> 
                      <div className={`animate-height is-visible`} id="canva-to-copy">
                        <Document renderMode="canvas" file={this.state.file}>
                          {screenWidth < 600 ? (<Page size="A4" pageNumber={1} />) : (<Page width={700} pageNumber={1} />)}
                        </Document>
                      </div>
                  </div>
                 {/*
                  <h2>TOTALE PRENOTAZIONE AL NETTO DELLA COMMISIONE: € 2,00</h2>
                  <h2>ATTENZIONE </h2>
                  <h4 className="text-align-center">Il presente documento non costituisce Ricevuta o Attewstazione di pagamento non essendo anocora le</h4>
                  <h4 className="text-align-center">operazioni materialmente eseguite .</h4>
                  <h4 className="text-align-center">Le prenotazione ha viladita di 24h dalla data di generazione del codice, trascorse le quali senza che sia</h4>
                  <h4 className="text-align-center">processatam sara anullata ed occorra se del caso riseguirla</h4>
                  <h4 className="text-align-center">Per consultare le oprazioni effettuate la invitiamo a consultare il sito "YAPPAY.IT"</h4>
                  </div> */}

                      {/* <div
                    className="printBtn"
                    onClick={() => {
                      this.printPdfReceipt(bolletiniBianchi, "print")
                    }}
                  >
                    Print Ticket
                  </div>
                  <div
                    className="printBtn"
                    onClick={() => {
                      this.printPdfReceipt(bolletiniBianchi, "download")
                    }}
                  >
                    Download Ticket
                  </div> */}
                    </div>
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: receipt
                        // .replace(/</g, "&lt;")
                        // .replace(/>/g, "&gt;")
                        .replace(/\t/g, "\u00a0")
                        .replace(/\n/g, "<br/> ")
                        .replace(/\+/g, " ")
                        .replace(/: /g, ":<div class='marginB'></div>")
                        .replace(
                          /<div class='marginB'><\/div>([^>]+)<br\/>/g,
                          "<div class='marginB'></div><div class='marginC'>$1</div><br/>"
                        ),
                    }}
                  />
                )}
                <img
                  className="qrCodeImg"
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=https%3A%2F%2F${window.location.host}%2F%23%2Fqr%2F${bolletiniBianchi.barcode}&size=420x420&margin=10`}
                  alt=""
                />
                <img
                  className="barcodeModal"
                  src={`https://barcode.tec-it.com/barcode.ashx?data=${bolletiniBianchi.barcode}&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0`}
                  alt=""
                />
                {!isBase64 && (
                  <ReactToPrint
                    trigger={() => <div className="printBtn">Print</div>}
                    content={() => this.componentRef}
                    bodyClass="afterprint"
                  />
                )}
                {bolletiniBianchi.paymentType?.includes("Prenotazione") && (
                  <div onClick={this.pay} className="printBtn">
                    Pago
                  </div>
                )}
              </div>

              {isBase64 && (
                <div className="Bolletini new" style={{position : base64Booking ? 'static' : 'sticky'}}>
                  <div className="Actions">
                    {base64Booking ? (
                      <PagaButton onClick={this.pay} />
                    ) : (
                      // <ReactToPrint
                      //   trigger={() => (
                      //     <div className="Actions--Item">
                      //       <h3>Stampa</h3>
                      //       <i className="fal fa-print"></i>
                      //     </div>
                      //   )}
                      //   content={() => this.componentRef}
                      //   bodyClass={`afterprint ${isBase64 ? "base-64" : ""}`}
                      // />
                           <div className="Actions--Item" onClick={()=> window.open(this.state.file, "_blank").focus()}>
                            <h3>Stampa</h3>
                            <i className="fal fa-print"></i>
                          </div>
                    )}
                    {base64Booking && this.props.accountInfo.profile.role.name !== "user" ? (
                      <PagaPosButton onClick={(e, model) => this.props.pagoTicket(bolletiniBianchi.barcode, model,this.props.accountInfo.profile.username)} />
                    ) : (
                      <div className="Actions--Item" onClick={this.CopyToClipboard}>
                        <h3>Copia</h3>
                        <i className="fal fa-copy"></i>
                      </div>
                    )}
                    <AnullaButton onClick={this.close} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="printModal confirmMdoal aaa" ref={(el) => (this.componentRef = el)}>
              <ConfirmPaymentModal
                pay={this.confirmPaymentWithoutPos}
                payPos={this.confirmPaymentPos}
                cancel={this.cancelPayment}
                importo={importo || Amount}
                commissione={fee || FeesAmount}
                totale={TotalAmount || parseFloat(importo) + parseFloat(fee)}
                accountInfo={this.props.accountInfo}
              />
            </div>
          ))}
      </Modal>
    );
  }
}

const Action = (props) => {
  const { onClick, title, src, icon, otherIconProps, ...otherProps } = props;
  return (
    <div className="Actions--Item" onClick={onClick} {...otherProps}>
      <h3>{title}</h3>
      {src ? (
        <img src={src} {...(otherIconProps || {})} alt="" />
      ) : (
        <i className={`fal fa-${icon}`} {...(otherIconProps || {})}></i>
      )}
    </div>
  );
};

export const PagaButton = ({ onClick }) => <Action onClick={onClick} title="Paga" src={images["pagaSvg"]} />;

const mpstpPos = (state) => ({
  agencyPosesAvailable: state.auth.agencyPosesAvailable,
  accountInfo : state.auth.accountInfo
});
export const PagaPosButton = connect(
  mpstpPos,
  AuthActions
)(({ onClick, getAgencyPos, agencyPosesAvailable, setAgencyPos , accountInfo }) => {
  const [visible, setVisible] = useState(false);
  const filterPoses = useMemo(
    () => (agencyPosesAvailable ? (agencyPosesAvailable || []).filter((pos) => pos.StateDescr === "Attivo") : null),
    [agencyPosesAvailable]
  );
  const hasPoses = useCallback(() => filterPoses && filterPoses[0], [filterPoses]);
  const getFirstPos = useCallback(() => hasPoses() && filterPoses[0].PosCode, [hasPoses, filterPoses]);
  const [posActive, setPosActive] = useState(getFirstPos());

  useEffect(() => {
    setPosActive(getFirstPos());
    return () => {
      setAgencyPos(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVisible( (!!filterPoses));
        setPosActive(getFirstPos());
  }, [filterPoses, getFirstPos]);

  const handleClick = async (e) => {
    await onClick(e, posActive);
    await setAgencyPos(null);
  };
  return (
    <React.Fragment>
      <Modal title={null} visible={visible} onCancel={() => setVisible(false)} footer={null}>
        <div className="pos">
          <div className="pos__title">Seleziona POS</div>
          <div className="pos_bill-contianer">
            {(filterPoses || []).map(({ PosModel, PosCode }) => {
              const currentModel = PosModel.toUpperCase();
              const imgModel = currentModel in images ? currentModel : "A920";
              return (
                <div
                  onClick={() => setPosActive(PosCode)}
                  className={`pos__bill ${posActive === PosCode ? "is-active" : ""}`}
                  key={PosCode}
                >
                  <img src={images[imgModel]} alt=""/>
                  <span>{`${currentModel} - ${PosCode}`}</span>
                </div>
              );
            })}
          </div>
          <button onClick={handleClick}>Paga</button>
        </div>
      </Modal>
      <Action onClick={() => getAgencyPos(this.props.accountInfo.profile.username)} title="Paga POS" src={images["pagaPos"]} htmltype="submit" />
    </React.Fragment>
  );
});

export const AnullaButton = ({ onClick }) => {
  return <Action onClick={onClick} title="Annulla" src={images.close} otherIconProps={{ className: "x" }} />;
};

const mpstp = (state, props) => {
  const {
    auth: { bolletiniBianchi, skinExtras, service_id , accountInfo},
  } = state;
  return {
    bolletiniBianchi: props.bolletiniBianchi || bolletiniBianchi,
    skinExtras,
    service_id: props.service_id
      ? props.service_id?.service_id || props.service_id
      : service_id?.service_id || service_id,
      screenWidth: state.main.screenWidth,
      accountInfo
  };
};
export default connect(mpstp, {...MainActions,...AuthActions})(PrintTicketSerap);
