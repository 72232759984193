import React, { useState } from "react"
import { connect } from "react-redux"
import { Loader } from "shared-components"
import images from "themes/images"
import "./styles.css"
import { Button, Icon } from "antd"

const AreaDownload = ({ link6, skinExtras }) => {
  const [showIframe, setShowIframe] = useState(false)
  const links = [
    {
      title: "Poster Pagamento Bollettini",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-bollettini-mrpay.pdf",
    },
    {
      title: "Poster Ricariche Nazionali",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-ricariche-nazionali-mr-pay.pdf",
    },
    {
      title: "Poster Ricariche Conto Gioco",
      link: "https://mrpay.it/wp-content/uploads/2023/04/poster-ricariche-conto-gioco-mrpay-new.pdf",
    },
    {
      title: "Poster Ricariche Internazionali",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-ricariche-internazionali-mrpay.pdf",
    },
    {
      title: "Poster Gift Card",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-gift-card.pdf",
    },
    {
      title: "Poster Ricariche TV",
      link: "https://mrpay.it/wp-content/uploads/2023/04/poster-tv-mrpay-new.pdf",
    },
    {
      title: "Poster Mr Express",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-spedizioni-mrpay.pdf",
    },
    {
      title: "Poster Vendo Crypto",
      link: "https://mrpay.it/wp-content/uploads/2023/04/poster-vendocrypto-mrpay-new.pdf",
    },
    {
      title: "Poster Crypto Merchant",
      link: "https://mrpay.it/wp-content/uploads/2022/01/poster-cryptomerchant-mrpay.pdf",
    },
    {
      title: "Adesivo MrPay 1",
      link: "https://mrpay.it/wp-content/uploads/2021/07/adesivo-mrpay1.pdf",
    },
    {
      title: "Adesivo MrPay 2",
      link: "https://mrpay.it/wp-content/uploads/2021/07/adesivo-mrpay2.pdf",
    },
    {
      title: "Adesivo Vendo Crypto",
      link: "https://mrpay.it/wp-content/uploads/2021/07/adesivo-vendocrypto-mrpay.pdf",
    },
    {
      title: "Adesivo Crypto Merchant",
      link: "https://mrpay.it/wp-content/uploads/2021/07/adesivo-cryptomerchant-mrpay.pdf",
    },
  ]

  return (
    <div className="area-download maxWidth">
      <h4>Scarica le grafiche {skinExtras.name}</h4>
      <div className="description">
        <span>Le nostre esclusive grafiche in formato PDF!</span>
        <span>
          Questi file sono ideati per la Vostra comunicazione visiva. Le nostre
          grafiche sono ad alta risoluzione e pronte all'uso.
        </span>
      </div>
      <div className="download-links">
        {links.map((link, i) => (
          <div className="link-container" key={i}>
            <a href={link.link} target="_blank">
              <div>
                <span className="link">{link.title}</span>
                <div>
                  <div className="file-pdf">
                    {/* <Icon type="file-pdf" /> */}
                    <img src={images["pdf-icon"]} />
                    <span>Pdf</span>
                  </div>

                  {/* <div className="file-size">
                    <span>2.8 mb</span>
                  </div> */}

                  <Button icon="upload">Scarica</Button>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>

    // <React.Fragment>
    //   <iframe
    //     id="the_iframe"
    //     title="area_download_frame"
    //     onLoadCapture={() => {
    //       setShowIframe(true)
    //     }}
    //     className={`${showIframe ? "" : "d-none"} maxWidth`}
    //     frameBorder="0"
    //     src={
    //       link6
    //         ? link6 + "?footer=false&header=false"
    //         : "https://bpoint.store/area-marketing/?footer=false&header=false"
    //     }
    //   />
    //   {showIframe === false && (
    //     <div className="LoadContainer">
    //       <Loader />
    //     </div>
    //   )}
    // </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  link6: state.auth.skinExtras.link6,
  skinExtras: state.auth.skinExtras,
})
export default connect(mapStateToProps)(AreaDownload)
