import React from "react";

const CompaniesSearch = ({ categoriesTypeSelected, isSepafin, hasVPT, searchRef, onFocusHandler, debounceSearch }) => {
  return (
    <div className="Search">
      {categoriesTypeSelected === "PRDPST" && isSepafin && (
        <span
          onClick={() => {
            hasVPT(true);
          }}
        >
          CERCA PRENOTAZIONE
        </span>
      )}
      <input
        type="text"
        placeholder="Cerca"
        ref={searchRef}
        onFocus={onFocusHandler} 
        onChange={debounceSearch}
      />
      <i className="fal fa-search" />
    </div>
  );
};

export default CompaniesSearch;
