import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import moment from "moment"
import { Icon, Table } from "antd"
import "antd/dist/antd.css"
import { Input, Button } from "antd"

class AnnunciAdmin extends React.Component {
  constructor() {
    super()
    this.state = {
      tabFilter: "",
      expanded: [],
      modal: false,
      unread: [],
      searchText: "",
      searchedColumn: "",
      fileredInfo: null,
    }
  }

  hideModal = () => {
    document.body.style.overflow = ""
    this.setState({ modal: false })
  }
  showModal = () => {
    document.body.style.overflow = "hidden"
    this.setState({ modal: true })
  }

  selectTab = (payload) => {
    this.setState({ tabFilter: payload })
  }

  componentDidMount() {
    this.props.getAds()
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: "" })
  }

  expandRowFunc = (m) => {
    if (this.props.screenWidth > 815) {
      return m.text.includes("{") &&
        m.text.includes("}") &&
        m.categoria === "Popup" ? (
        <div
          style={{ width: "300px", margin: "auto" }}
          className="content"
          dangerouslySetInnerHTML={{ __html: JSON.parse(m.text).content }}
        ></div>
      ) : (
        <strong>{m.text}</strong>
      )
    } else if (this.props.screenWidth > 415) {
      return (
        <div>
          <p>
            Tipologia : <strong>{m.tipologia}</strong>
          </p>
          <p>
            Tempo :{" "}
            <strong>
              {m.start} - {m.stop}
            </strong>
          </p>
          <p>
            Messagio :{" "}
            {m.text.includes("{") &&
            m.text.includes("}") &&
            m.categoria === "Popup" ? (
              <div
                style={{ width: "300px", margin: "auto" }}
                className="content"
                dangerouslySetInnerHTML={{ __html: JSON.parse(m.text).content }}
              ></div>
            ) : (
              <strong>{m.text}</strong>
            )}
          </p>
        </div>
      )
    } else {
      let value = (
        <div>
          <p>
            Categoria : <strong>{m.categoria}</strong>
          </p>
          <p>
            Tipologia : <strong>{m.tipologia}</strong>
          </p>
          <p>
            Tempo :{" "}
            <strong>
              {m.start} - {m.stop}
            </strong>
          </p>
          <p>
            Messagio : <strong>{m.text}</strong>
          </p>
        </div>
      )
      return m.text.includes("{") &&
        m.text.includes("}") &&
        m.categoria === "Popup" ? (
        <div
          style={{ width: "300px", margin: "auto" }}
          className="content"
          dangerouslySetInnerHTML={{ __html: JSON.parse(m.text).content }}
        ></div>
      ) : (
        value
      )
    }
  }

  customExpandIcon(props) {
    if (props.expanded) {
      return (
        <a
          style={{
            color:
              props.record.categoria === "Privata"
                ? "#0092ff"
                : props.record.categoria === "Informazione"
                ? "#ff6f00"
                : props.record.categoria === "Privata"
                ? "#0092ff"
                : props.record.categoria === "Cancelazione"
                ? "#ff0000"
                : props.record.categoria === "Nuovo Prodotto"
                ? "#00b850"
                : "",
          }}
          onClick={(e) => {
            props.onExpand(props.record, e)
          }}
        >
          {/* <Icon type="down" /> */}
          <i className="fas fa-chevron-circle-down"></i>
        </a>
      )
    } else {
      return (
        <a
          style={{
            color:
              props.record.categoria === "Privata"
                ? "#0092ff"
                : props.record.categoria === "Informazione"
                ? "#ff6f00"
                : props.record.categoria === "Privata"
                ? "#0092ff"
                : props.record.categoria === "Cancelazione"
                ? "#ff0000"
                : props.record.categoria === "Nuovo Prodotto"
                ? "#00b850"
                : "",
          }}
          onClick={(e) => {
            props.onExpand(props.record, e)
          }}
        >
          {/* <Icon type="right" /> */}
          <i className="fas fa-chevron-circle-right"></i>
        </a>
      )
    }
  }

  render() {
    const { ads, accountInfo, forAdmin, screenWidth } = this.props

    let adsFiltered =
      this.state.tabFilter === ""
        ? Object.values(ads).sort((a, b) => b.id - a.id)
        : this.state.tabFilter === "4"
        ? Object.values(ads)
            .filter(
              (m) =>
                m.importance === this.state.tabFilter ||
                m.importance === "4_false" ||
                m.importance === "4_true"
            )
            .sort((a, b) => b.id - a.id)
        : this.state.tabFilter === "5"
        ? Object.values(ads)
            .filter(
              (m) =>
                m.importance === this.state.tabFilter ||
                m.importance === "5_false" ||
                m.importance === "5_true"
            )
            .sort((a, b) => b.id - a.id)
        : Object.values(ads)
            .filter((m) => m.importance === this.state.tabFilter)
            .sort((a, b) => b.id - a.id)

    var columns =
      screenWidth > 815
        ? [
            {
              title: "Titolo",
              dataIndex: "titolo",
              key: "titolo",
              ...this.getColumnSearchProps("titolo"),
              width: "25%",
              ellipsis: true,
            },
            {
              title: "Categoria",
              dataIndex: "categoria",
              key: "categoria",
              align: "center",
              filters: [
                { text: "Privata", value: "Privata" },
                { text: "Cancellazione", value: "Cancellazion" },
                { text: "Informazione", value: "Informazione" },
                { text: "Nuovo Prodotto", value: "Nuovo Prodotto" },
                { text: "Popup", value: "Popup" },
              ],
              onFilter: (value, record) => record.categoria.indexOf(value) === 0,
              // width : "10%",
              ellipsis: true,
            },
            {
              title: "Tipologia",
              dataIndex: "tipologia",
              filters: [
                { text: "HTML", value: "HTML" },
                { text: "Testo", value: "Testo" },
              ],
              filterMultiple: false,
              onFilter: (value, record) => record.tipologia.indexOf(value) === 0,
              ellipsis: true,
              align: "center",
              render: (text, record) => {
                let color;
                if (record.tipologia === "HTML") {
                  color = "#FF6F00"; 
                } else if (record.tipologia === "Testo") {
                  color = "#0078FF"; 
                }
                return <span style={{ color }}>{text}</span>;
              },
              // width : "10%"
            },
            {
              title: "Utente",
              dataIndex: "utente",
              key: "utente",
              ...this.getColumnSearchProps("utente"),
              ellipsis: true,
              align: "center",
              // width : "15%"
            },
            {
              title: "Start",
              dataIndex: "start",
              key: "start",
              sorter: (a, b) =>
                moment(a.start, "DD/MM/YYYY HH:mm").unix() -
                moment(b.start, "DD/MM/YYYY HH:mm").unix(),
              ellipsis: true,
              align: "center",
              // width : "15%"
            },
            {
              title: "Stop",
              dataIndex: "stop",
              key: "stop",
              sorter: (a, b) =>
                moment(a.stop, "DD/MM/YYYY HH:mm").unix() -
                moment(b.stop, "DD/MM/YYYY HH:mm").unix(),
              ellipsis: true,
              align: "center",
              // width : "15%"
            },
          ]
        : screenWidth > 415
        ? [
            {
              title: "Titolo",
              dataIndex: "titolo",
              key: "titolo",
              ...this.getColumnSearchProps("titolo"),
              width: "40%",
              ellipsis: true,
            },
            {
              title: "Categoria",
              dataIndex: "categoria",
              key: "categoria",
              filters: [
                { text: "Privata", value: "Privata" },
                { text: "Cancellazione", value: "Cancellazion" },
                { text: "Informazione", value: "Informazione" },
                { text: "Nuovo Prodotto", value: "Nuovo Prodotto" },
                { text: "Popup", value: "Popup" },
              ],
              onFilter: (value, record) => record.categoria.indexOf(value) === 0,
              width: "30%",
              ellipsis: true,
            },
            {
              title: "Utente",
              dataIndex: "utente",
              key: "utente",
              ...this.getColumnSearchProps("utente"),
              width: "30%",
            },
          ]
        : [
            {
              title: "Titolo",
              dataIndex: "titolo",
              key: "titolo",
              ...this.getColumnSearchProps("titolo"),
              ellipsis: true,
              width: "60%",
            },
            {
              title: "Utente",
              dataIndex: "utente",
              key: "utente",
              ...this.getColumnSearchProps("utente"),
              width: "40%",
            },
          ]

    if (accountInfo.profile.role === "agent") {
      columns = columns.filter(function (value, index, arr) {
        if (value.dataIndex !== "utente") {
          return value
        }
      })
    }

    var data = adsFiltered.map((m) => ({
      // key: m.id,
      titolo: m.title,
      categoria:
        m.importance === "1"
          ? "Nuovo Prodotto"
          : m.importance === "2"
          ? "Informazione"
          : m.importance === "3"
          ? "Cancellazione"
          : (m.importance === "4") | (m.importance === "4_false") ||
            m.importance === "4_true"
          ? "Privata"
          : (m.importance === "5") | (m.importance === "5_false") ||
            m.importance === "5_true"
          ? "Popup"
          : "",
      tipologia:
        m.text.includes("{") &&
        m.text.includes("}") &&
        ((m.importance === "5") | (m.importance === "5_false") ||
          m.importance === "5_true")
          ? "HTML"
          : "Testo",
      utente: m.user || "Tutti",
      start: moment(m.updated_at).format("DD/MM/YYYY HH:mm"),
      stop: moment(m.updated_at).format("DD/MM/YYYY HH:mm"),
      text: m.text,
      importance: m.importance,
      id: m.id,
    }))
    return (
      <div className="Container Annunci" style={{ width: "100%" }}>
        {this.props.MessaggiComponent}
        {/* <Overview></Overview> */}
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="sort-annunci adminAnnunci">
              <h1 className=" headerTop">ANNUNCI</h1>
              <div style={{ display: "flex", margin: "0px 30px" }}>
                <Button
                  onClick={() =>
                    forAdmin ? this.props.setCreaMessaggi(true) : this.showModal
                  }
                  className="creareMessagioBtn"
                  type="default"
                >
                  <i className="fal fa-plus-circle"></i>
                CREA ANNUNCI
                </Button>
              </div>
            </div>

            <Table
              rowClassName={(record, index) => record.importance === "4_false" ? "is-warning-row" : ""}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    if (record.importance === "4_false")
                      this.props.readAds(record.id)
                  },
                }
              }}
              style={{ width: "100%" }}
              columns={columns}
              // pagination={false}
              pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50"],
              }}
              showHeader={screenWidth > 815 ? true : false}
              expandIcon={(props) => this.customExpandIcon(props)}
              dataSource={data}
              expandRowByClick={true}
              expandedRowRender={(m) => this.expandRowFunc(m)}
              rowKey={(record, i) => record.id.toString() + i.toString()}
              loading={ads.length === 0}
            />
          </div>
        </div>
      </div>
    )
  }
}

const rowClassName = (index, adsFiltered) => {
  if (adsFiltered?.[index]?.importance === "4_false") return "is-warning-row"
}

// class AddAds extends React.Component {
//   handleSubmit = (e) => {
//     e.preventDefault()
//     this.props.form.validateFields((err, values) => {
//       if (!err) {
//         this.props.createAds(values)
//         setTimeout(() => {
//           if (this.props.adsCreationgMess.errors) {
//             this.props.form.resetFields()
//           } else {
//             this.props.getAds()
//             this.props.hideModal()
//           }
//         }, 3000)
//       }
//     })
//   }

//   render() {
//     let { adsCreationgLoading, adsCreationgMess } = this.props
//     const { getFieldDecorator } = this.props.form
//     return (
//       <div>
//         <Form onSubmit={this.handleSubmit}>
//           <Form.Item label="Categoria Annuncio">
//             {getFieldDecorator("importance", {
//               rules: [
//                 {
//                   required: true,
//                   message: "Please select ads type",
//                 },
//               ],
//             })(
//               <Radio.Group>
//                 <Radio value={3}>Cancellazione prodotto</Radio>
//                 <Radio value={2}>Informazione</Radio>
//                 <Radio value={1}>Nuovo Prodotto</Radio>
//               </Radio.Group>
//             )}
//           </Form.Item>
//           <Form.Item label="Titolo">
//             {getFieldDecorator("title", {
//               rules: [
//                 {
//                   required: true,
//                   message: "Please input ad title",
//                 },
//               ],
//             })(<Input placeholder="Please input ad title" />)}
//           </Form.Item>
//           <Form.Item label="Testo">
//             {getFieldDecorator("text", {
//               rules: [
//                 {
//                   required: true,
//                   message: "Please input ad text",
//                 },
//               ],
//             })(<TextArea rows={4} />)}
//           </Form.Item>
//           <Form.Item>
//             {adsCreationgLoading && <Loader />}
//             {adsCreationgMess && adsCreationgMess.errors ? (
//               <div className="alert alert-danger text-center" role="alert">
//                 {adsCreationgMess.message}
//               </div>
//             ) : adsCreationgMess && adsCreationgMess.message ? (
//               <div className="alert alert-success text-center" role="alert">
//                 {adsCreationgMess.message}
//               </div>
//             ) : null}
//             <Button disabled={adsCreationgLoading} type="primary" htmlType="submit">
//               INVIA
//             </Button>
//           </Form.Item>
//         </Form>
//       </div>
//     )
//   }
// }

const mapStateToProps = (state) => ({
  ads: state.auth.msg,
  ads_loading: state.auth.ads_loading,
  accountInfo: state.auth.accountInfo,
  adsCreationgLoading: state.auth.adsCreationgLoading,
  adsCreationgMess: state.auth.adsCreationgMess,
  screenWidth: state.main.screenWidth,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  AnnunciAdmin
)
