import React, { useEffect, useState } from "react"
import { Header, Footer } from "shared-componentsMobile"
import { skin } from "config/api"
import DashboardBody from "../domains/Dashboard"
import { subscribeSocketUser, subscribeSocketSupport } from "config/socket.js"
import { AuthActions, MainActions } from "redux-store/models"
import { connect } from "react-redux"
import images from "themes/images"
import { notification } from "antd"
import { Captcha } from "shared-components"
import { dontShowRPCH } from "utils/HelperFunc"
import StaticDefaultHomePage from "routes/domains/StaticDefaultHomePage/StaticDefaultHomePage"

const Dashboard = (props) => {
  const [username, handleChangeUsername] = useState("")
  const [password, handleChangePassword] = useState("")
  const [activeCategory, setActiveCategory] = useState()
  const [activeService, setActiveService] = useState()
  const [prenotaBolletino, setPrenotaBolletino] = useState(false)
  const [captchaToken, setCaptchaToken] = useState(false)
  const [email, setEmail] = useState("")
  const [forgetPassword, setForgetPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const socketCall = (data) => {
    subscribeSocketUser(data.id, props)
    if (data.role.name === "support") {
      subscribeSocketSupport(props)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (forgetPassword) {
      props.forgotPassword(email)
    } else {
      if ((captchaToken && captchaToken !== "") || dontShowRPCH(forgetPassword)) {
        props.signInByEmail(username, password, socketCall)
      } else {
        notification["error"]({
          message: "Verifica che non sei un robot (reCAPTCHA)",
          placement: "topRight",
        })
      }
    }
  }

  useEffect(()=> {
    if (props.scannedBarcode) {
      setActiveCategory("PRDPST")
      setActiveService("103185")
    }
  },[props.scannedBarcode])

  const loggedIn = props?.accountInfo?.profile?.role?.name
  return (
    <React.Fragment>
      {loggedIn ? (
        <React.Fragment>
          <div className="dashboardMobile">
            <Header
              togglePrenotazione={() => {
                setActiveCategory("SPRDPST")
                setActiveService("SBOL001")
              }}
            />
            <DashboardBody
              setActiveCategory={setActiveCategory}
              setActiveService={setActiveService}
              activeCategory={activeCategory}
              activeService={activeService}
            />
            <Footer />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={`${prenotaBolletino ? "dashboardMobile" : ""}`}>
            <Header togglePrenotazione={() => setPrenotaBolletino(true)} />

            {prenotaBolletino ? (
              <DashboardBody
                setPrenotaBolletino={() => setPrenotaBolletino(false)}
                services={{
                  SPRDPST: {
                    group: "pagamenti",
                    name: "Pagamenti",
                    SBOLL: {
                      favourite: false,
                      name: "Bollettini Postali",
                      services: [
                        {
                          service_id: "SBOL001",
                          name: "Bollettini Bianchi",
                          cost: "0.00",
                          type: "B",
                          commissione: "2.00",
                          provigione: "0.50",
                          link: "",
                        },
                        {
                          service_id: "SBOL002",
                          name: "Bollettini Premarcati",
                          cost: "0.00",
                          type: "B",
                          commissione: "2.00",
                          provigione: "0.50",
                          link: "",
                        },
                      ],
                    },
                  },
                }}
                activeCategory="SPRDPST"
                activeService="SBOL001"
              />
            ) : (
              <StaticDefaultHomePage />
            )}
            <Footer />
          </div>
        </React.Fragment>
      )}
      {window.location.hash.includes("login") && (
        <>
          <div className="loginPopUp animated slideInUp">
            <div className="loginPopUp--header">Accedi</div>
            <div className="loginPopUp--subheader">con le tue credenziali</div>
            <form className="loginPopUp--forms" action="/">
              {forgetPassword ? (
                <PopupFormItem
                  label="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  handleSubmit={handleSubmit}
                />
              ) : (
                <React.Fragment>
                  <PopupFormItem
                    label="UserName"
                    value={username}
                    onChange={(e) => {
                      handleChangeUsername(e.target.value)
                    }}
                    handleSubmit={handleSubmit}
                  />
                  <PopupFormItem
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    handleSubmit={handleSubmit}
                    onChange={(e) => {
                      handleChangePassword(e.target.value)
                    }}
                  />
                  
                    {showPassword ? (
                      <i
                        className="fal fa-eye"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    ) : (
                      <i
                        className="fal fa-eye-slash"
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    )}

                </React.Fragment>
              )}
              <div className="loginPopUp--forms__item flex-row">
                {!dontShowRPCH(forgetPassword) && (
                  <Captcha onChange={(token) => setCaptchaToken(token)} />
                )}
              </div>
              <div className="loginPopUp--forms__item flex-row">
                <button type="submit" onClick={handleSubmit}>
                  {!forgetPassword ? "Accedi" : "Vai"}
                </button>
                {skin.skin_id === 4 && (
                  <a href="https://services.planetservizi.it/login#/rut/y6flOjiiBH">
                    <span>REGISTRATI</span>
                  </a>
                )}
              </div>
              <div className="loginPopUp--forms__item">
                <span
                  className="forgotPassword"
                  onClick={() => setForgetPassword(!forgetPassword)}
                >
                  {!forgetPassword ? "Password dimenticata?" : "Login"}
                </span>
              </div>
            </form>
            <div className="loginPopUp--banner">
              <img src={images.mobileLoginGirl} alt="" />
            </div>
          </div>
          <div
            className="backDrop"
            onClick={() => {
              window.location.hash = "dashboard"
            }}
          ></div>
        </>
      )}
    </React.Fragment>
  )
}

const PopupFormItem = (props) => {
  const { label, type, handleSubmit, ...otherProps } = props

  const onKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit(e)
  }

  return (
    <div className="loginPopUp--forms__item">
      <div className="label">{label}</div>
      <input
        onKeyDown={onKeyDown}
        autoComplete="on"
        type={type || "text"}
        placeholder={label}
        name={label.toLowerCase()}
        id={label.toLowerCase() + "id"}
        {...otherProps}
      />
    </div>
  )
}

const mpstp = (state) => ({
  accountInfo: state.auth.accountInfo,
  scannedBarcode: state.auth.scannedBarcode
})
export default connect(mpstp, { ...AuthActions, ...MainActions })(Dashboard)
