import React, { Component } from "react"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import { Header } from "../../shared-components"
import images from "../../themes/images";

class UnderConstruction extends Component {
    constructor(props) {
        super(props)
        this.state = {
          redirect : false
        }
    }

    componentDidMount(){
      this.props.getSystemModes();
    }

    componentDidUpdate(prevProps){
      if(this.props.systemModes.find((value) => value.active === 1) && this.props.systemModes.find((value) => value.active === 1) != prevProps.systemModes.find((value) => value.active === 1)){
        window.location.href = "#/dashboard/service"
      }
    }

    render() {
      return (
        <div>
        <Header></Header>
        <div style={{height: "65vh",textAlign:"center",display:"flex",justifyContent:"center",flexDirection:"column"}}>
          <div style={{height:"max-content"}}>
            <img style={{width: "120px" , margin : "auto"}} src={images['commingSoon']} alt="" />
            <h1>SISTEMA IN AGGIORNAMENTO</h1>
          </div>
        </div>
        </div>
      )
    }
  }

  const mpStP = (state) => ({
    systemModes: state.main.systemModes,
  })
  export default connect(mpStP, MainActions)(UnderConstruction)