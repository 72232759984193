import React from "react";
import { connect } from "react-redux";
import AuthActions from "redux-store/models/auth";
import CommonBolletinoWrapper from "./CommonBolletinoWrapper";
import {
  LeftFreccia,
  RightFreccia,
} from "shared-components/ModulePopUp/BoloFrecciaForms"

const FrecciaForm = ({ setService, activeService, setFreccia, allFavServices }) => {
  const commonBolletinoProps = {
    title: "BOLLETTINI FRECCIA",
    favService: "BOLL",
  };

  return (
    <CommonBolletinoWrapper submit={setFreccia} {...{ commonBolletinoProps, activeService, setService, allFavServices }}>
      {({ getFieldDecorator, getFieldValue }, barcode) => (
          <React.Fragment>
             <LeftFreccia
            barcodeData={barcode}
            mobile
            {...{ getFieldDecorator, getFieldValue }}
          />
          <RightFreccia
            barcodeData={barcode}
           {...{ getFieldDecorator, getFieldValue }}
          />
          </React.Fragment>
      )}
    </CommonBolletinoWrapper>
  );
};

export default connect(null, AuthActions)(FrecciaForm);
