import React, { useState } from "react"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import CommonBolletinoWrapper from "./CommonBolletinoWrapper"

import {
  MrPayBolletiniRightForm,
  MPayBolletiniLeftForm,
  SepafinBolletiniRightForm,
  SepafinBolletiniLeftForm,
} from "shared-components/ModulePopUp/BolletiniForms"

const BolletiniBianchi = ({
  setService,
  BookingBoletinoInfo,
  activeService,
  fetchBolletini,
  getBolletiniBianchi,
  getBookingsBolMr,
  getBolletiniPremercati,
  allFavServices,
  isPrenotaBolletino,
  pagamentiServices,
  mrPayPagoPa,
  getBolletiniAuto
}) => {
  const [commonBolletinoProps, setBolletinoBanner] = useState({
    title: !isPrenotaBolletino ? "Bolletini Bianchi" : "Prenotazione Bolletini",
    favService: "BOLL",
  })

  const submitByService = {
    SBOL001: fetchBolletini,
    SBOL002: fetchBolletini,
    BOL001: getBolletiniBianchi,
    BOL002: getBolletiniPremercati,

    "110067": getBolletiniBianchi,

    ...(pagamentiServices && {
      ["Paytipper Bill payments no edit Miniprogram"]: getBolletiniBianchi,
      ["Paytipper Bill payments"]: getBolletiniPremercati,
      ["Paytipper RAV"]: getBolletiniPremercati,
      ["Paytipper MAV"]: getBolletiniPremercati,
      ["Paytipper PagoPA"]: mrPayPagoPa,
      ["Paytipper Bollo auto"]: getBolletiniAuto,
    }),

  }


  return (
    <CommonBolletinoWrapper
      submit={submitByService[activeService ? activeService : "SBOL001"]}
      {...{
        commonBolletinoProps,
        activeService,
        setService,
        allFavServices,
        isPrenotaBolletino,
      }}
    >
      {(
        { getFieldDecorator, getFieldValue, setFieldsValue },
        barcode,
        services,
        isSepafin,
        newFields,
        causaleRequired,
        hasCodiceFiscale,
        detailByContoCorrente,
        companyName
      ) => {
        const formProps = {
          barcodeData: barcode,
          setFieldsValue,
          getBookingsBolMr,
          getFieldDecorator,
          BookingBoletinoInfo,
          getFieldValue,
          service_id: activeService,
          newFields: Array.isArray(newFields) ? newFields : undefined,
          causaleRequired,
          isSepafin,
          hasCodiceFiscale,
          hideCasuale: causaleRequired,
          isPrenotaBolletino,
        }
        return (
          // <React.Fragment>
          <div>
            {isSepafin || isPrenotaBolletino ? (
              <SepafinBolletiniLeftForm {...formProps} openCodiceBolInfo={() => {}}>
                <div className="intestato">
                  <span>Intestato a </span>
                  <div className={companyName ? "placeholder" : ""}>
                    {detailByContoCorrente
                      ? companyName ||
                        "Non è stato possibile trovare il Beneficiario"
                      : "Nome beneficario"}
                  </div>
                </div>
              </SepafinBolletiniLeftForm>
            ) : (
              React.cloneElement(<MPayBolletiniLeftForm pagamentiServices={pagamentiServices}/>, { ...formProps })
            )}
            {React.cloneElement(
              isSepafin || isPrenotaBolletino ? (
                <SepafinBolletiniRightForm />
              ) : (
                <MrPayBolletiniRightForm pagamentiServices={pagamentiServices}/>
              ),
              { ...formProps }
            )}
          </div>
          //   {React.cloneElement(isSepafin ? SepafinBolletiniRightForm : MrPayBolletiniRightForm, { ...formProps })}
          // </React.Fragment>
        )
      }}
    </CommonBolletinoWrapper>
  )
}

const mapsStateToProps = (state) => ({
  BookingBoletinoInfo: state.auth.BookingBoletinoInfo,
})

export default connect(mapsStateToProps, AuthActions)(BolletiniBianchi)
