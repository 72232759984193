import React from 'react';
import {  Upload } from 'antd'; 
const { Dragger } = Upload


const LogoUploader = ({
  draggerProps,
  step,
  setStep,
  toBase64,
  fieldName,
  labelText,
  removeHandler,
  errorMessage,
}) => {
  return (
    <div>
      {!step[fieldName] ? (
        <Dragger
          maxCount={1}
          {...draggerProps}
          onChange={async (e) => {
            const file = e.file.originFileObj;
            const Base64 = await toBase64(file);
            setStep((prevStep) => ({
              ...prevStep,
              [fieldName]: e.file?.name || '',
              [`base64_${fieldName}`]: Base64,
            }));
          }}
        >
          <p className="ant-upload-drag-icon">
            <i className="fal fa-plus-circle"></i>
          </p>
          <p className="ant-upload-text">{labelText}</p>
        </Dragger>
      ) : (
        <div className="logo-uploaded-field">
          <p>{labelText} :</p>
          <div>
            <img src={step[`base64_${fieldName}`]} alt="" />
            <p onClick={removeHandler}>
              Remove <i className="fal fa-trash"></i>
            </p>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="error-message" style={{ color: 'red', fontSize: 12 }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default LogoUploader;
